export const COOKIE_CONFIG = {
  LOYMEE_ACCESS_TOKEN: {
    name: "loymeeAccessToken",
    duration: (expiresIn: number) => expiresIn * 1000,
  },
  LOYMEE_REFRESH_TOKEN: {
    name: "loymeeRefreshToken",
    duration: 14 * 24 * 60 * 60 * 1000,
  },
  LOYMEE_TENANT_ID: {
    name: "loymeeTenantId",
    duration: 14 * 24 * 60 * 60 * 1000,
  },
  LOYMEE_USER_ROLE: {
    name: "loymeeUserRole",
    duration: 14 * 24 * 60 * 60 * 1000,
  },
};
