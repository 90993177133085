/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AccessTokenResponse {
  tokenType: string;
  accessToken: string;
  /** @format int64 */
  expiresIn: number;
  refreshToken: string;
}

export interface AddLocationCommand {
  /**
   * @minLength 1
   * @maxLength 400
   */
  externalId: string;
}

export interface AddLocationResult {
  /** @format int32 */
  locationId: number;
}

export interface AddRewardCommand {
  /** @maxLength 24 */
  name?: string | null;
  /** @maxLength 24 */
  namePl?: string | null;
  /**
   * @format int32
   * @min 1
   * @max 10000
   */
  pointCost: number;
  /** @maxLength 100 */
  description?: string | null;
  /** @maxLength 100 */
  descriptionPl?: string | null;
}

export interface AddRewardResponse {
  /** @format int32 */
  rewardId: number;
}

export interface BadRequest {
  /** @format int32 */
  statusCode: number;
}

export enum BillingAccountType {
  Personal = "Personal",
  Business = "Business",
}

export interface BillingProfileVm {
  firstName: string;
  lastName: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  billingEmail: string;
  zipCode: string;
  company: string;
  taxIdType: string;
  vat: string;
  accountType: BillingAccountType;
}

export interface CompanyAccessTokenResponse {
  accessTokenResponse: AccessTokenResponse;
  identifier: string;
}

export enum CompanyCategory {
  Other = "Other",
  Restaurant = "Restaurant",
  Cafe = "Cafe",
  RetailStore = "RetailStore",
  BeautySalon = "BeautySalon",
  FitnessNutrition = "FitnessNutrition",
  HairSalon = "HairSalon",
  BarberShop = "BarberShop",
  MassagePhysiotherapy = "MassagePhysiotherapy",
  TattooPiercing = "TattooPiercing",
  HealthWellness = "HealthWellness",
  AestheticMedicine = "AestheticMedicine",
  ForPets = "ForPets",
  CarWash = "CarWash",
  Bakery = "Bakery",
  StreetFood = "StreetFood",
}

export interface CompanyChartStatisticsDto {
  purchasePointSumOverTime: Record<string, number>;
  claimedRewardsCountOverTime: Record<string, number>;
  customersOverTime: Record<string, number>;
  employeeScanCountOverTime: StatisticsEmployeeDto[];
}

export interface CompanyDto {
  identifier: string;
  name: string;
  verificationStatus: CompanyVerificationStatus;
  phoneNumber: string;
  pointsType: string;
  /** @format int32 */
  multiplier: number;
  pointsGainDescription?: string | null;
  pointsGainDescriptionPl?: string | null;
}

export interface CompanyGeneralStatisticsDto {
  /** @format int32 */
  purchaseScanCount: number;
  /** @format int32 */
  customerCount: number;
  /** @format int32 */
  claimedRewardsCount: number;
  /** @format int32 */
  purchasePointsSum: number;
}

export interface CompanyNumericStatisticsInPeriodDto {
  /** @format int32 */
  marketingEmailsDelivered: number;
}

export interface CompanyRegistrationStatusDto {
  isEmailConfirmed: boolean;
  hasReward: boolean;
  hasLocation: boolean;
  hasEmployee: boolean;
}

export enum CompanyVerificationStatus {
  Pending = "Pending",
  Confirmed = "Confirmed",
  Rejected = "Rejected",
}

export interface CreateBillingProfileCommand {
  /** @maxLength 255 */
  firstName: string;
  /** @maxLength 255 */
  lastName: string;
  /** @maxLength 255 */
  address: string;
  address2?: string | null;
  /** @maxLength 255 */
  city: string;
  state?: string | null;
  country: string;
  billingEmail: string;
  zipCode: string;
  company?: string | null;
  taxIdType?: string | null;
  vat?: string | null;
  accountType: BillingAccountType;
}

export interface CreatePaymentMethodCommand {
  paymentNonce: string;
}

export interface CreateSubscriptionCommand {
  /**
   * @format int32
   * @min 1
   */
  planId: number;
  frequency: SubscriptionFrequency;
  useTrial: boolean;
}

export interface CustomAndPublicPlans {
  plans: PlanPublicDto[];
  excludedPlan?: PlanPublicExtendedDto | null;
}

export interface CustomerCompanySummaryDto {
  /** @format uuid */
  id: string;
  email?: string | null;
  /** @format int32 */
  totalPoints: number;
  /** @format int32 */
  purchasesAmount: number;
  /** @format int32 */
  rewordsAmount: number;
  /** @format date */
  lastVisit?: string | null;
  purchaseScansWithAlert: ScanBaseDto[];
}

export interface CustomerCompanySummaryDtoPaginatedList {
  items: CustomerCompanySummaryDto[];
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  totalCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface EditRewardCommand {
  /** @format int32 */
  id: number;
  /** @maxLength 24 */
  name?: string | null;
  /** @maxLength 24 */
  namePl?: string | null;
  /**
   * @format int32
   * @min 1
   * @max 10000
   */
  pointCost: number;
  /** @maxLength 100 */
  description?: string | null;
  /** @maxLength 100 */
  descriptionPl?: string | null;
}

export interface EmployeeDto {
  /** @format uuid */
  id: string;
  email: string;
}

export interface FlagVm {
  employeeLimitReached: boolean;
  /** @format int32 */
  employeeLimit: number;
  rewardLimitReached: boolean;
  /** @format int32 */
  rewardLimit: number;
  locationsLimitReached: boolean;
  /** @format int32 */
  locationsLimit: number;
  /** @format int32 */
  emailToSubscribersUsed: number;
  /** @format int32 */
  emailToSubscribersLimit: number;
  /** @format int32 */
  pushNotificationToSubscribersUsed: number;
  /** @format int32 */
  pushNotificationToSubscribersLimit: number;
  subscriptionActive: boolean;
}

export interface ForgotPasswordRequest {
  email: string;
}

export type HttpValidationProblemDetails = ProblemDetails & {
  errors?: Record<string, string[]>;
  [key: string]: any;
};

export interface InfoRequest {
  newEmail?: string | null;
  newPassword?: string | null;
  oldPassword?: string | null;
  languageCode?: LanguageCode | null;
}

export enum InvoiceState {
  Unknown = "Unknown",
  Draft = "Draft",
  Open = "Open",
  Paid = "Paid",
  Uncollectible = "Uncollectible",
  Void = "Void",
}

export interface InvoiceVm {
  /** @format uuid */
  id: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  currency: string;
  state: InvoiceState;
  invoicePdfUrl?: string | null;
}

export interface InvoiceVmPaginatedList {
  items: InvoiceVm[];
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  totalCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export enum LanguageCode {
  En = "en",
  Pl = "pl",
}

export interface LocationDto {
  /** @format int32 */
  id: number;
  externalId: string;
}

export interface LoginRequest {
  email: string;
  password: string;
}

export enum MessageType {
  Email = "Email",
  PushNotification = "PushNotification",
}

export interface NoContent {
  /** @format int32 */
  statusCode: number;
}

export interface NotFound {
  /** @format int32 */
  statusCode: number;
}

export enum PaymentGatewayType {
  Stripe = "Stripe",
}

export interface PaymentMethodVm {
  /** @format uuid */
  id: string;
  paymentGatewayType: PaymentGatewayType;
  paymentEmail: string;
  address: string;
  city: string;
  country: string;
  expirationDate: string;
  firstName: string;
  lastName: string;
  lastFour: string;
  nickName: string;
  isDefault: boolean;
}

export interface PaymentMethodVmPaginatedList {
  items: PaymentMethodVm[];
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  totalCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export enum PlanAccessibility {
  Standalone = "Standalone",
  Custom = "Custom",
}

export interface PlanPublicDto {
  /** @format int32 */
  id: number;
  name: string;
  description?: string | null;
  namePl: string;
  descriptionPl?: string | null;
  /** @format double */
  pricePerMonth: number;
  /** @format double */
  pricePerYear: number;
  /** @format int32 */
  operatorsLimit: number;
  /** @format int32 */
  locationsLimit: number;
  /** @format int32 */
  rewardsLimit: number;
  /** @format int32 */
  emailToSubscribersLimit: number;
  /** @format int32 */
  pushNotificationToSubscribersLimit: number;
}

export type PlanPublicExtendedDto = PlanPublicDto & {
  accessibility?: PlanAccessibility;
  isAbandoned?: boolean;
};

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export interface ProductVm {
  code: string;
  publicName: string;
}

export interface RatePlanVm {
  /** @format int32 */
  id: number;
  name: string;
  namePl: string;
  currency: string;
  scope: string;
}

export interface RefreshRequest {
  refreshToken: string;
}

export interface RegisterCompanyCommand {
  /**
   * @format email
   * @minLength 1
   * @maxLength 200
   */
  ownerEmailAddress: string;
  /**
   * @minLength 8
   * @maxLength 200
   * @pattern ^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()._+=-])[A-Za-z\d!@#$%^&*()._+=-]{8,200}$
   */
  ownerPassword: string;
  /** @maxLength 20 */
  phoneNumber?: string | null;
  /**
   * @minLength 1
   * @maxLength 200
   */
  companyName: string;
  category: CompanyCategory;
  languageCode: LanguageCode;
  referralCode?: string | null;
}

export interface RegisterEmployeeRequest {
  userName: string;
  password: string;
}

export interface ResendConfirmationEmailRequest {
  email: string;
}

export interface ResetPasswordRequest {
  email: string;
  resetCode: string;
  newPassword: string;
}

export interface RewardDto {
  /** @format int32 */
  id: number;
  name?: string | null;
  namePl?: string | null;
  description?: string | null;
  descriptionPl?: string | null;
  /** @format int32 */
  pointCost: number;
}

export interface ScanBaseDto {
  /** @format int32 */
  points: number;
  /** @format date-time */
  date: string;
}

export interface ScanCustomerPublicIdCommand {
  /**
   * @minLength 1
   * @maxLength 200
   */
  customerPublicId: string;
  /**
   * @format int32
   * @min 1
   * @max 20
   */
  scanCount: number;
}

export interface ScanCustomerPublicIdResponse {
  /** @format int32 */
  pointsGained: number;
  /** @format int32 */
  pointsOwned: number;
  /** @format int32 */
  userScanStreak: number;
}

export interface ScanRewardRequestCodeCommand {
  /**
   * @minLength 1
   * @pattern ^\d{4}-\d{4}$
   */
  rewardRequestCode: string;
}

export interface ScanRewardRequestCodeResponse {
  /** @format int32 */
  pointsTaken: number;
  /** @format int32 */
  pointsLeft: number;
}

export interface SendEmailToCompanySubscribersCommand {
  /**
   * @minLength 1
   * @maxLength 40
   */
  title: string;
  /**
   * @minLength 1
   * @maxLength 1000
   */
  body: string;
}

export interface SendPushNotificationToCompanySubscribersCommand {
  /** @maxLength 40 */
  title?: string | null;
  /** @maxLength 120 */
  body?: string | null;
  /** @maxLength 40 */
  titlePl?: string | null;
  /** @maxLength 120 */
  bodyPl?: string | null;
  customData: Record<string, string | null>;
}

export interface SendPushNotificationToCustomerCommand {
  email: string;
  /**
   * @minLength 1
   * @maxLength 20
   */
  title: string;
  /**
   * @minLength 1
   * @maxLength 100
   */
  body: string;
  customData: Record<string, string>;
}

export interface SentMessageDto {
  /** @format uuid */
  id: string;
  messageType: MessageType;
  subject?: string | null;
  bodyContent?: string | null;
  subjectPl?: string | null;
  bodyContentPl?: string | null;
  /** @format int32 */
  recipientCount?: number | null;
  /** @format date-time */
  sentAt: string;
}

export interface SentMessageDtoPaginatedList {
  items: SentMessageDto[];
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  totalCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface SetPasswordRequest {
  resetCode: string;
  newPassword: string;
}

export interface StatisticsEmployeeDto {
  /** @format uuid */
  id: string;
  email: string;
  /** @format int32 */
  scanCount: number;
}

export enum StatisticsPeriod {
  Year = "Year",
  Month = "Month",
  Week = "Week",
}

export interface StripeClientSecretDto {
  clientSecret: string;
}

export interface StripePublishableKeyVm {
  publishableKey: string;
}

export enum SubscriptionFrequency {
  Monthly = "Monthly",
  Annually = "Annually",
}

export enum SubscriptionHandler {
  Stripe = "Stripe",
}

export enum SubscriptionState {
  Unknown = "Unknown",
  Provisioned = "Provisioned",
  Trial = "Trial",
  Paid = "Paid",
  AwaitingPayment = "AwaitingPayment",
  Cancelled = "Cancelled",
  Failed = "Failed",
  Expired = "Expired",
}

export interface SubscriptionVm {
  /** @format uuid */
  id: string;
  cancelAtPeriodEnd: boolean;
  state: SubscriptionState;
  /** @format date-time */
  currentPeriodStart: string;
  /** @format date-time */
  currentPeriodEnd: string;
  frequency: SubscriptionFrequency;
  /** @format double */
  price: number;
  currency: string;
  handler: SubscriptionHandler;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  product?: ProductVm | null;
  ratePlan?: RatePlanVm | null;
  /** @format date-time */
  trialStart?: string | null;
  /** @format date-time */
  trialEnd?: string | null;
  /** @format date-time */
  canceledAt?: string | null;
}

export interface SubscriptionVmPaginatedList {
  items: SubscriptionVm[];
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  totalCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface UpdateBillingProfileCommand {
  /** @minLength 1 */
  firstName: string;
  /** @minLength 1 */
  lastName: string;
  /** @minLength 1 */
  address: string;
  address2?: string | null;
  /** @minLength 1 */
  city: string;
  state?: string | null;
  /** @minLength 1 */
  country: string;
  /** @format email */
  billingEmail: string;
  /**
   * @minLength 1
   * @pattern ^\d{2}-\d{3}$
   */
  zipCode: string;
  company?: string | null;
  taxIdType?: string | null;
  vat?: string | null;
  accountType: BillingAccountType;
}

export interface UpdateCompanyCommand {
  /** @maxLength 200 */
  companyName?: string | null;
  /** @maxLength 20 */
  phoneNumber?: string | null;
  /**
   * @minLength 3
   * @maxLength 32
   */
  pointsType?: string | null;
  /**
   * @format int32
   * @min 1
   */
  multiplier?: number | null;
  /** @maxLength 300 */
  pointsGainDescription?: string | null;
  pointsGainDescriptionPl?: string | null;
}

export interface UpdateEmployeeCommand {
  /**
   * @format uuid
   * @minLength 1
   */
  id: string;
  /**
   * @format email
   * @maxLength 64
   */
  email?: string | null;
  /**
   * @maxLength 200
   * @pattern ^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()._+=-])[A-Za-z\d!@#$%^&*()._+=-]{8,200}$
   */
  newPassword?: string | null;
}

export interface UpdatePaymentMethodCommand {
  /** @format uuid */
  paymentMethodId: string;
  /** @minLength 1 */
  paymentNonce: string;
}

export interface UpdateSubscriptionCommand {
  /** @format uuid */
  subscriptionId: string;
  /**
   * @format int32
   * @min 1
   */
  planId: number;
  frequency: SubscriptionFrequency;
}

export interface UserInfoResponse {
  email: string;
  isEmailConfirmed: boolean;
  userType: string;
  userRole?: string | null;
  tenantIdentifier?: string | null;
  languageCode: LanguageCode;
}
