import {
  LanguageCode,
  PlanPublicDto,
  RatePlanVm,
  RewardDto,
  SentMessageDto,
} from "@repo/types/companyApi.types";
import {
  CompanyRewardsResult,
  LatestReward,
} from "@repo/types/customerApi.types";
import { SubscriptionDto } from "@repo/types/rootApi.types";

export const getSubscriptionName = (
  plan: PlanPublicDto | RatePlanVm | SubscriptionDto,
  language: string
) => {
  if (language === LanguageCode.En) {
    return "name" in plan ? plan.name : "planName" in plan ? plan.planName : "";
  }
  return "namePl" in plan
    ? plan.namePl
    : "planNamePl" in plan
      ? plan.planNamePl
      : "";
};

export const getSubscriptionDescription = (
  plan: PlanPublicDto,
  language: string
) => {
  if (language === LanguageCode.En) return plan.description;
  return plan.descriptionPl;
};

export const getRewardName = (
  reward:
    | Pick<RewardDto, "name" | "namePl">
    | Pick<LatestReward, "rewardName" | "rewardNamePl">,
  language: string
): string => {
  const name =
    "name" in reward
      ? reward.name
      : "rewardName" in reward
        ? reward.rewardName
        : "";

  const namePl =
    "namePl" in reward
      ? reward.namePl
      : "rewardNamePl" in reward
        ? reward.rewardNamePl
        : "";

  if (namePl && !name) return namePl;
  if (name && !namePl) return name;
  if (language === LanguageCode.En && name) return name;
  return namePl || name || "";
};

export const getRewardDescription = (reward: RewardDto, language: string) => {
  if (reward.descriptionPl && !reward.description) return reward.descriptionPl;
  if (reward.description && !reward.descriptionPl) return reward.description;
  if (language === LanguageCode.En) return reward.description;
  return reward.descriptionPl;
};

export const getPointsGainDescription = (
  data: CompanyRewardsResult,
  language: string
) => {
  if (data.pointsGainDescriptionPl && !data.pointsGainDescription)
    return data.pointsGainDescriptionPl;
  if (data.pointsGainDescription && !data.pointsGainDescriptionPl)
    return data.pointsGainDescription;
  if (language === LanguageCode.En) return data.pointsGainDescription;
  return data.pointsGainDescriptionPl;
};

export const getNotificationSubject = (
  message: SentMessageDto,
  language: string
) => {
  if (message.subjectPl && !message.subject) return message.subjectPl;
  if (message.subject && !message.subjectPl) return message.subject;
  if (language === LanguageCode.En) return message.subject || "";
  return message.subjectPl || "";
};

export const getNotificationBodyContent = (
  message: SentMessageDto,
  language: string
) => {
  if (message.bodyContentPl && !message.bodyContent)
    return message.bodyContentPl;
  if (message.bodyContent && !message.bodyContentPl) return message.bodyContent;
  if (language === LanguageCode.En) return message.bodyContent || "";
  return message.bodyContentPl || "";
};
