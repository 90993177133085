import { Box, Typography } from "@mui/material";
import { creditCardIcon } from "../..";
import { useTranslation } from "@repo/i18n-config";
import { getImageUrl } from "@repo/utils";
import { PaymentMethodVm } from "@repo/types/companyApi.types";

interface CardInfoProps {
  data: PaymentMethodVm;
}

export const CardInfo = ({ data }: CardInfoProps) => {
  
  const { t } = useTranslation("ui");

  return (
    (<Box>
      <Box
        sx={{
          display: "flex",
          gap: 1,
        }}
      >
        <img src={getImageUrl(creditCardIcon)} alt="" />
        <Typography>**** {data.lastFour}</Typography>
      </Box>
      <Typography
        sx={theme => ({
          mt: 1,
          color: theme.palette.text.secondary
        })}
      >
        {t("CardInfo.expires")}: {data.expirationDate}
      </Typography>
    </Box>)
  );
};
