import { PaginationParams, StatisticsChartTimePeriod } from "../../types";
import { GetReferredCompaniesListRequest } from "./companiesService";

const basePath = "/root/api/v1";

export const rootApiPaths = {
  auth: {
    base: `${basePath}/auth`,
    login: `${basePath}/auth/login`,
    refresh: `${basePath}/auth/refresh`,
    info: `${basePath}/auth/info`,
    forgotPassword: `${basePath}/auth/forgot-password`,
    confirmEmail: `${basePath}/auth/confirm-email`,
    resendConfirmationEmail: `${basePath}/auth/resendConfirmationEmail`,
    resetPassword: `${basePath}/auth/reset-password`,
  },
  companies: {
    base: ({ pageParam }: PaginationParams) =>
      `${basePath}/companies?pageNumber=${pageParam.pageNumber}&pageSize=${pageParam.pageSize}`,
    referred: ({
      pageParam,
      sortBy,
      sortAscending,
    }: GetReferredCompaniesListRequest) =>
      `${basePath}/companies/referred?pageNumber=${pageParam.pageNumber}&pageSize=${pageParam.pageSize}&sortBy=${sortBy}&sortAscending=${sortAscending}`,
    new: `${basePath}/companies`,
    getCompanyById: (identifier: string) =>
      `${basePath}/companies/${identifier}`,
    getCompanyStatistics: (
      identifier: string,
      period: StatisticsChartTimePeriod
    ) => `${basePath}/companies/${identifier}/statistics?period=${period}`,
    update: (identifier: string) => `${basePath}/companies/${identifier}`,
    confirm: (identifier: string) =>
      `${basePath}/companies/verification/${identifier}`,
  },
  plans: {
    base: (pageNumber: number, pageSize: number) =>
      `${basePath}/plans?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    create: `${basePath}/plans`,
    delete: (id: number) => `${basePath}/plans/${id}`,
  },
  statistics: {
    base: `${basePath}/statistics`,
    chart: (period: StatisticsChartTimePeriod) =>
      `${basePath}/statistics/chart?period=${period}`,
    period: (period: StatisticsChartTimePeriod) =>
      `${basePath}/statistics/period?period=${period}`,
  },
  rootUsers: {
    base: `${basePath}/rootUsers`,
    delete: (identifier: string) => `${basePath}/rootUsers/${identifier}`,
  },
  connectAccount: {
    base: `${basePath}/connectAccount`,
    referral: `${basePath}/connectAccount/referral`,
    commission: `${basePath}/connectAccount/commission`,
  },
};
