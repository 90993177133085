import * as v from "valibot";
import { FieldApiOptions } from "@tanstack/react-form";

export const requiredStringValidator = v.pipe(
  v.string(),
  v.minLength(1, "validators.requiredField")
);

export const requiredNumberValidator = v.union([
  v.pipe(v.string(), v.minLength(1, "validators.requiredField")), // handle empty number fields which are "" for compatibility with mui & form library
  v.pipe(v.number(), v.minValue(1, "validators.requiredNumber")),
]);

export const requiredCheckboxValidator = v.pipe(
  v.boolean(),
  v.value(true, "validators.requiredField")
);

export const emailValidator = v.pipe(
  requiredStringValidator,
  v.custom((value) => {
    if (value === "") {
      return true; // If the value is empty, skip regex validation
    }
    return typeof value === "string" &&
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        value
      )
      ? true
      : false;
  }, "validators.wrongEmail")
);

export const optionalEmailValidator = v.regex(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  "validators.wrongEmail"
);

export const nipValidator = ({ value }: ValidatorOptions) => {
  if (/\D/.test(value)) {
    return "validators.wrongNip";
  }

  const digitsOnly = value.replace(/\D/g, "");

  if (digitsOnly.length !== 10) {
    return "validators.wrongNip";
  }

  const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
  let sum = 0;

  for (let i = 0; i < 9; i++) {
    sum += parseInt(digitsOnly.charAt(i), 10) * weights[i]!;
  }

  const checksum = sum % 11;

  if (checksum === 10) {
    return "validators.wrongNip";
  }

  const lastDigit = parseInt(digitsOnly.charAt(9), 10);

  return checksum === lastDigit ? undefined : "validators.wrongNip";
};
export const loginPasswordValidator = v.pipe(
  v.string("validators.enterPassword"),
  v.minLength(1, "validators.enterPassword"),
  v.maxLength(200, "validators.passwordMaxLength")
);

export const passwordValidator = v.pipe(
  requiredStringValidator,
  v.minLength(8, "validators.passwordMinLength"),
  v.maxLength(200, "validators.passwordMaxLength"),
  v.regex(/[A-Z]/, "validators.passwordAZ"),
  v.regex(/[a-z]/, "validators.passwordaz"),
  v.regex(/[0-9]/, "validators.passwordNumber"),
  v.regex(/[`!@#$%\^&*()_+\-=\.]/, "validators.passwordChar"),
  v.regex(/^[A-Za-z0-9!@#$%\^&*()_+\-=\.]*$/, "validators.passwordCharLimit")
);

export interface ValidatorOptions {
  value: string;
  fieldApi: FieldApiOptions<any, string, any, any, any>;
}

export const confirmPasswordValidator = ({
  value,
  fieldApi,
  passwordFieldValue = "password",
}: ValidatorOptions & {
  passwordFieldValue?: string;
}) => {
  if (value !== fieldApi.form.getFieldValue(passwordFieldValue)) {
    return "validators.passwordNoMatch";
  }
  return undefined;
};

export const pricePerYearValidator = ({
  value,
  fieldApi,
}: ValidatorOptions) => {
  if (!value) return "validators.requiredField";
  if (value < fieldApi.form.getFieldValue("pricePerMonth")) {
    return "validators.wrongPricePerYear";
  }
  return undefined;
};

export const pricePerMonthValidator = ({
  value,
  fieldApi,
}: ValidatorOptions) => {
  if (!value) return "validators.requiredField";
  if (value > fieldApi.form.getFieldValue("pricePerYear")) {
    return "validators.wrongPricePerMonth";
  }
  return undefined;
};

export const phoneValidator = v.pipe(
  v.string(),
  v.regex(/^(?:[0-9\-\+]{9,15})?$/, "validators.enterCorrectPhone")
);

export const noSpacesValidator = v.pipe(
  requiredStringValidator,
  v.regex(/^\S*$/, "validators.noSpaces")
);

export const rewardNameValidator = v.pipe(
  v.string(),
  v.maxLength(24, "validators.rewardNameTooLong")
);

export const requiredRewardNameValidator = v.pipe(
  requiredStringValidator,
  v.maxLength(24, "validators.rewardNameTooLong")
);

export const rewardDescriptionValidator = v.pipe(
  v.string(),
  v.maxLength(100, "validators.rewardDescriptionTooLong")
);

export const companyCategoryValidator = v.pipe(
  v.string(),
  v.notValue("None", "validators.pickCompanyCategory")
);

export const notificationTitleValidator = v.pipe(
  v.string(),
  v.maxLength(40, "validators.notificationTitleTooLong")
);

export const notificationContentValidator = v.pipe(
  v.string(),
  v.maxLength(120, "validators.notificationContentTooLong")
);

export const requiredNotificationTitleValidator = v.pipe(
  requiredStringValidator,
  v.maxLength(40, "validators.notificationTitleTooLong")
);

export const requiredNotificationContentValidator = v.pipe(
  requiredStringValidator,
  v.maxLength(120, "validators.notificationContentTooLong")
);

export const mailNotificationContentValidator = v.pipe(
  requiredStringValidator,
  v.maxLength(1000, "validators.mailNotificationContentTooLong")
);

export const zipCodeValidator = ({ value }: ValidatorOptions) => {
  if (!value) return "validators.requiredField";
  if (value.length !== 6) {
    return "validators.zipCode";
  }
  return undefined;
};

export const codeValidator = ({ value }: ValidatorOptions) => {
  if (!value || value === "____-____") return undefined;
  if (!value.match(/^\d{4}-\d{4}$/)) {
    return "validators.scanCode";
  }
  return undefined;
};

export const companyIdValidator = v.pipe(
  requiredStringValidator,
  v.regex(/^[A-Za-z0-9]+$/, "validators.companyIdRegex")
);

export const planDescriptionValidator = v.pipe(
  v.string(),
  v.maxLength(1000, "validators.planDescription")
);

export const rewardRulesValidator = v.pipe(
  v.string(),
  v.maxLength(300, "validators.rewardRules")
);

export const multiplierValidator = v.union([
  v.pipe(v.string(), v.minLength(1, "validators.multiplier")), // handle empty number fields which are "" for compatibility with mui & form library
  v.pipe(
    v.number(),
    v.minValue(1, "validators.multiplier"),
    v.integer("validators.multiplier")
  ),
]);

export const companyNameValidator = v.pipe(
  requiredStringValidator,
  v.maxLength(200, "validators.companyName")
);

export const companyEmailValidator = v.pipe(
  v.string(),
  v.maxLength(200, "validators.companyEmail"),
  emailValidator
);
