import { Cancel } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "@repo/i18n-config";
import { LinkWrapper } from "../LinkWrapper";
import { cmsRoutes } from "@repo/utils";

export const FunctionNotAvailable = () => {
  const { t } = useTranslation("ui");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Cancel
        color="error"
        sx={{
          height: 48,
          width: 48,
          mb: 2,
        }}
      />
      <Typography>{t("FunctionNotAvailable.header")}</Typography>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          mt: 3,
        }}
      >
        <LinkWrapper
          to={cmsRoutes.settings.base}
          query={{ tab: "paymentSubscription" }}
        >
          <Button>{t("FunctionNotAvailable.plans")}</Button>
        </LinkWrapper>
        <LinkWrapper to={cmsRoutes.home.base}>
          <Button variant="text">{t("FunctionNotAvailable.understood")}</Button>
        </LinkWrapper>
      </Box>
    </Box>
  );
};
