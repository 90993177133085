"use client";

import { ReactNode, useRef } from "react";

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;
  prompt(): Promise<void>;
}

declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
  }
  interface Window {
    _LOYMEE_PWA_PROMPT_?: BeforeInstallPromptEvent;
  }
}

interface PWAInstallerWrapperProps {
  children: ReactNode;
}

export const PWAInstallerWrapper = ({ children }: PWAInstallerWrapperProps) => {
  const isInitialized = useRef(false);

  if (!isInitialized.current && typeof window !== "undefined") {
    window.addEventListener("beforeinstallprompt", (e) => {
      window._LOYMEE_PWA_PROMPT_ = e;
    });
    isInitialized.current = true;
  }

  return children;
};
