import { Box, CircularProgress } from "@mui/material";
import { getTenantId } from "@repo/api-config";
import {
  getCompanyDetails,
  updateCompany,
} from "@repo/api-config/services/company";
import { useTranslation } from "@repo/i18n-config";
import {
  HttpValidationProblemDetails,
  UpdateCompanyCommand,
} from "@repo/types/companyApi.types";
import { Card, ErrorMessage, LoadingButton, TextInput } from "@repo/ui";
import {
  multiplierValidator,
  useNotificationsContext,
  useServerErrorFormatter,
} from "@repo/utils";
import { useForm } from "@tanstack/react-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { valibotValidator } from "@tanstack/valibot-form-adapter";
import { AxiosError } from "axios";
import { useEffect } from "react";

interface FormValues {
  multiplier: number | string;
}

const Points = () => {
  const { t } = useTranslation("cms");

  const tenantId = getTenantId();

  const { formatErrorMessage } = useServerErrorFormatter();

  const { showNotification } = useNotificationsContext();

  const { data, isLoading, error } = useQuery({
    queryKey: ["Multiplier"],
    queryFn: () => getCompanyDetails(tenantId!),
  });

  const form = useForm<FormValues, any>({
    defaultValues: {
      multiplier: 1,
    },
    onSubmit: async ({ value }) => {
      if (value.multiplier)
        mutate({
          multiplier: Number(value.multiplier),
        });
    },
    validatorAdapter: valibotValidator(),
  });

  useEffect(() => {
    if (data) form.setFieldValue("multiplier", data.multiplier);
  }, [data]);

  const {
    mutate,
    isPending,
    error: mutationError,
  } = useMutation<void, AxiosError, Pick<UpdateCompanyCommand, "multiplier">>({
    mutationFn: (value) => updateCompany(tenantId!, value),
    onSuccess: () => {
      showNotification(t("Rewards.multiplierSaved"));
    },
  });

  return (
    <Card
      title={t("Rewards.points.pointsPerScan")}
      description={t("Rewards.points.pointsPerScanDescription")}
      sx={{
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        "& h6": {
          width: { xs: "100%", sm: "40%" },
        },
      }}
    >
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {error && !data && (
        <ErrorMessage
          errorMessage={formatErrorMessage(
            error as AxiosError<HttpValidationProblemDetails>
          )}
        />
      )}
      {data && (
        <Box
          component="form"
          sx={{ display: "flex", flex: 1 }}
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            form.handleSubmit();
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <TextInput
              type="number"
              label={t("Rewards.points.multiplier")}
              fullWidth
              form={form}
              name="multiplier"
              validators={{
                onChange: multiplierValidator,
              }}
              numberType="float"
            />
            {mutationError && (
              <ErrorMessage
                errorMessage={formatErrorMessage(
                  mutationError as AxiosError<HttpValidationProblemDetails>
                )}
              />
            )}
            <form.Subscribe
              selector={(state) => [state.isValid]}
              children={([isValid]) => (
                <LoadingButton
                  sx={(theme) => ({
                    mt: 4,
                    alignSelf: "flex-end",
                    backgroundColor: theme.palette.secondary.main,
                  })}
                  fullWidth={false}
                  text={t("Rewards.points.save")}
                  isLoading={isPending}
                  disabled={!isValid}
                />
              )}
            />
          </Box>
        </Box>
      )}
    </Card>
  );
};

export default Points;
