import { CheckCircle, Error } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "@repo/i18n-config";
import { SubscriptionState as SubscriptionStateEnum } from "@repo/types/companyApi.types";
import { useMemo } from "react";

interface SubscriptionStateProps {
  state?: SubscriptionStateEnum;
}

export const SubscriptionState = ({ state }: SubscriptionStateProps) => {
  const { t } = useTranslation("ui");

  const isError = useMemo(() => {
    return (
      !state ||
      state === SubscriptionStateEnum.AwaitingPayment ||
      state === SubscriptionStateEnum.Cancelled ||
      state === SubscriptionStateEnum.Failed ||
      state === SubscriptionStateEnum.Expired
    );
  }, [state]);

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 0.75 }}>
      {!isError ? <CheckCircle color="success" /> : <Error color="error" />}
      <Typography variant="body2">
        {t(`DataTable.columns.subscriptionState.${state}`)}
      </Typography>
    </Box>
  );
};
