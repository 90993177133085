import { httpClient } from "../../http-client";
import { companyApiPaths } from "./paths";
import {
  MessageType,
  SentMessageDtoPaginatedList,
} from "@repo/types/companyApi.types";

export const getMessagesHistory = async (
  tenantId: string,
  {
    pageNumber,
    pageSize,
  }: {
    pageNumber: number;
    pageSize: number;
  },
  type: MessageType
) => {
  const response = await httpClient.get<SentMessageDtoPaginatedList>(
    companyApiPaths.messages.history(tenantId, pageNumber, pageSize, type)
  );

  return response.data;
};

export const deleteMessage = async (tenantId: string, messageId: string) => {
  const response = await httpClient.delete(
    companyApiPaths.messages.delete(tenantId, messageId)
  );
  return response.data;
};
