"use client";

import { Alert, AlertColor, Snackbar } from "@mui/material";
import { createContext, useState, useContext, ReactNode } from "react";

interface NotificationsContextType {
  showNotification: (msg: string, opts?: { type: NotificationType }) => void;
}

const NotificationsContext = createContext<
  NotificationsContextType | undefined
>(undefined);

type NotificationType = Extract<AlertColor, "success" | "error">;

export const NotificationsContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [message, setMessage] = useState<string>();
  const [type, setType] = useState<NotificationType>("success");
  const [open, setOpen] = useState(false);

  const showNotification = (msg: string, opts?: { type: NotificationType }) => {
    setMessage(msg);
    setType(opts?.type || "success");
    setOpen(true);
  };

  const hideNotification = () => {
    setOpen(false);
  };

  return (
    <NotificationsContext.Provider value={{ showNotification }}>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={(_e, reason) => reason !== "clickaway" && hideNotification()}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <Alert
          onClose={hideNotification}
          severity={type}
          variant="standard"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>

      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotificationsContext = (): NotificationsContextType => {
  const context = useContext(NotificationsContext);
  if (!context) {
    throw new Error(
      "useNotificationContext must be used within a NotificationContextProvider"
    );
  }
  return context;
};
