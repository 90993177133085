import { createFileRoute } from "@tanstack/react-router";
import { EditEmployee } from "../../../../../pages/Employees/NewEmployee";
import { getEmployeesList } from "@repo/api-config/services/company";
import { getCookie } from "@repo/utils";
import { COOKIE_CONFIG, getTenantId } from "@repo/api-config";

export const Route = createFileRoute("/_private/_withDashboard/employees/edit/$employeeId")({
  component: () => <EditEmployee />,
  loader: async ({ params }) => {
    const role = getCookie(COOKIE_CONFIG.LOYMEE_USER_ROLE.name);
    const tenantId = getTenantId();
    const employees = await getEmployeesList(
      tenantId!,
      role === "PendingOwner" ? "PENDINGOPERATOR" : "OPERATOR"
    );
    return employees.find((employee) => employee.id === params.employeeId);
  },
});
