"use client";

import { getTenantId } from "@repo/api-config";
import {
  changePayment,
  deletePayment,
  generateStripeSecret,
  getBillingData,
  getPaymentMethod,
  getPublishableKey,
  savePayment,
  SavePaymentMethodData,
} from "@repo/api-config/services/company";
import {
  cmsRoutes,
  useNotificationsContext,
  useServerErrorFormatter,
} from "@repo/utils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useState } from "react";
import { ErrorMessage } from "../ErrorMessage";
import {
  loadStripe,
  StripeElementLocale,
  StripeElementsOptions,
} from "@stripe/stripe-js";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useTranslation } from "@repo/i18n-config";
import { LoadingButton } from "../LoadingButton";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { CardInfo } from "./CardInfo";
import { ErrorButton } from "../webapp/ErrorButton";
import { Close } from "@mui/icons-material";
import { HttpValidationProblemDetails } from "@repo/types/companyApi.types";

interface StripeCardInputProps {
  secret: string;
  onSuccess?: () => void;
  forModal: boolean;
}

interface StripeCardFormProps {
  forModal?: boolean;
}

const StripeCardInput = ({
  secret,
  onSuccess,
  forModal,
}: StripeCardInputProps) => {
  const { t } = useTranslation("cms");
  const { showNotification } = useNotificationsContext();
  const [processing, setProcessing] = useState(false);
  const tenantId = getTenantId();

  const { formatErrorMessage } = useServerErrorFormatter();
  const { data, error } = useQuery({
    queryFn: () => getPaymentMethod(tenantId!),
    queryKey: ["PaymentMethod"],
    enabled: !!tenantId,
  });

  const stripe = useStripe();
  const elements = useElements();
  const queryClient = useQueryClient();

  const { mutate: saveData } = useMutation<
    void,
    AxiosError,
    SavePaymentMethodData
  >({
    mutationFn: (values) =>
      data ? changePayment(tenantId!, values) : savePayment(tenantId!, values),
    onError: () => showNotification(t("stripe.error")),
    onSuccess: () => {
      showNotification(t("stripe.saved"));
      queryClient.invalidateQueries({
        queryKey: ["BillingData"],
      });
      queryClient.invalidateQueries({
        queryKey: ["PaymentMethod"],
      });
      queryClient.invalidateQueries({
        queryKey: ["StripeSecret"],
      });
      queryClient.invalidateQueries({
        queryKey: ["PublishableKey"],
      });
      onSuccess && onSuccess();
    },
  });

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { mutate: deleteMethod, isPending: deletePending } = useMutation<
    void,
    AxiosError,
    void
  >({
    mutationFn: () => deletePayment(tenantId!, data!.id!),
    onError: () => showNotification(t("stripe.deleteError")),
    onSuccess: () => {
      showNotification(t("stripe.saved"));
      queryClient.invalidateQueries({
        queryKey: ["BillingData"],
      });
      queryClient.invalidateQueries({
        queryKey: ["PaymentMethod"],
      });
      queryClient.invalidateQueries({
        queryKey: ["StripeSecret"],
      });
      queryClient.invalidateQueries({
        queryKey: ["PublishableKey"],
      });
    },
  });

  const handleSubmit = async () => {
    if (!stripe || !elements || !secret) {
      return;
    }
    try {
      setProcessing(true);
      elements.submit();
      const confirmSetupResult = await stripe.confirmSetup({
        elements: elements,
        clientSecret: secret,
        redirect: "if_required",
        confirmParams: {
          return_url: `${window.location.origin}/${cmsRoutes.settings.base}`,
        },
      });
      if (confirmSetupResult.error) {
        showNotification(t("stripe.error"), { type: "error" });
        setProcessing(false);
      } else {
        const setupIntentRetrieve = await stripe.retrieveSetupIntent(secret);
        const paymentMethod = setupIntentRetrieve.setupIntent?.payment_method;
        saveData({
          paymentNonce: paymentMethod as string,
          paymentMethodId: data ? data.id : undefined,
        });
        setProcessing(false);
      }
    } catch {
      showNotification(t("stripe.error"), { type: "error" });
      setProcessing(false);
    }
  };

  if (error)
    return (
      <ErrorMessage
        errorMessage={formatErrorMessage(
          error as AxiosError<HttpValidationProblemDetails>
        )}
      />
    );
  return (
    <>
      {data ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 4,
          }}
        >
          <CardInfo data={data} />
          <ErrorButton
            size="small"
            disabled={deletePending}
            onClick={() => setDeleteModalOpen(true)}
          >
            {t("stripe.delete")}
          </ErrorButton>
        </Box>
      ) : (
        <>{!forModal && <Typography>{t("stripe.noCard")}</Typography>}</>
      )}
      <Typography variant="h6">
        {data ? t("stripe.updateCard") : t("stripe.addCard")}
      </Typography>
      <PaymentElement />
      <LoadingButton
        sx={{ alignSelf: "flex-end" }}
        onClick={() => handleSubmit()}
        isLoading={processing}
        text={data ? t("stripe.updateCard") : t("stripe.addCard")}
        type="button"
        fullWidth={false}
      />
      <Dialog
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {t("stripe.deleteModal.header")}
          <IconButton onClick={() => setDeleteModalOpen(false)} sx={{ mr: -1 }}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ mb: 2, whiteSpace: "pre-line" }}>
            {t("stripe.deleteModal.description")}
          </Typography>
          {data && <CardInfo data={data} />}
          <Box sx={{ display: "flex", justifyContent: "end", gap: 2, mt: 5 }}>
            <ErrorButton
              onClick={() => {
                setDeleteModalOpen(false);
                deleteMethod();
              }}
            >
              {t("stripe.deleteModal.yes")}
            </ErrorButton>
            <Button onClick={() => setDeleteModalOpen(false)} variant="text">
              {t("stripe.deleteModal.no")}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export const StripeCardForm = ({ forModal = false }: StripeCardFormProps) => {
  const { t, i18n } = useTranslation("cms");

  const tenantId = getTenantId();
  const { formatErrorMessage } = useServerErrorFormatter();

  const { data, isLoading, error } = useQuery({
    queryFn: () => getBillingData(tenantId!),
    queryKey: ["BillingData"],
    enabled: !!tenantId,
  });

  const { data: secret, error: secretError } = useQuery({
    queryFn: () => generateStripeSecret(tenantId!),
    queryKey: ["StripeSecret"],
    enabled: !!data,
  });

  const { data: publishableKey, error: publishableKeyError } = useQuery({
    queryFn: () => getPublishableKey(tenantId!),
    queryKey: ["StripePublishableKey"],
    enabled: !!data,
  });

  if (data && secret && publishableKey) {
    const stripePromise = loadStripe(publishableKey!);

    const elementsOptions: StripeElementsOptions = {
      clientSecret: secret,
      locale: i18n.language as StripeElementLocale,
      appearance: {
        theme: "night",
      },
    };

    return (
      <>
        <Elements key={secret} stripe={stripePromise} options={elementsOptions}>
          <StripeCardInput secret={secret} forModal={forModal} />
        </Elements>
      </>
    );
  }

  return (
    <>
      {error && (error as AxiosError)?.status !== 404 && (
        <ErrorMessage
          errorMessage={formatErrorMessage(
            error as AxiosError<HttpValidationProblemDetails>
          )}
        />
      )}
      {secretError && (
        <ErrorMessage
          errorMessage={formatErrorMessage(
            secretError as AxiosError<HttpValidationProblemDetails>
          )}
        />
      )}
      {publishableKeyError && (
        <ErrorMessage
          errorMessage={formatErrorMessage(
            publishableKeyError as AxiosError<HttpValidationProblemDetails>
          )}
        />
      )}
      {!data && !isLoading && (
        <ErrorMessage errorMessage={t("stripe.noBillingData")} />
      )}
    </>
  );
};
