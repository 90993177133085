"use client";
import ReactCountryFlag from "react-country-flag";

import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useParams, usePathname, useRouter } from "next/navigation";
import { LanguageCode } from "@repo/types/customerApi.types";
import { useTranslation } from "@repo/i18n-config";
import { useMemo } from "react";

interface ImportMetaEnv {
  readonly VITE_IS_VITE: string;
}

interface ImportMeta {
  readonly env: ImportMetaEnv;
}
interface LanguageSelectorInternalProps {
  value: string;
  onChange: (event: SelectChangeEvent) => void;
}

const LanguageSelectorInternal = ({
  value,
  onChange,
}: LanguageSelectorInternalProps) => {
  const { t } = useTranslation("common");

  const languages = useMemo(() => {
    return Object.values(LanguageCode).map((lang) => ({
      value: lang,
      label: t(`languages.${lang}`),
      countryCode: lang == "en" ? "us" : lang,
    }));
  }, []);

  return (
    <Select
      value={value}
      onChange={onChange}
      size="small"
      renderValue={(p) => (
        <Box sx={{ mb: 0.25 }}>
          {p == "pl" ? (
            <ReactCountryFlag countryCode="pl" svg />
          ) : (
            <ReactCountryFlag countryCode="us" svg />
          )}
        </Box>
      )}
      MenuProps={{
        sx: {
          zIndex: 12001,
        },
      }}
    >
      {languages.map((lang) => (
        <MenuItem
          key={lang.value}
          value={lang.value}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <ReactCountryFlag countryCode={lang.countryCode} svg />
          {lang.label}
        </MenuItem>
      ))}
    </Select>
  );
};

const LanguageSelectorNext = () => {
  const pathname = usePathname();
  const { push } = useRouter();
  const params = useParams();
  const handleChange = (event: SelectChangeEvent) => {
    push(pathname.replace(`/${params.lng}`, `/${event.target.value}`));
  };

  return (
    <LanguageSelectorInternal
      value={params.lng as string}
      onChange={handleChange}
    />
  );
};

const LanguageSelectorVite = () => {
  const { i18n } = useTranslation("common");
  const handleChange = (event: SelectChangeEvent) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <LanguageSelectorInternal value={i18n.language} onChange={handleChange} />
  );
};

export const LanguageSelector = () => {
  if ((import.meta as unknown as ImportMeta).env?.VITE_IS_VITE)
    return <LanguageSelectorVite />;
  else return <LanguageSelectorNext />;
};
