"use client";

import React from "react";
import {
  FormControl,
  FormGroup,
  InputLabel,
  InputProps,
  OutlinedInput,
} from "@mui/material";
import {
  DeepKeys,
  FieldApi,
  FieldApiOptions,
  ReactFormApi,
} from "@tanstack/react-form";
import { FieldInfo } from "../FieldInfo";
import { IMaskInput } from "react-imask";

type TextInputProps<T> = {
  form: ReactFormApi<T, any>;
  name: DeepKeys<T>;
  handleFieldChange?: (
    value: string,
    field: FieldApi<any, any, any, any, any>
  ) => void;
  required?: boolean;
  validators?: FieldApiOptions<any, any, any, any, any>["validators"];
} & InputProps & {
    label: string;
  };

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="####-####"
        definitions={{
          "#": /[0-9]/,
        }}
        lazy={false}
        inputRef={ref}
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
        overwrite
        type="tel"
        inputMode="numeric"
      />
    );
  }
);

export const CodeTextInput = <T,>(props: TextInputProps<T>) => {
  const { validators, handleFieldChange, ...fieldProps } = props;
  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    field: FieldApi<any, any, any, any, any>
  ): void => {
    handleFieldChange
      ? handleFieldChange(e.target.value, field)
      : field.handleChange(e.target.value);
  };

  return (
    <props.form.Field
      name={props.name}
      children={(field) => (
        <FormGroup style={{ width: props.fullWidth ? "100%" : "" }}>
          <FormControl variant="outlined">
            <InputLabel
              htmlFor={fieldProps.name}
              required={props.required}
              shrink
            >
              {fieldProps.label}
            </InputLabel>
            <OutlinedInput
              value={field.state.value}
              onChange={(e) => handleChange(e, field)}
              id={fieldProps.name}
              inputComponent={TextMaskCustom as any}
              notched
              autoComplete="off"
              {...fieldProps}
            />
          </FormControl>
          <FieldInfo field={field} />
        </FormGroup>
      )}
      validators={validators}
    />
  );
};
