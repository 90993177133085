import { COOKIE_CONFIG } from "@repo/api-config";
import { DashboardCompanyLayout } from "./DashboardCompanyLayout";
import { DashboardPendingOwnerLayout } from "./DashboardPendingOwnerLayout";
import { getCookie } from "@repo/utils";

const DashboardPage = () => {
  const role = getCookie(COOKIE_CONFIG.LOYMEE_USER_ROLE.name);
  if (role === "Owner") return <DashboardCompanyLayout />;
  if (role === "PendingOwner") return <DashboardPendingOwnerLayout />;
};

export default DashboardPage;
