import {
  Cluster,
  ClusterStats,
  Marker,
  MarkerUtils,
  Renderer,
} from "@googlemaps/markerclusterer";

export class ClustererRenderer implements Renderer {
  public render(
    { count, position }: Cluster,
    _stats: ClusterStats,
    map: google.maps.Map
  ): Marker {
    const color = "#1E1E1E";

    const svg = `<svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" width="50" height="50">
<circle cx="120" cy="120" opacity="1" r="70" />
<circle cx="120" cy="120" opacity=".7" r="90" />
<circle cx="120" cy="120" opacity=".5" r="110" />
<text x="50%" y="50%" style="fill:#CBFD13" text-anchor="middle" font-size="50" dominant-baseline="middle" font-family="roboto,arial,sans-serif">${count}</text>
</svg>`;

    const title = `Cluster of ${count} markers`,
      zIndex: number = Number(google.maps.Marker.MAX_ZINDEX) + count;

    if (MarkerUtils.isAdvancedMarkerAvailable(map)) {
      // create cluster SVG element
      const parser = new DOMParser();
      const svgEl = parser.parseFromString(
        svg,
        "image/svg+xml"
      ).documentElement;
      svgEl.setAttribute("transform", "translate(0 25)");

      const clusterOptions: google.maps.marker.AdvancedMarkerElementOptions = {
        map,
        position,
        zIndex,
        title,
        content: svgEl,
      };
      return new google.maps.marker.AdvancedMarkerElement(clusterOptions);
    }

    const clusterOptions: google.maps.MarkerOptions = {
      position,
      zIndex,
      title,
      icon: {
        url: `data:image/svg+xml;base64,${btoa(svg)}`,
        anchor: new google.maps.Point(25, 25),
      },
    };
    return new google.maps.Marker(clusterOptions);
  }
}
