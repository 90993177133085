import {
  CompanyDto,
  CompanyStatsForRootDto,
  CompanySummaryDtoPaginatedList,
  RegisterCompanyAsAgentCommand,
  RootReferredCompanyDtoPaginatedList,
  SortQueryBy,
} from "@repo/types/rootApi.types";
import { httpClient } from "../../http-client";
import { rootApiPaths } from "./paths";
import { PaginationParams, StatisticsChartTimePeriod } from "../../types";

export interface EditCompanyRequest {
  companyName: string;
  phoneNumber: string;
  multiplier: number;
}

export type GetReferredCompaniesListRequest = PaginationParams & {
  sortBy: SortQueryBy;
  sortAscending: boolean;
};

export const getCompaniesList = async (params: PaginationParams) => {
  const response = await httpClient.get<CompanySummaryDtoPaginatedList>(
    rootApiPaths.companies.base(params)
  );

  return response.data;
};

export const getReferredCompaniesList = async (
  params: GetReferredCompaniesListRequest
) => {
  const response = await httpClient.get<RootReferredCompanyDtoPaginatedList>(
    rootApiPaths.companies.referred(params)
  );

  return response.data;
};

export const getCompanyById = async (id: string) => {
  const response = await httpClient.get<CompanyDto>(
    rootApiPaths.companies.getCompanyById(id)
  );

  return response.data;
};

export const getCompanyStatistics = async (
  id: string,
  period: StatisticsChartTimePeriod
) => {
  const response = await httpClient.get<CompanyStatsForRootDto>(
    rootApiPaths.companies.getCompanyStatistics(id, period)
  );

  return response.data;
};

export const newCompany = async (payload: RegisterCompanyAsAgentCommand) => {
  const response = await httpClient.post(rootApiPaths.companies.new, payload);

  return response.data;
};

export const editCompany = async (
  companyId: string,
  payload: EditCompanyRequest
) => {
  const response = await httpClient.patch(
    rootApiPaths.companies.update(companyId),
    payload
  );

  return response.data;
};

export const deleteCompany = async (id: string) => {
  const response = await httpClient.delete(rootApiPaths.companies.update(id));

  return response.data;
};

export const confirmCompany = async (identifier: string) => {
  const response = await httpClient.post(
    rootApiPaths.companies.confirm(identifier),
    {
      identifier,
      isConfirmed: true,
    }
  );

  return response.data;
};
