"use client";

import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  ArrowBackOutlined,
  CameraAlt,
  Email,
  Factory,
  Grading,
  Logout,
  Loyalty,
  Mail,
  MenuOutlined,
  MilitaryTech,
  Notifications,
  People,
  QrCodeScanner,
  ReceiptLong,
  Settings,
  ShieldOutlined,
  SpaceDashboard,
  Store,
  Storefront,
} from "@mui/icons-material";
import {
  Link,
  Outlet,
  useNavigate,
  useRouterState,
} from "@tanstack/react-router";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { LanguageSelector, LinkWrapper, Logo } from "..";
import {
  COOKIE_CONFIG,
  useCmsRole,
  useCompanyAuth,
  useMe,
} from "@repo/api-config";
import {
  AppRouteValues,
  cmsRoutes,
  getCookie,
  superAdminRoutes,
  useNotificationsContext,
} from "@repo/utils";
import { useTranslation } from "@repo/i18n-config";
import { getCurrentSubscription } from "@repo/api-config/services/company";

interface MenuItem {
  title: string;
  link: string;
  icon: JSX.Element;
}

const drawerWidth: string = "280px";

export const DashboardTemplate = () => {
  const { logout } = useCompanyAuth();
  const navigate = useNavigate();
  const { userRole } = useMe(useCmsRole());

  const { t, i18n } = useTranslation("ui");

  const { data: currentSubscription } = useQuery({
    queryKey: ["currentSubscription"],
    queryFn: () =>
      getCurrentSubscription(getCookie(COOKIE_CONFIG.LOYMEE_TENANT_ID.name)!),
    enabled: userRole === "PendingOwner",
  });

  const menuItems: MenuItem[] = useMemo(
    () => [
      ...(userRole === "Agent" || userRole === "SuperAdmin"
        ? [
            {
              title: "Dashboard",
              link: superAdminRoutes.home.base,
              icon: <SpaceDashboard />,
            },
          ]
        : []),
      ...(userRole === "Agent"
        ? [
            {
              title: t("Dashboard.menuItems.contacts"),
              link: superAdminRoutes.contact.base,
              icon: <Email />,
            },
          ]
        : []),
      ...(userRole === "SuperAdmin"
        ? [
            {
              title: t("Dashboard.menuItems.companies"),
              link: superAdminRoutes.companies.base,
              icon: <Factory />,
            },
            {
              title: t("Dashboard.menuItems.plans"),
              link: superAdminRoutes.plans.base,
              icon: <Loyalty />,
            },
            {
              title: t("Dashboard.menuItems.agents"),
              link: superAdminRoutes.agents.base,
              icon: <Storefront />,
            },
          ]
        : []),
      ...(userRole === "Owner"
        ? [
            {
              title: "Dashboard",
              link: cmsRoutes.home.base,
              icon: <SpaceDashboard />,
            },
            {
              title: t("Dashboard.menuItems.rewards"),
              link: cmsRoutes.rewards.base,
              icon: <MilitaryTech />,
            },
            {
              title: t("Dashboard.menuItems.employees"),
              link: cmsRoutes.employees.base,
              icon: <QrCodeScanner />,
            },
            {
              title: t("Dashboard.menuItems.goToScanner"),
              link: cmsRoutes.scanner.base,
              icon: <CameraAlt />,
            },
            {
              title: t("Dashboard.menuItems.customers"),
              link: cmsRoutes.customers.base,
              icon: <People />,
            },
            {
              title: t("Dashboard.menuItems.locations"),
              link: cmsRoutes.locations.base,
              icon: <Store />,
            },
            {
              title: t("Dashboard.menuItems.notifications"),
              link: cmsRoutes.notifications.base,
              icon: <Notifications />,
            },
            {
              title: t("Dashboard.menuItems.mail"),
              link: cmsRoutes.mailNotifications.base,
              icon: <Mail />,
            },
            {
              title: t("Dashboard.menuItems.invoices"),
              link: cmsRoutes.invoices.base,
              icon: <ReceiptLong />,
            },
            {
              title: t("Dashboard.menuItems.settings"),
              link: cmsRoutes.settings.base,
              icon: <Settings />,
            },
            {
              title: t("Dashboard.menuItems.contacts"),
              link: cmsRoutes.contact.base,
              icon: <Email />,
            },
          ]
        : []),
      ...(userRole === "PendingOwner"
        ? [
            {
              title: "Dashboard",
              link: cmsRoutes.home.base,
              icon: <SpaceDashboard />,
            },
            ...(currentSubscription
              ? [
                  {
                    title: t("Dashboard.menuItems.rewards"),
                    link: cmsRoutes.rewards.base,
                    icon: <MilitaryTech />,
                  },
                  {
                    title: t("Dashboard.menuItems.employees"),
                    link: cmsRoutes.employees.base,
                    icon: <QrCodeScanner />,
                  },
                  {
                    title: t("Dashboard.menuItems.locations"),
                    link: cmsRoutes.locations.base,
                    icon: <Store />,
                  },
                  {
                    title: t("Dashboard.menuItems.settings"),
                    link: cmsRoutes.settings.base,
                    icon: <Settings />,
                  },
                ]
              : []),
            {
              title: t("Dashboard.menuItems.contacts"),
              link: cmsRoutes.contact.base,
              icon: <Email />,
            },
          ]
        : []),
      {
        title: t("Dashboard.menuItems.termsOfService"),
        icon: (
          <Grading
            sx={{
              height: 24,
              width: 24,
            }}
          />
        ),
        link: `https://loymee.com/tos-company/TermsOfService_company_${i18n.language}.pdf`,
      },
      {
        title: t("Dashboard.menuItems.privacyPolicy"),
        icon: (
          <ShieldOutlined
            sx={{
              height: 24,
              width: 24,
            }}
          />
        ),
        link: `https://loymee.com/privacy/PrivacyPolicy_${i18n.language}.pdf`,
      },
    ],
    [userRole, currentSubscription, i18n.language]
  );

  const { showNotification } = useNotificationsContext();
  const { t: commonT } = useTranslation("common");
  const { mutate: logoutMutation } = useMutation({
    mutationFn: logout,
    onSuccess: () => {
      showNotification(commonT("loggedOut"));
      navigate({
        to: "/login",
      });
    },
  });

  const { location } = useRouterState();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box sx={{ pl: isMobile ? 0 : drawerWidth }}>
      <Drawer
        PaperProps={{
          elevation: 7,
          sx: { width: 280, border: "none" },
        }}
        open={mobileMenuOpen}
        variant={isMobile ? "temporary" : "permanent"}
        onClose={() => setMobileMenuOpen(false)}
      >
        <Box
          component={Link}
          to={cmsRoutes.home.base}
          sx={{ px: 4, py: 2, my: 2 }}
          display="flex"
          justifyContent="center"
        >
          <Logo theme="dark" />
        </Box>
        <List
          sx={{
            p: 2,
            pb: 3,
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {menuItems.map((item) =>
            item.link.startsWith("mailto:") || item.link.endsWith(".pdf") ? (
              <a
                href={item.link}
                key={`menuItem_${item.title}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ListItemButton
                  onClick={() => setMobileMenuOpen(false)}
                  selected={location.pathname === item.link}
                >
                  {item.icon}
                  {item.title}
                </ListItemButton>
              </a>
            ) : (
              <LinkWrapper
                to={item.link as AppRouteValues}
                key={`menuItem_${item.title}`}
              >
                <ListItemButton
                  onClick={() => setMobileMenuOpen(false)}
                  selected={location.pathname === item.link}
                >
                  {item.icon}
                  {item.title}
                </ListItemButton>
              </LinkWrapper>
            )
          )}
          <Box
            sx={{
              mt: "auto",
            }}
          >
            <ListItemButton
              onClick={() => logoutMutation()}
              sx={{
                cursor: "pointer",
              }}
            >
              <Logout />
              {t("Dashboard.logout")}
            </ListItemButton>
          </Box>
        </List>
        {(userRole === "Owner" ||
          userRole === "PendingOwner" ||
          userRole === "Agent") && (
          <Box
            sx={{
              pl: 4,
              pb: 3,
            }}
          >
            <LanguageSelector />
          </Box>
        )}
      </Drawer>
      <Box sx={{ pt: isMobile ? 2 : 4, pb: 2, mx: isMobile ? 2 : 4 }}>
        {isMobile && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              sx={{
                ml: -1.5,
              }}
            >
              <MenuOutlined />
            </IconButton>
            <LinkWrapper to={cmsRoutes.home.base}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Logo type="mini" theme="dark" height={29} />
              </Box>
            </LinkWrapper>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            mb: 1,
            mt: {
              xs: 2,
              md: 0,
            },
          }}
        >
          {(location.pathname.endsWith("/new") ||
            location.pathname.includes("/edit")) && (
            <IconButton
              onClick={() =>
                navigate({
                  to: location.pathname
                    .replace("/new", "")
                    .replace(new RegExp("/edit.*"), ""),
                })
              }
              sx={{
                ml: -1.5,
              }}
            >
              <ArrowBackOutlined />
            </IconButton>
          )}
          <Typography variant="h5" fontWeight={700}>
            {menuItems.find((item) => item.link === location.pathname)?.title}
          </Typography>
        </Box>
        <Outlet />
      </Box>
    </Box>
  );
};
