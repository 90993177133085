import { MessageType } from "@repo/types/companyApi.types";
import { EmployeeRole, StatisticsChartTimePeriod } from "../../types";

const basePath = "/company/api/v1";

export const companyApiPaths = {
  companies: {
    base: `${basePath}/companies`,
    getByTenant: (tenantId: string) => `${basePath}/${tenantId}/companies`,
    registrationStatus: (tenantId: string) =>
      `${basePath}/${tenantId}/companies/registration-status`,
    endRegistration: (tenantId: string) =>
      `${basePath}/${tenantId}/companies/end-registration`,
  },
  customers: {
    base: (tenantId: string, pageNumber: number, pageSize: number) =>
      `${basePath}/${tenantId}/customers?pageNumber=${pageNumber}&pageSize=${pageSize}`,
  },
  auth: {
    base: `/${basePath}/auth`,
    info: (tenantId: string) => `${basePath}/${tenantId}/auth/info`,
    login: `${basePath}/login`,
    refresh: (tenantId: string) => `${basePath}/${tenantId}/auth/refresh`,
    confirmEmail: (tenantId: string) =>
      `${basePath}/${tenantId}/auth/confirm-email`,
    confirmEmailSetPassword: (tenantId: string) =>
      `${basePath}/${tenantId}/auth/confirm-email-set-password`,
    resendConfirmationEmail: `${basePath}/auth/resendConfirmationEmail`,
    forgotPassword: `${basePath}/auth/forgot-password`,
    resetPassword: (tenantId: string) =>
      `${basePath}/${tenantId}/auth/reset-password`,
  },
  employees: {
    base: (tenantId: string, role?: EmployeeRole) =>
      `${basePath}/${tenantId}/employees${role ? `?role=${role}` : ""}`,
    update: (tenantId: string, id: string) =>
      `${companyApiPaths.employees.base(tenantId)}/${id}`,
    delete: (tenantId: string, id: string) =>
      `${companyApiPaths.employees.base(tenantId)}/${id}`,
  },
  locations: {
    base: (tenantId: string) => `${basePath}/${tenantId}/locations`,
    delete: (tenantId: string, id: string) =>
      `${companyApiPaths.locations.base(tenantId)}/${id}`,
  },
  rewards: {
    base: (tenantId: string) => `${basePath}/${tenantId}/rewards`,
    update: (tenantId: string, id: number) =>
      `${basePath}/${tenantId}/rewards/${id}`,
    delete: (tenantId: string, id: number) =>
      `${basePath}/${tenantId}/rewards/${id}`,
  },
  scans: {
    base: (tenantId: string) => `${basePath}/${tenantId}/scans`,
    scanCustomerCode: (tenantId: string) =>
      `${basePath}/${tenantId}/scans/scan-customer-code`,
    scanRewardRequestCode: (tenantId: string) =>
      `${basePath}/${tenantId}/scans/scan-reward-request-code`,
  },
  statistics: {
    base: (tenantId: string) => `${basePath}/${tenantId}/statistics`,
    chart: (tenantId: string, period: StatisticsChartTimePeriod) =>
      `${basePath}/${tenantId}/statistics/chart?period=${period}`,
    period: (tenantId: string, period: StatisticsChartTimePeriod) =>
      `${basePath}/${tenantId}/statistics/period?period=${period}`,
  },
  messages: {
    history: (
      tenantId: string,
      pageNumber: number,
      pageSize: number,
      type: MessageType
    ) =>
      `${basePath}/${tenantId}/messages/history?pageNumber=${pageNumber}&pageSize=${pageSize}&type=${type}`,
    delete: (tenantId: string, id: string) =>
      `${basePath}/${tenantId}/messages/${id}`,
  },
  notifications: {
    topic: (tenantId: string) => `${basePath}/${tenantId}/notifications/topic`,
    user: (tenantId: string) => `${basePath}/${tenantId}/notifications/user`,
    plans: {
      base: (tenantId: string, pageNumber: number, pageSize: number) =>
        `${basePath}/${tenantId}/plans?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    },
  },
  emails: {
    subscribers: (tenantId: string) =>
      `${basePath}/${tenantId}/emails/subscribers`,
  },
  plans: {
    base: (tenantId: string) => `${basePath}/${tenantId}/plans`,
  },
  invoices: {
    base: (tenantId: string, pageNumber?: number, pageSize?: number) =>
      `${basePath}/${tenantId}/invoices${pageNumber && pageSize ? `?pageNumber=${pageNumber}&pageSize=${pageSize}` : ""}`,
  },
  billing: {
    profile: (tenantId: string) => `${basePath}/${tenantId}/billing/profile`,
  },
  stripe: {
    secret: (tenantId: string) => `${basePath}/${tenantId}/stripe/secret`,
    publishableKey: (tenantId: string) =>
      `${basePath}/${tenantId}/stripe/publishable-key`,
  },
  paymentMethods: {
    base: (tenantId: string) => `${basePath}/${tenantId}/payment-methods/`,
    change: (tenantId: string, paymentId: string) =>
      `${basePath}/${tenantId}/payment-methods/${paymentId}`,
  },
  subscriptions: {
    base: (tenantId: string) => `${basePath}/${tenantId}/subscriptions`,
    current: (tenantId: string) =>
      `${basePath}/${tenantId}/subscriptions/partner`,
    change: (tenantId: string, subId: string) =>
      `${basePath}/${tenantId}/subscriptions/${subId}`,
    cancelCancellation: (tenantId: string, subId: string) =>
      `${basePath}/${tenantId}/subscriptions/${subId}/cancel-cancellation`,
  },
  flags: {
    base: (tenantId: string) => `${basePath}/${tenantId}/flags`,
  },
};
