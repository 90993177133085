"use client";

import {
  Box,
  Container,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { EmployeeQrScanner } from "./scanner";
import {
  BottomDrawer,
  BottomDrawerMenu,
  GreyButton,
  LanguageSelector,
  Loader,
} from "@repo/ui";
import { useMutation } from "@tanstack/react-query";
import { useCmsRole, useCompanyAuth, useMe } from "@repo/api-config";
import { cmsRoutes, useNotificationsContext } from "@repo/utils";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "@repo/i18n-config";
import {
  ArrowBack,
  InstallMobile,
  Logout,
  Menu as MenuIcon,
  TextFields,
} from "@mui/icons-material";
import { isIOS, isMacOs, isSafari } from "react-device-detect";
import { Link, useRouter } from "@tanstack/react-router";
import { useIsPWA, usePWAInstall } from "@repo/utils";

export default function ScannerPage() {
  const { logout } = useCompanyAuth();
  const { navigate } = useRouter();

  const { t } = useTranslation("cms");
  const { t: commonT } = useTranslation("common");
  const { showNotification } = useNotificationsContext();
  const [loggingOut, setLoggingOut] = useState(false);
  const menuRef = useRef<HTMLButtonElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const { mutate: logoutMutation } = useMutation({
    mutationFn: logout,
    onSuccess: () => {
      setLoggingOut(true);
      showNotification(commonT("loggedOut"));
      navigate({ to: cmsRoutes.login.base });
    },
  });
  const smallScreen = useMediaQuery("@media screen and (max-height: 600px)");
  const { userRole } = useMe(useCmsRole());
  const { install, canPrompt } = usePWAInstall();
  const isPWA = useIsPWA();
  const [iOSDrawerOpen, setIOSDrawerOpen] = useState(false);

  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(true);
  }, []);

  const isStandalone = useMediaQuery(
    "@media all and (display-mode: standalone)"
  );

  if (loggingOut) return <Loader />;
  return (
    <Container maxWidth="sm" sx={{ p: 0 }}>
      <Box
        sx={{
          px: 3,
          py: 6,
          position: "relative",
          minHeight:
            isSafari && isStandalone
              ? "100svh"
              : "calc(100svh - env(safe-area-inset-bottom, 0))",
          backgroundColor: "#040104",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
            }}
          >
            {userRole === "Owner" && (
              <Link to={cmsRoutes.home.base}>
                <IconButton
                  sx={(theme) => ({
                    color: theme.palette.common.white,
                  })}
                >
                  <ArrowBack />
                </IconButton>
              </Link>
            )}
            <Typography
              variant="h5"
              fontWeight={700}
              sx={(theme) => ({ color: theme.palette.common.white })}
            >
              {t("employeeScan.page.header")}
            </Typography>
          </Box>
          <IconButton ref={menuRef} onClick={() => setMenuOpen(true)}>
            <MenuIcon
              sx={(theme) => ({
                color: theme.palette.common.white,
              })}
            />
          </IconButton>
          <Menu
            open={menuOpen}
            anchorEl={menuRef.current}
            onClose={() => setMenuOpen(false)}
          >
            <MenuList sx={{ p: 0 }}>
              {!isPWA && loaded && (isIOS || isMacOs || canPrompt()) && (
                <MenuItem
                  onClick={() => {
                    setMenuOpen(false);
                    isIOS || isMacOs ? setIOSDrawerOpen(true) : install();
                  }}
                >
                  <ListItemIcon
                    sx={{
                      position: "relative",
                      left: -3,
                    }}
                  >
                    <InstallMobile />
                  </ListItemIcon>
                  <ListItemText>
                    {t("employeeScan.page.addToHomeScreen")}
                  </ListItemText>
                </MenuItem>
              )}
              <MenuItem onClick={() => logoutMutation()}>
                <ListItemIcon>
                  <Logout />
                </ListItemIcon>
                <ListItemText>{t("employeeScan.page.logout")}</ListItemText>
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
        <Box
          sx={
            smallScreen
              ? {}
              : {
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  width: "100%",
                  px: 3,
                  transform: "translate(-50%, -50%)",
                }
          }
        >
          <EmployeeQrScanner smallScreen={smallScreen} />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 1,
          }}
        >
          <LanguageSelector />
          <Link to={cmsRoutes.scanner.text}>
            <GreyButton
              startIcon={<TextFields />}
              sx={{
                alignSelf: "end",
              }}
            >
              {t("employeeScan.page.text")}
            </GreyButton>
          </Link>
        </Box>
      </Box>
      <BottomDrawer
        open={iOSDrawerOpen}
        onClose={() => setIOSDrawerOpen(false)}
      >
        <BottomDrawerMenu
          header={t("employeeScan.page.iOSDrawer.header")}
          items={[
            {
              action: () => navigate({ to: cmsRoutes.scanner.installPwa }),
              icon: <InstallMobile />,
              text: t("employeeScan.page.iOSDrawer.button"),
            },
          ]}
        />
      </BottomDrawer>
    </Container>
  );
}
