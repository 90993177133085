import { createFileRoute } from "@tanstack/react-router";
import SettingsPage from "../../../../pages/Settings/SettingsPage";

export interface SettingsParams {
  tab?: "basicData" | "paymentSubscription";
}

export const Route = createFileRoute("/_private/_withDashboard/settings/")({
  component: () => <SettingsPage />,
  validateSearch: (search: Record<string, unknown>): SettingsParams => {
    // validate and parse the search params into a typed state
    return {
      tab: (search.tab as SettingsParams["tab"]) || "basicData",
    };
  },
});
