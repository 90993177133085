import { COOKIE_CONFIG, useCompanyAuth } from "@repo/api-config";
import { getUserInfo } from "@repo/api-config/services/company";
import { AuthResponse } from "@repo/api-config/types";
import { useTranslation } from "@repo/i18n-config";
import {
  HttpValidationProblemDetails,
  LoginRequest,
} from "@repo/types/companyApi.types";
import {
  cmsRoutes,
  eraseCookie,
  setCookie,
  useNotificationsContext,
  useServerErrorFormatter,
} from "@repo/utils";
import { useForm } from "@tanstack/react-form";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { valibotValidator } from "@tanstack/valibot-form-adapter";
import { AxiosError } from "axios";

export const useLoginForm = () => {
  const auth = useCompanyAuth();
  const navigate = useNavigate();
  const { formatErrorMessage } = useServerErrorFormatter();

  const { t: commonT, i18n } = useTranslation("common");
  const { showNotification } = useNotificationsContext();

  const {
    mutate: loginMutation,
    isPending,
    error,
  } = useMutation<AuthResponse, AxiosError, LoginRequest>({
    mutationFn: (values) => auth.signIn(values),
    onSuccess: async ({ tenantId }) => {
      showNotification(commonT("loggedIn"));
      eraseCookie(COOKIE_CONFIG.LOYMEE_USER_ROLE.name);
      const data = await getUserInfo(tenantId!);
      setCookie(
        COOKIE_CONFIG.LOYMEE_USER_ROLE.name,
        data.userRole || "Owner",
        COOKIE_CONFIG.LOYMEE_USER_ROLE.duration
      );
      i18n.changeLanguage(data.languageCode);
      // must be called in the next tick to avoid language being set to original value
      setTimeout(() => {
        if (data.userRole === "Operator") {
          navigate({
            to: cmsRoutes.scanner.base,
          });
        } else if (data.userRole?.includes("Owner")) {
          navigate({ to: "/" });
        } else {
          auth.logout();
        }
      });
    },
  });

  const form = useForm<LoginRequest, any>({
    defaultValues: {
      email: "",
      password: "",
    },
    onSubmit: ({ value }) => {
      loginMutation({
        email: value.email.trim(),
        password: value.password.trim(),
      });
    },
    validatorAdapter: valibotValidator(),
  });

  return {
    form,
    isPending,
    errorMessage: error
      ? formatErrorMessage(error as AxiosError<HttpValidationProblemDetails>)
      : undefined,
  };
};
