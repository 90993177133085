"use client";

import SubscriptionOptions from "./SubscriptionOptions";
import PaymentsInfo from "./PaymentsInfo";
import { ChangePassword } from "./ChangePassword";
import { EmailInfo } from "./EmailInfo";
import { Box, Button, Tab, Tabs } from "@mui/material";
import CompanyInfo from "./CompanyInfo";
import { SyntheticEvent } from "react";
import { useTranslation } from "@repo/i18n-config";
import { useRouter, useSearch } from "@tanstack/react-router";
import { SettingsParams } from "../../routes/_private/_withDashboard/settings";

const SettingsPage = () => {
  const router = useRouter();
  const handleChange = (_event: SyntheticEvent, newValue: string) => {
    router.navigate({
      // @ts-ignore
      search: { tab: newValue } as SettingsParams,
    });
  };

  const { t } = useTranslation("cms");

  const search: SettingsParams = useSearch({
    strict: false,
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Tabs
        value={search.tab || "basicData"}
        onChange={handleChange}
        variant="fullWidth"
      >
        <Tab value="basicData" label={t("Settings.tabs.basicData")} />
        <Tab
          value="paymentSubscription"
          label={t("Settings.tabs.paymentSubscription")}
        />
      </Tabs>
      {(!search.tab || search.tab === "basicData") && (
        <>
          <EmailInfo />
          <CompanyInfo />
          <ChangePassword />
        </>
      )}
      {search.tab === "paymentSubscription" && (
        <>
          <PaymentsInfo />
          <SubscriptionOptions />
        </>
      )}
      <Button
        variant="text"
        color="primary"
        size="small"
        sx={{
          alignSelf: "start",
        }}
        className="cky-banner-element"
      >
        {t("Settings.cookieSettings")}
      </Button>
    </Box>
  );
};
export default SettingsPage;
