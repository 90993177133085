import { Box, Typography, Drawer, ButtonProps } from "@mui/material";
import { ReactNode } from "react";
import { ListItemButton } from "./ListItemButton";
import { useTranslation } from "@repo/i18n-config";

interface BottomDrawerProps {
  children: ReactNode;
  open: boolean;
  onClose: () => void;
}

interface BottomDrawerMenuProps {
  items: {
    text: string;
    icon: ReactNode;
    action: () => void;
    buttonSx?: ButtonProps["sx"];
  }[];
  header?: string;
}

export const BottomDrawer = ({
  children,
  open,
  onClose,
}: BottomDrawerProps) => {
  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiPaper-root": {
          overflowX: "hidden",
          overflowY: "auto",
          maxHeight: "90dvh",
        },
      }}
    >
      {children}
    </Drawer>
  );
};

export const BottomDrawerMenu = ({ items, header }: BottomDrawerMenuProps) => {
  
  const { t } = useTranslation("ui");

  return (
    <>
      <Typography
        variant="h6"
        sx={{
          mb: 2,
        }}
      >
        {header || t("BottomDrawer.chooseAction")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {items.map((item, index) => (
          <ListItemButton
            key={index}
            sx={[
              (theme) => ({
                gap: 1.5,
                padding: 2,
                borderRadius: 2,
                ...theme.applyStyles("dark", {
                  backgroundColor: "#1F1F1F",
                  color: theme.palette.text.primary,
                }),
              }),
              ...(Array.isArray(item.buttonSx)
                ? item.buttonSx
                : [item.buttonSx]),
            ]}
            onClick={item.action}
          >
            {item.icon}
            <Typography fontWeight={500}>{item.text}</Typography>
          </ListItemButton>
        ))}
      </Box>
    </>
  );
};
