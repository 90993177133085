"use client";

import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  IconButton,
  Link as MuiLink,
  Collapse,
  TableSortLabel,
} from "@mui/material";
import { Link } from "@tanstack/react-router";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { Resources, useTranslation } from "@repo/i18n-config";
import { CompanyStatus } from "./components/CompanyStatus";
import { CompanyVerification } from "./components/CompanyVerification";
import { CompanyVerificationStatus } from "@repo/types/rootApi.types";
import { PlanStatus } from "./components/PlanStatus";
import { DownloadButton } from "./components/DownloadButton";
import { Fragment, useState } from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { ExpirationDate } from "./components/ExpirationDate";
import { SubscriptionState } from "./components/SubscriptionState";
import { SortSettings } from "./DataTable";

export interface ShowActions {
  handleEdit?: boolean;
  isDeleteMutationPending?: boolean;
  handleDelete?: (id: string | number) => void;
}

const renderEmptyState = (message: React.ReactNode) => (
  <TableBody>
    <TableRow>
      <TableCell sx={{ textAlign: "center", py: 8 }}>{message}</TableCell>
    </TableRow>
  </TableBody>
);

export const renderTableHeader = (
  columnNames: string[],
  showActions: ShowActions,
  showCollapse?: boolean,
  sortSettings?: SortSettings,
  sortableColumns?: string[],
  handleSort?: (orderBy: string) => void
) => {
  const { t } = useTranslation("ui");

  const { handleEdit, handleDelete } = showActions;
  return (
    <TableHead>
      <TableRow>
        {showCollapse && <TableCell />}
        {columnNames.map((columnName, index) => (
          <TableCell key={index}>
            <TableSortLabel
              disabled={!sortableColumns?.includes(columnName)}
              active={
                sortableColumns?.includes(columnName) &&
                sortSettings?.orderBy === columnName
              }
              direction={sortSettings?.order}
              onClick={() => handleSort?.(columnName)}
            >
              {t(
                `DataTable.headers.${columnName as keyof Resources["ui"]["DataTable"]["headers"]}`
              )}
            </TableSortLabel>
          </TableCell>
        ))}
        {columnNames.length && (handleEdit || handleDelete) ? (
          <TableCell align="right">{t("DataTable.actions")}</TableCell>
        ) : null}
      </TableRow>
    </TableHead>
  );
};

interface TableItem {
  id: string | number;
  [key: string]: any;
}

export const renderTableBody = (
  items: TableItem[],
  isLoading: boolean,
  columnNames: string[],
  showActions: ShowActions,
  renderCollapseContent?: (item: any) => React.ReactNode
) => {
  const { t, i18n } = useTranslation("ui");
  const [collapseOpenList, setCollapseOpenList] = useState<(string | number)[]>(
    []
  );

  if (isLoading) {
    return renderEmptyState(<CircularProgress />);
  }

  if (items.length === 0) {
    return renderEmptyState(t("DataTable.noData"));
  }

  const { handleEdit, isDeleteMutationPending, handleDelete } = showActions;

  const formatItem = (item: TableItem, columnName: string) => {
    if (columnName === "verificationStatus") {
      return (
        <CompanyVerification
          id={item.id}
          status={item[columnName] as CompanyVerificationStatus}
          name={item.companyName}
        />
      );
    }
    if (columnName === "isActive") {
      return <CompanyStatus status={item[columnName] as boolean} />;
    }
    if (
      columnName === "pricePerMonth" ||
      columnName === "pricePerYear" ||
      columnName === "price" ||
      columnName === "totalCommission"
    ) {
      return (item[columnName] as number)?.toLocaleString(i18n.language, {
        style: "currency",
        currency: "PLN",
      });
    }
    if (
      columnName === "createdAt" ||
      columnName === "updatedAt" ||
      columnName === "sentAt"
    ) {
      return new Date(item[columnName] as string).toLocaleString(i18n.language);
    }
    if (columnName === "registrationDate") {
      return item[columnName]
        ? new Date(item[columnName] as string).toLocaleString(i18n.language, {
            dateStyle: "short",
          })
        : "-";
    }
    if (columnName === "expirationDate") {
      return <ExpirationDate data={item[columnName]} />;
    }
    if (columnName === "accessibility")
      return t(
        `DataTable.columns.accessibility.${item[columnName]}` as unknown as `DataTable.columns.accessibility.${keyof Resources["ui"]["DataTable"]["columns"]["accessibility"]}`
      );
    if (columnName === "planPrice")
      return `${
        item[columnName]["price"] !== null
          ? `${(item[columnName]["price"] as number)?.toLocaleString(
              i18n.language,
              {
                style: "currency",
                currency: "PLN",
              }
            )}/`
          : ""
      }${t(
        `DataTable.columns.planPrice.subscriptionFrequency.${item[columnName]["subscriptionFrequency"]}` as unknown as `DataTable.columns.planPrice.subscriptionFrequency.${keyof Resources["ui"]["DataTable"]["columns"]["planPrice"]["subscriptionFrequency"]}`
      )}`;
    if (columnName === "subscriptionState")
      return <SubscriptionState state={item[columnName]} />;
    if (columnName === "state")
      return t(
        `DataTable.columns.state.${item[columnName]}` as unknown as `DataTable.columns.state.${keyof Resources["ui"]["DataTable"]["columns"]["state"]}`
      );
    if (columnName === "isAbandoned")
      return <PlanStatus status={item[columnName] as boolean} />;
    if (columnName === "invoicePdfUrl")
      return <DownloadButton href={item[columnName] as string} />;
    return item[columnName];
  };

  return (
    <TableBody>
      {items.map((item) => (
        <Fragment key={item.id}>
          <TableRow sx={{ "& > *": { borderBottom: "unset !important" } }}>
            {renderCollapseContent && (
              <TableCell>
                <IconButton
                  size="small"
                  onClick={() =>
                    setCollapseOpenList((prev) =>
                      prev.includes(item.id)
                        ? prev.filter((id) => id !== item.id)
                        : [...prev, item.id]
                    )
                  }
                >
                  {collapseOpenList.includes(item.id) ? (
                    <KeyboardArrowUp />
                  ) : (
                    <KeyboardArrowDown />
                  )}
                </IconButton>
              </TableCell>
            )}
            {columnNames.map((columnName, index) => (
              <TableCell
                key={index}
                sx={{ maxWidth: 350, overflowWrap: "break-word" }}
              >
                {formatItem(item, columnName)}
              </TableCell>
            ))}
            {handleEdit || handleDelete ? (
              <TableCell align="right">
                {handleEdit ? (
                  <MuiLink component={Link} to={`edit/${item.id}`}>
                    <IconButton>
                      <EditIcon />
                    </IconButton>
                  </MuiLink>
                ) : null}
                {handleDelete ? (
                  <IconButton
                    onClick={() => handleDelete(item.id)}
                    disabled={isDeleteMutationPending}
                  >
                    <DeleteIcon />
                  </IconButton>
                ) : null}
              </TableCell>
            ) : null}
          </TableRow>
          {renderCollapseContent && (
            <TableRow>
              <TableCell
                style={{ padding: 0 }}
                colSpan={
                  columnNames.length + 1 + (handleEdit || handleDelete ? 1 : 0)
                }
              >
                <Collapse
                  in={collapseOpenList.includes(item.id)}
                  timeout="auto"
                  unmountOnExit
                >
                  {renderCollapseContent(item)}
                </Collapse>
              </TableCell>
            </TableRow>
          )}
        </Fragment>
      ))}
    </TableBody>
  );
};
