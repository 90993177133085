import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Switch,
  Typography,
} from "@mui/material";
import { getTenantId } from "@repo/api-config";
import {
  getCurrentSubscription,
  getPlans,
} from "@repo/api-config/services/company";
import { useTranslation } from "@repo/i18n-config";
import { Card, ErrorMessage } from "@repo/ui";
import { useServerErrorFormatter } from "@repo/utils";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { SubscriptionCard } from "./SubscriptionCard";
import { SubscriptionSettingsModal } from "./SubscriptionSettingsModal";
import {
  HttpValidationProblemDetails,
  SubscriptionFrequency,
  SubscriptionState,
} from "@repo/types/companyApi.types";

const SubscriptionOptions = () => {
  const { formatErrorMessage } = useServerErrorFormatter();
  const tenantId = getTenantId();

  const {
    data: subscriptionOptions,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["subscriptions"],
    queryFn: () => getPlans(tenantId!),
  });

  const { data: currentSubscription, isLoading: currentSubscriptionLoading } =
    useQuery({
      queryKey: ["currentSubscription"],
      queryFn: () => getCurrentSubscription(tenantId!),
      enabled: !!subscriptionOptions,
    });

  const { t } = useTranslation("cms");

  const subscriptions =
    subscriptionOptions &&
    [
      ...(subscriptionOptions.excludedPlan &&
      subscriptionOptions.excludedPlan.isAbandoned &&
      currentSubscription?.state === SubscriptionState.Cancelled
        ? []
        : [subscriptionOptions.excludedPlan!]),
      ...subscriptionOptions.plans,
    ].filter(Boolean);

  const [selectedFrequency, setSelectedFrequency] =
    useState<SubscriptionFrequency>(SubscriptionFrequency.Annually);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);

  useEffect(() => {
    if (currentSubscription)
      setSelectedFrequency(currentSubscription.frequency);
  }, [currentSubscription]);

  return (
    <div id="plans">
      <Card
        sx={{
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: { xs: 2, sm: 4 },
            flexDirection: {
              xs: "column",
              md: "row",
            },
          }}
        >
          <Box>
            <Typography variant="h6">
              {t("subscriptionOptions.cardTitle")}
            </Typography>
            <Typography>{t("subscriptionOptions.cardDescription")}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
              flexShrink: 0,
            }}
          >
            <Box>
              {t("subscriptionOptions.frequency.Monthly")}
              <Switch
                checked={selectedFrequency === SubscriptionFrequency.Annually}
                onClick={() =>
                  setSelectedFrequency(
                    selectedFrequency === SubscriptionFrequency.Annually
                      ? SubscriptionFrequency.Monthly
                      : SubscriptionFrequency.Annually
                  )
                }
              />
              {t("subscriptionOptions.frequency.Annually")}
            </Box>
            {selectedFrequency === SubscriptionFrequency.Annually && (
              <Chip
                label={t("subscriptionOptions.2MonthsFree")}
                color="primary"
                sx={{
                  fontWeight: 700,
                  mt: 0.5,
                }}
              />
            )}
          </Box>
        </Box>
        {currentSubscription &&
          currentSubscription.state !== SubscriptionState.Cancelled && (
            <>
              <Button
                color="secondary"
                sx={{
                  alignSelf: "end",
                }}
                onClick={() => setSettingsModalOpen(true)}
              >
                {t("subscriptionOptions.subscriptionSettings")}
              </Button>
              <SubscriptionSettingsModal
                open={settingsModalOpen}
                onClose={() => setSettingsModalOpen(false)}
                currentSubscription={currentSubscription}
              />
            </>
          )}

        {(isLoading || currentSubscriptionLoading) && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={28} />
          </Box>
        )}
        <Box
          sx={{
            display: "grid",
            flex: 1,
            rowGap: { xs: 2, sm: 6 },
            columnGap: 2,
            mt: { sm: 3 },
            gridTemplateColumns: {
              xs: "1fr",
              sm: "1fr 1fr",
              lg: "1fr 1fr 1fr",
            },
          }}
        >
          {error && (
            <ErrorMessage
              errorMessage={formatErrorMessage(
                error as AxiosError<HttpValidationProblemDetails>
              )}
            />
          )}
          {(!isLoading || !currentSubscriptionLoading) &&
            !error &&
            subscriptions?.map((item) => (
              <SubscriptionCard
                item={item}
                key={item.id}
                selectedFrequency={selectedFrequency}
              />
            ))}
        </Box>
      </Card>
    </div>
  );
};

export default SubscriptionOptions;
