import { Typography } from "@mui/material";
import { useTranslation } from "@repo/i18n-config";

interface PlanStatusProps {
  status: boolean;
}

export const PlanStatus = ({ status }: PlanStatusProps) => {
  
  const { t } = useTranslation("ui");

  return (
    <Typography
      variant="body2"
      sx={(theme) => ({
        color: status ? theme.palette.warning.main : theme.palette.success.main,
      })}
    >
      {t(`DataTable.columns.isAbandoned.${status as boolean}`)}
    </Typography>
  );
};
