import { Box, Typography } from "@mui/material";
import { Resources, useTranslation } from "@repo/i18n-config";
import { FieldApi } from "@tanstack/react-form";

export const FieldInfo = ({
  field,
}: {
  field: FieldApi<any, any, any, any>;
}) => {
  const { t } = useTranslation("ui");
  const { t: commonT } = useTranslation("common");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {field.state.meta.errors && field.state.meta.errors[0]
        ? field.state.meta.errors[0].split(",").map((v, index) => (
            <Typography color="error" variant="caption" key={index}>
              {commonT(
                v.trim() as unknown as `validators.${keyof Resources["common"]["validators"]}`
              )}
            </Typography>
          ))
        : null}
      {field.state.meta.isValidating ? (
        <Typography variant="caption">{t("FieldInfo.checking")}</Typography>
      ) : null}
    </Box>
  );
};
