import {
  AddRewardCommand,
  EditRewardCommand,
  RewardDto,
} from "@repo/types/companyApi.types";
import { companyApiPaths } from "./paths";
import { httpClient } from "../../http-client";

export const getRewardsList = async (tenantId: string) => {
  const response = await httpClient.get<RewardDto[]>(
    companyApiPaths.rewards.base(tenantId)
  );

  return response.data;
};

export const addReward = async (
  tenantId: string,
  { name, namePl, description, descriptionPl, pointCost }: AddRewardCommand
) => {
  const response = await httpClient.post(
    companyApiPaths.rewards.base(tenantId),
    {
      name,
      namePl,
      description,
      descriptionPl,
      pointCost,
    }
  );

  return response.data;
};

export const editReward = async (
  tenantId: string,
  { id, name, namePl, description, descriptionPl, pointCost }: EditRewardCommand
) => {
  const response = await httpClient.put(
    companyApiPaths.rewards.update(tenantId, id!),
    {
      id,
      name,
      namePl,
      description,
      descriptionPl,
      pointCost,
    }
  );

  return response.data;
};

export const deleteReward = async (tenantId: string, id: number) => {
  const response = await httpClient.delete(
    companyApiPaths.rewards.delete(tenantId, id)
  );

  return response.data;
};
