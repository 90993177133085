"use client";

import { useEffect } from "react";
import { parseLanguageCode, useTranslation } from ".";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { updateLanguage as updateLanguageCompany } from "../api-config/services/company";
import { updateLanguage as updateLanguageRoot } from "../api-config/services/root";
import { getTenantId, useMe } from "../api-config";
import { changeLoggedUserLanguage } from "../api-config/services/customer";
import {
  InfoRequest as CustomerInfoRequest,
  UserInfoResponse as CompanyUserInfoResponse,
} from "@repo/types/customerApi.types";
import {
  InfoRequest as CompanyInfoRequest,
  UserInfoResponse as CustomerUserInfoResponse,
} from "@repo/types/companyApi.types";

export const I18nSettingsSyncCompany = () => {
  const { i18n } = useTranslation("common");
  const tenantId = getTenantId();
  const me = useMe("company");
  const queryClient = useQueryClient();

  const { mutate } = useMutation<
    void,
    AxiosError,
    Pick<CompanyInfoRequest, "languageCode">
  >({
    mutationFn: (value) => updateLanguageCompany(value, tenantId!),
    onSuccess: () => {
      queryClient.setQueryData(
        ["userInfo"],
        (oldData: CompanyUserInfoResponse) => ({
          ...oldData,
          languageCode: parseLanguageCode(i18n.language),
        })
      );
    },
  });

  useEffect(() => {
    if (me && me.languageCode !== parseLanguageCode(i18n.language))
      mutate({ languageCode: parseLanguageCode(i18n.language) });
  }, [i18n.language]);

  return <></>;
};

export const I18nSettingsSyncRoot = () => {
  const { i18n } = useTranslation("common");
  const me = useMe("admin");
  const queryClient = useQueryClient();

  const { mutate } = useMutation<
    void,
    AxiosError,
    Pick<CompanyInfoRequest, "languageCode">
  >({
    mutationFn: (value) => updateLanguageRoot(value),
    onSuccess: () => {
      queryClient.setQueryData(
        ["userInfo"],
        (oldData: CompanyUserInfoResponse) => ({
          ...oldData,
          languageCode: parseLanguageCode(i18n.language),
        })
      );
    },
  });

  useEffect(() => {
    if (
      me &&
      me.languageCode !== parseLanguageCode(i18n.language) &&
      me.userRole === "Agent"
    )
      mutate({ languageCode: parseLanguageCode(i18n.language) });
  }, [i18n.language]);

  return <></>;
};

export const I18nSettingsSyncCustomer = () => {
  const { i18n } = useTranslation("common");
  const me = useMe("customer");
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation<
    void,
    AxiosError,
    Pick<CustomerInfoRequest, "languageCode">
  >({
    mutationFn: (value) => changeLoggedUserLanguage(value),
    onSuccess: () => {
      queryClient.setQueryData(
        ["userInfo"],
        (oldData: CustomerUserInfoResponse) => ({
          ...oldData,
          languageCode: parseLanguageCode(i18n.language),
        })
      );
    },
  });

  useEffect(() => {
    if (
      me.languageCode &&
      me.languageCode !== parseLanguageCode(i18n.language) &&
      !isPending
    )
      mutate({
        languageCode: parseLanguageCode(i18n.language),
      });
  }, [i18n.language, isPending]);

  return <></>;
};
