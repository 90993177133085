import { Box, Typography } from "@mui/material";
import { getTenantId } from "@repo/api-config";
import { getNumericStatistics } from "@repo/api-config/services/company";
import { useTranslation } from "@repo/i18n-config";
import { StatisticsPeriod } from "@repo/types/companyApi.types";
import { usePlural } from "@repo/utils";
import { useQueries } from "@tanstack/react-query";
import { useMemo } from "react";

export const DashboardEmailStatsBanner = () => {
  const tenantId = getTenantId();

  const { t } = useTranslation("ui");
  const pluralMessages = usePlural([
    t("DashboardEmailStatsBanner.messagesPlural.1"),
    t("DashboardEmailStatsBanner.messagesPlural.2"),
    t("DashboardEmailStatsBanner.messagesPlural.3"),
  ]);
  const pluralMessagesYear = usePlural([
    t("DashboardEmailStatsBanner.messagesYearPlural.1"),
    t("DashboardEmailStatsBanner.messagesYearPlural.2"),
    t("DashboardEmailStatsBanner.messagesYearPlural.3"),
  ]);

  const statsQueries = useQueries({
    queries: [
      {
        queryKey: ["emailStatsBanner", "week"],
        queryFn: () => getNumericStatistics(tenantId!, StatisticsPeriod.Week),
        refetchOnMount: true,
      },
      {
        queryKey: ["emailStatsBanner", "month"],
        queryFn: () => getNumericStatistics(tenantId!, StatisticsPeriod.Month),
        refetchOnMount: true,
      },
      {
        queryKey: ["emailStatsBanner", "year"],
        queryFn: () => getNumericStatistics(tenantId!, StatisticsPeriod.Year),
        refetchOnMount: true,
      },
    ],
  });

  const message = useMemo(() => {
    if (statsQueries.some((query) => query.isFetching)) {
      return null;
    }
    const stats = {
      week: statsQueries[0].data?.marketingEmailsDelivered,
      month:
        statsQueries[1].data?.marketingEmailsDelivered !==
        statsQueries[0].data?.marketingEmailsDelivered
          ? statsQueries[1].data?.marketingEmailsDelivered
          : undefined,
      year:
        statsQueries[2].data?.marketingEmailsDelivered !==
        statsQueries[1].data?.marketingEmailsDelivered
          ? statsQueries[2].data?.marketingEmailsDelivered
          : undefined,
    };

    const validStats = Object.entries(stats).filter(
      ([_, value]) => value !== undefined && value !== 0
    ) as ["week" | "month" | "year", number][];

    if (validStats.length > 0) {
      const randomIndex = Math.floor(Math.random() * validStats.length);
      const [period, value] = validStats[randomIndex]!;
      return t(`DashboardEmailStatsBanner.messages.${period}`, {
        number: `${value} ${period === "year" ? pluralMessagesYear(value) : pluralMessages(value)}`,
      });
    } else {
      const tipNumber = Math.floor(Math.random() * 3) + 1;
      return t(("DashboardEmailStatsBanner.messages.tip" + tipNumber) as any);
    }
  }, [statsQueries]);

  if (statsQueries.some((query) => query.isFetching)) return null;

  if (!message) return null;

  return (
    <Box
      sx={(theme) => ({
        width: "100%",
        backgroundColor: theme.palette.secondary.main,
        px: 1,
        py: 0.25,
        borderRadius: 2,
      })}
    >
      <Typography
        sx={(theme) => ({
          textAlign: "center",
          color: theme.palette.common.black,
        })}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: message,
          }}
        />
      </Typography>
    </Box>
  );
};
