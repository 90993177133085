"use client";

import {
  Checkbox,
  CheckboxProps as MuiCheckboxProps,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { DeepKeys, FieldApiOptions, ReactFormApi } from "@tanstack/react-form";
import { FieldInfo } from "../FieldInfo";
import { ReactNode } from "@tanstack/react-router";

type CheckboxComponentProps<T> = {
  form: ReactFormApi<T, any>;
  name: DeepKeys<T>;
  required?: boolean;
  validators?: FieldApiOptions<any, any, any, any, any>["validators"];
  label: string | ReactNode;
  variant?: Extract<Variant, "body1" | "body2">;
  checkboxAtTop?: boolean;
} & Omit<MuiCheckboxProps, "form">;

const CheckboxComponent = <T,>(props: CheckboxComponentProps<T>) => {
  const { form, label, checkboxAtTop, ...fieldProps } = props;

  return (
    <props.form.Field
      name={props.name}
      children={(field) => (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                value={field.state.value}
                checked={field.state.value}
                onChange={(e) => field.handleChange(e.target.checked)}
                sx={{
                  ...(checkboxAtTop && {
                    alignSelf: "start",
                    pt: 0,
                  }),
                }}
                {...fieldProps}
              />
            }
            label={label}
            componentsProps={{
              typography: {
                variant: props.variant ?? "body2",
                fontWeight: props.variant === "body1" ? 500 : 400,
              },
            }}
            required={props.required}
          />
          <FieldInfo field={field} />
        </FormGroup>
      )}
      validators={props.validators}
    />
  );
};

export { CheckboxComponent as Checkbox };
