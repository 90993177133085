import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { cookieName, getOptions } from "@repo/i18n-config";
import translationPL from "@repo/i18n-config/resources/pl/translation.json";
import translationEN from "@repo/i18n-config/resources/en/translation.json";
import cmsPL from "@repo/i18n-config/resources/pl/cms.json";
import commonPL from "@repo/i18n-config/resources/pl/common.json";
import uiPL from "@repo/i18n-config/resources/pl/ui.json";
import cmsEN from "@repo/i18n-config/resources/en/cms.json";
import commonEN from "@repo/i18n-config/resources/en/common.json";
import uiEN from "@repo/i18n-config/resources/en/ui.json";

import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector/cjs";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(I18nextBrowserLanguageDetector)
  .init({
    resources: {
      pl: {
        translation: translationPL,
        cms: cmsPL,
        common: commonPL,
        ui: uiPL,
      },
      en: {
        translation: translationEN,
        cms: cmsEN,
        common: commonEN,
        ui: uiEN,
      },
    },
    ...getOptions(),
    detection: {
      lookupCookie: cookieName,
      caches: ["cookie"],
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
