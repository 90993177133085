import { Error } from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "@repo/i18n-config";
import { FlagsDto, SubscriptionFrequency } from "@repo/types/rootApi.types";
import { useMemo } from "react";

interface ExpirationDateProps {
  data?: {
    date?: string;
    flags: FlagsDto;
    frequency: SubscriptionFrequency;
  };
}

export const ExpirationDate = ({ data }: ExpirationDateProps) => {
  const { t, i18n } = useTranslation("ui");
  const parsedDate = data?.date && new Date(data.date);

  const errorReasons = useMemo(() => {
    let expiringSoon = false;

    if (parsedDate && data?.frequency === SubscriptionFrequency.Annually) {
      const now = new Date();
      const diff = parsedDate.getTime() - now.getTime();
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      expiringSoon = days <= 7;
    }

    return {
      expiringSoon,
      billingProfileIncomplete: data?.flags.billingProfileIncomplete,
      lackOfPaymentMethod: data?.flags.lackOfPaymentMethod,
    };
  }, [parsedDate, data?.flags]);

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 0.75 }}>
      {Object.values(errorReasons).some((value) => value === true) && (
        <Tooltip
          title={
            <>
              {errorReasons.expiringSoon && (
                <Typography>
                  {t("DataTable.columns.expirationDate.expiringSoon")}
                </Typography>
              )}
              {errorReasons.billingProfileIncomplete && (
                <Typography>
                  {t(
                    "DataTable.columns.expirationDate.billingProfileIncomplete"
                  )}
                </Typography>
              )}
              {errorReasons.lackOfPaymentMethod && (
                <Typography>
                  {t("DataTable.columns.expirationDate.lackOfPaymentMethod")}
                </Typography>
              )}
            </>
          }
        >
          <Error color="error" />
        </Tooltip>
      )}
      <Typography variant="body2">
        {parsedDate
          ? parsedDate.toLocaleString(i18n.language, {
              dateStyle: "short",
            })
          : "-"}
      </Typography>
    </Box>
  );
};
