import {
  CompanyChartStatisticsDto,
  CompanyGeneralStatisticsDto,
  CompanyNumericStatisticsInPeriodDto,
} from "@repo/types/companyApi.types";
import { httpClient } from "../../http-client";
import { companyApiPaths } from "./paths";
import { StatisticsChartTimePeriod } from "../../types";

export const getStatistics = async (tenantId: string) => {
  const response = await httpClient.get<CompanyGeneralStatisticsDto>(
    companyApiPaths.statistics.base(tenantId)
  );

  return response.data;
};

export const getStatisticsChart = async (
  tenantId: string,
  period: StatisticsChartTimePeriod
) => {
  const response = await httpClient.get<CompanyChartStatisticsDto>(
    companyApiPaths.statistics.chart(tenantId, period)
  );

  return response.data;
};

export const getNumericStatistics = async (
  tenantId: string,
  period: StatisticsChartTimePeriod
) => {
  const response = await httpClient.get<CompanyNumericStatisticsInPeriodDto>(
    companyApiPaths.statistics.period(tenantId, period)
  );

  return response.data;
};
