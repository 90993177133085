import { getCookie, setCookie, eraseCookie } from "@repo/utils";
import { AuthResponse } from "../types";
import { COOKIE_CONFIG } from "../cookieConfig";

export const handleAuthResponse = (
  response: any,
  additionalCookies = {},
  saveLogin = true
): Promise<AuthResponse> => {
  return new Promise<AuthResponse>((resolve, reject) => {
    if (!response) {
      clearUserAuth();
      reject();
    }

    const expiration =
      response.accessTokenResponse?.expiresIn || response.expiresIn;

    const accessToken =
      response.accessTokenResponse?.accessToken || response.accessToken;

    const refreshToken =
      response.accessTokenResponse?.refreshToken || response.refreshToken;

    if (accessToken) {
      setCookie(
        COOKIE_CONFIG.LOYMEE_ACCESS_TOKEN.name,
        accessToken,
        COOKIE_CONFIG.LOYMEE_ACCESS_TOKEN.duration(expiration)
      );
    }

    if (response.identifier) {
      setCookie(
        COOKIE_CONFIG.LOYMEE_TENANT_ID.name,
        response.identifier,
        COOKIE_CONFIG.LOYMEE_TENANT_ID.duration // 14 days
      );
    }

    if (refreshToken && saveLogin) {
      setCookie(
        COOKIE_CONFIG.LOYMEE_REFRESH_TOKEN.name,
        refreshToken,
        COOKIE_CONFIG.LOYMEE_REFRESH_TOKEN.duration // 14 days
      );
    }

    for (const [key, value] of Object.entries(additionalCookies)) {
      setCookie(key, value as string, expiration * 1000);
    }

    resolve({
      tenantId: response.identifier,
    });
  });
};

export const updateCompanyAuthAfterConfirmation = (response: any) => {
  return new Promise<void>((resolve, reject) => {
    if (!response) {
      clearUserAuth();
      reject();
    }

    if (response.data.accessToken) {
      setCookie(
        COOKIE_CONFIG.LOYMEE_ACCESS_TOKEN.name,
        response.data.accessToken,
        COOKIE_CONFIG.LOYMEE_ACCESS_TOKEN.duration(response.expiresIn) // as in API in seconds
      );
    }

    if (response.data.refreshToken) {
      setCookie(
        COOKIE_CONFIG.LOYMEE_REFRESH_TOKEN.name,
        response.data.refreshToken,
        COOKIE_CONFIG.LOYMEE_REFRESH_TOKEN.duration // 14 days
      );
    }
    setCookie(
      COOKIE_CONFIG.LOYMEE_USER_ROLE.name,
      "Owner",
      COOKIE_CONFIG.LOYMEE_USER_ROLE.duration
    );
    resolve();
  });
};

export const clearUserAuth = (): Promise<void> => {
  return new Promise((resolve) => {
    eraseCookie(COOKIE_CONFIG.LOYMEE_ACCESS_TOKEN.name);
    eraseCookie(COOKIE_CONFIG.LOYMEE_REFRESH_TOKEN.name);
    eraseCookie(COOKIE_CONFIG.LOYMEE_TENANT_ID.name);
    eraseCookie(COOKIE_CONFIG.LOYMEE_USER_ROLE.name);
    resolve();
  });
};

export const getTenantId = (): string | null => {
  return getCookie(COOKIE_CONFIG.LOYMEE_TENANT_ID.name);
};

export const getStoredToken = (): string | null => {
  return getCookie(COOKIE_CONFIG.LOYMEE_ACCESS_TOKEN.name);
};

export const getStoredRefreshToken = (): string | null => {
  return getCookie(COOKIE_CONFIG.LOYMEE_REFRESH_TOKEN.name);
};

export const isAuthenticated = (): boolean => {
  return (
    getCookie(COOKIE_CONFIG.LOYMEE_ACCESS_TOKEN.name) !== null ||
    getCookie(COOKIE_CONFIG.LOYMEE_REFRESH_TOKEN.name) !== null
  );
};
