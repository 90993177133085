import { useTranslation } from "@repo/i18n-config";
import { CompanyCategory } from "@repo/types/companyApi.types";

export const CompanyCategoryExtended = {
  None: "None" as CompanyCategory,
  ...CompanyCategory,
};

export const useSortedCategories = () => {
  const { t, i18n } = useTranslation("common");

  return Object.values(CompanyCategoryExtended)
    .filter((category) => category !== ("None" as CompanyCategory))
    .map((companyCategory) => ({
      value: companyCategory,
      label: t(`companyCategories.${companyCategory}`),
    }))
    .sort((a, b) =>
      a.value === "Other"
        ? 1
        : b.value === "Other"
          ? -1
          : a.label.localeCompare(b.label, i18n.language)
    );
};
