import React from "react";
import ButtonGroup, { ButtonGroupProps } from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import { useTranslation } from "@repo/i18n-config";
import {
  StatisticsChartTimePeriod,
  statisticsChartTimePeriods,
} from "@repo/api-config/types";
import { useMediaQuery, useTheme } from "@mui/material";

interface StatisticsPeriodSelectorProps {
  periodType: StatisticsChartTimePeriod;
  setPeriodType: (type: StatisticsChartTimePeriod) => void;
  size?: ButtonGroupProps["size"];
}

const StatisticsPeriodSelector: React.FC<StatisticsPeriodSelectorProps> = ({
  periodType,
  setPeriodType,
  size,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation("ui");
  return (
    <ButtonGroup
      variant="contained"
      size={size || (isMobile ? "medium" : "large")}
      sx={{
        "& .MuiButton-root": {
          fontWeight: 700,
          letterSpacing: "0.46px",
        },
      }}
    >
      {statisticsChartTimePeriods.map((type) => (
        <Button
          key={type}
          sx={[periodType !== type ? { opacity: 0.7 } : { opacity: 1 }]}
          onClick={() => setPeriodType(type)}
        >
          {t(`StatisticsPeriodSelector.${type}`)}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default StatisticsPeriodSelector;
