import { CloseOutlined } from "@mui/icons-material";
import { Box, IconButton, Modal, Paper, Typography } from "@mui/material";
import { Button, TransparentButton } from "@repo/ui";
import { CodeType } from "./scanner";
import { useState } from "react";
import { useTranslation } from "@repo/i18n-config";
import { MultiplierSelector } from "./multiplierSelector";
import { useQuery } from "@tanstack/react-query";
import { getCompanyDetails } from "@repo/api-config/services/company";
import { getTenantId } from "@repo/api-config";
import { usePlural } from "@repo/utils";

export interface ConfirmModalProps {
  type?: CodeType;
  open: boolean;
  onCancel: () => void;
  onConfirm: (multiplier?: number) => void;
}

export const ConfirmModal = ({
  open,
  type,
  onCancel,
  onConfirm,
}: ConfirmModalProps) => {
  
  const { t } = useTranslation("cms");
  const tenantId = getTenantId();
  const [multiplier, setMultiplier] = useState(1);

  const resetMultiplier = () => {
    setTimeout(() => {
      setMultiplier(1);
    }, 300);
  };

  const { data } = useQuery({
    queryKey: ["CompanyInfo"],
    queryFn: () => getCompanyDetails(tenantId!),
  });

  const pluralPoints = usePlural([
    t("employeeScan.pluralPoints.1"),
    t("employeeScan.pluralPoints.2"),
    t("employeeScan.pluralPoints.3"),
  ]);

  const texts = {
    reward: t("employeeScan.confirmModal.texts.reward"),
    customer: t("employeeScan.confirmModal.texts.customer", {
      amount: data?.multiplier,
      pluralPoints: pluralPoints(data?.multiplier || 0),
    }),
  };
  return (
    <Modal open={open}>
      <Paper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 342,
          outline: "none",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            pt: 1.5,
            pl: 3,
            pr: 2,
            pb: 2,
          }}
        >
          <Typography variant="h6">
            {t("employeeScan.confirmModal.header")}
          </Typography>
          <IconButton
            onClick={() => {
              onCancel();
              resetMultiplier();
            }}
          >
            <CloseOutlined />
          </IconButton>
        </Box>
        <Box
          sx={{
            px: 3,
            pb: 2.5,
          }}
        >
          {type && <Typography>{texts[type]}</Typography>}
        </Box>
        {type === "customer" && data?.multiplier && (
          <Box
            sx={{
              px: 3,
              mb: 2,
            }}
          >
            <MultiplierSelector
              multiplier={data.multiplier}
              value={multiplier}
              setValue={setMultiplier}
            />
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            p: 1,
            gap: 1,
          }}
        >
          <TransparentButton
            onClick={() => {
              onCancel();
              resetMultiplier();
            }}
          >
            {t("employeeScan.confirmModal.cancel")}
          </TransparentButton>
          <Button
            onClick={() => {
              onConfirm(type === "customer" ? multiplier : undefined);
              resetMultiplier();
            }}
          >
            {t("employeeScan.confirmModal.confirm")}
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
};
