"use client";

import {
  Box,
  Card,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Area,
  ResponsiveContainer,
  Tooltip,
  Label,
  ComposedChart,
  Bar,
  Text,
} from "recharts";
import { curveBumpX } from "d3-shape";
import { useMemo, useState } from "react";
import { useTranslation } from "@repo/i18n-config";
import { useQuery } from "@tanstack/react-query";
import { getTenantId } from "@repo/api-config";
import { getStatisticsChart as getRootStatisticsChart } from "@repo/api-config/services/root";
import { getStatisticsChart as getCompanyStatisticsChart } from "@repo/api-config/services/company";
import { useServerErrorFormatter } from "@repo/utils";
import { AxiosError } from "axios";
import { ErrorMessage } from "./ErrorMessage";
import {
  StatisticsChartTimePeriod,
  statisticsChartTimePeriods,
} from "@repo/api-config/types";
import { RootChartStatisticsDto } from "@repo/types/rootApi.types";
import {
  CompanyChartStatisticsDto,
  HttpValidationProblemDetails,
} from "@repo/types/companyApi.types";
import StatisticsPeriodSelector from "./StatisticsPeriodSelector";

interface DateDataType {
  date: string;
  value: number;
}

interface EmployeeDataType {
  email: string;
  value: number;
}

const availableDataTypes = {
  company: [
    "purchasePointSumOverTime",
    "claimedRewardsCountOverTime",
    "customersOverTime",
    "employeeScanCountOverTime",
  ] as const,
  root: [
    "purchaseScanCountByDay",
    "claimedRewardsCountByDay",
    "newCustomersCountByDay",
    "newCompaniesCountByDay",
  ] as const,
};

type AvailableDataType =
  (typeof availableDataTypes)[keyof typeof availableDataTypes][number];

interface DashboardChartUiProps {
  dataType: AvailableDataType;
  periodType: StatisticsChartTimePeriod;
  data: DateDataType[] | EmployeeDataType[];
}

interface DashboardChartsProps {
  root?: boolean;
}

const DashboardChartRender = ({
  dataType,
  periodType,
  data,
}: DashboardChartUiProps) => {
  const { t, i18n } = useTranslation("ui");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const tooltipLabelFormatter = (value: string) => {
    if (value) {
      if (dataType === "employeeScanCountOverTime") return value;
      const dateSplit = value.slice(0, 10).split("-");
      return new Date(
        Number(dateSplit[0]),
        Number(dateSplit[1]) - 1,
        Number(dateSplit[2])
      ).toLocaleString(i18n.language, {
        dateStyle: "short",
      });
    }
    return t("DashboardCharts.deleted");
  };

  const tickFormatter = (value: string) => {
    if (dataType === "employeeScanCountOverTime")
      return value.length > 0
        ? value
            .split(/(@|\.|\+)/)
            .map((v) => " " + v)
            .join("")
        : t("DashboardCharts.deleted");
    if (value) {
      const date = value.slice(0, 10).split("-");
      if (periodType !== "Year")
        return new Date(
          Number(date[0]),
          Number(date[1]) - 1,
          Number(date[2])
        ).toLocaleString(i18n.language, {
          day: "2-digit",
          month: "2-digit",
        });
      const shortName = new Date(
        Number(date[0]),
        Number(date[1]) - 1,
        Number(date[2])
      ).toLocaleDateString(i18n.language, {
        month: "short",
      });
      return shortName[0]?.toUpperCase() + shortName.slice(1, shortName.length);
    }
    return t("DashboardCharts.deleted");
  };

  function CustomizedTick(props: any) {
    const { x, y, payload } = props;
    return (
      <svg height={455}>
        <Text
          x={x}
          y={y}
          fill={theme.palette.text.secondary}
          width={100}
          fontSize={14}
          verticalAnchor="start"
          textAnchor="middle"
        >
          {tickFormatter(payload.value)}
        </Text>
      </svg>
    );
  }

  const displayedTicks = useMemo(() => {
    if (dataType === "employeeScanCountOverTime")
      return data.map(
        (item) =>
          (item as EmployeeDataType).email ?? t("DashboardCharts.deleted")
      );
    if (periodType !== "Year") return undefined;
    let ticks: string[] = [];
    data?.forEach((dataItem) => {
      if (
        !ticks.find(
          (tick) =>
            tick.slice(0, 7) === (dataItem as DateDataType).date.slice(0, 7)
        )
      ) {
        ticks.push((dataItem as DateDataType).date);
      }
    });
    return ticks;
  }, [data, dataType, periodType]);

  const yAxisDomain = useMemo(() => {
    if (data.length > 0) {
      const maxY = Math.max(...data.map((d) => d.value!));
      if (maxY > 5) return undefined;
      return [0, 5];
    }
    return undefined;
  }, [data]);

  const xAxisLabel = useMemo(() => {
    if (dataType === "employeeScanCountOverTime")
      return t("DashboardCharts.employee");
    if (periodType === "Year") return t("DashboardCharts.month");
    return t("DashboardCharts.day");
  }, [periodType, t, dataType]);

  const xAxisInterval = useMemo(() => {
    if (dataType === "employeeScanCountOverTime") return 0;
    if (periodType === "Week") return 0;
    return undefined;
  }, [periodType, t]);

  return (
    <ResponsiveContainer height={475} width="100%">
      <ComposedChart
        data={data}
        margin={
          isMobile
            ? { left: -16, right: 20, bottom: 45 }
            : { bottom: 45, right: 20 }
        }
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="0%"
              stopColor={theme.palette.primary.main}
              stopOpacity={0.2}
            />
            <stop
              offset="100%"
              stopColor={theme.palette.primary.main}
              stopOpacity={0}
            />
          </linearGradient>
        </defs>
        <XAxis
          dataKey={dataType === "employeeScanCountOverTime" ? "email" : "date"}
          tickLine={false}
          axisLine={false}
          fontSize={14}
          stroke={theme.palette.grey[500]}
          tickMargin={8}
          ticks={displayedTicks}
          tickFormatter={tickFormatter}
          interval={xAxisInterval}
          tick={<CustomizedTick />}
        >
          <Label
            value={xAxisLabel}
            position="bottom"
            fontSize={14}
            color={theme.palette.text.secondary}
            offset={30}
            fontWeight={500}
          />
        </XAxis>
        <YAxis
          tickLine={false}
          axisLine={false}
          fontSize={14}
          stroke={theme.palette.grey[500]}
          tickMargin={8}
          domain={yAxisDomain}
          tickCount={6}
        />
        <CartesianGrid vertical={false} stroke={theme.palette.grey[500]} />
        {dataType === "employeeScanCountOverTime" ? (
          <Bar
            dataKey="value"
            stroke={theme.palette.primary.main}
            fillOpacity={1}
            strokeWidth={2}
            fill="url(#colorUv)"
          />
        ) : (
          <Area
            type={curveBumpX}
            dataKey="value"
            stroke={theme.palette.primary.main}
            fillOpacity={1}
            strokeWidth={2}
            fill="url(#colorUv)"
          />
        )}
        <Tooltip
          separator=": "
          contentStyle={{
            borderRadius: "8px",
            color: theme.palette.primary.contrast,
          }}
          itemStyle={{ color: theme.palette.primary.contrast }}
          labelFormatter={tooltipLabelFormatter}
          formatter={(value) => [
            value,
            dataType === "employeeScanCountOverTime"
              ? t("DashboardCharts.scanCount")
              : t(`DashboardCharts.dataTypes.${dataType}`),
          ]}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export const DashboardCharts = ({ root }: DashboardChartsProps) => {
  const { t } = useTranslation("ui");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [dataType, setDataType] = useState<AvailableDataType>(
    availableDataTypes[root ? "root" : "company"][0]!
  );

  const [periodType, setPeriodType] = useState<StatisticsChartTimePeriod>(
    statisticsChartTimePeriods[0]
  );

  const tenantId = getTenantId();
  const { formatErrorMessage } = useServerErrorFormatter();
  const { data, error, isLoading } = useQuery<
    RootChartStatisticsDto | CompanyChartStatisticsDto
  >({
    queryFn: () => {
      if (root) return getRootStatisticsChart(periodType);
      return getCompanyStatisticsChart(tenantId!, periodType);
    },
    queryKey: ["DashboardStatisticsChart", periodType],
    enabled: root ? true : !!tenantId,
  });

  const formattedData = useMemo(() => {
    if (!data || !dataType) {
      return [];
    }

    if (dataType in data) {
      if (dataType === "employeeScanCountOverTime")
        return (
          data as CompanyChartStatisticsDto
        ).employeeScanCountOverTime.map((item) => ({
          email: item.email,
          value: item.scanCount,
        }));
      const keys = Object.keys(data[dataType as keyof typeof data] || {});
      const values = Object.values(data[dataType as keyof typeof data] || {});

      return keys.map((key, index) => ({
        date: key,
        value: values[index] as number,
      }));
    }

    return [];
  }, [data, dataType]);

  return (
    <Card
      sx={{
        py: 2.5,
        borderRadius: 4,
      }}
      elevation={4}
    >
      <Box
        sx={{
          mb: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
          flexWrap: "wrap",
          px: { xs: 2, md: 3 },
        }}
      >
        <FormControl fullWidth={isMobile}>
          <InputLabel id="chart-type">{t("DashboardCharts.type")}</InputLabel>
          <Select
            value={dataType}
            label={t("DashboardCharts.type")}
            labelId="chart-type"
          >
            {availableDataTypes[root ? "root" : "company"].map((type) => (
              <MenuItem
                key={type}
                value={type}
                onClick={() => setDataType(type)}
              >
                {t(`DashboardCharts.dataTypes.${type}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <StatisticsPeriodSelector
          periodType={periodType}
          setPeriodType={setPeriodType}
        />
      </Box>
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {error && (
        <ErrorMessage
          errorMessage={formatErrorMessage(
            error as AxiosError<HttpValidationProblemDetails>
          )}
        />
      )}
      {formattedData && formattedData?.length > 0 ? (
        <DashboardChartRender
          dataType={dataType}
          periodType={periodType}
          data={formattedData}
        />
      ) : (
        <>
          {!isLoading && (
            <Typography textAlign="center">
              {t("DashboardCharts.noData")}
            </Typography>
          )}
        </>
      )}
    </Card>
  );
};
