"use client";

import {
  Box,
  TextField,
  Typography,
  ListItem,
  ListItemButton,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
  Map as GoogleMapComponent,
  MapMouseEvent,
  useMap,
} from "@vis.gl/react-google-maps";
import { Button } from "../Button";
import { MapPickerMarker } from "./MapPickerMarker";
import { useMapPlacesContext } from "./MapPlacesContext";
import { useGeolocation } from "@repo/utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "@repo/i18n-config";
import { Close } from "@mui/icons-material";
import { Marker, MarkerClusterer } from "@googlemaps/markerclusterer";
import { ClustererRenderer } from "./clustererRenderer";

interface MapPickerComponentProps {
  showSearchBox?: boolean;
  onChangePlace: (place: google.maps.places.PlaceResult) => void;
}

interface MapPickerMarkerClustererProps {
  places: google.maps.places.PlaceResult[];
  onSelect: (place: google.maps.places.PlaceResult) => void;
  selectedPlace: google.maps.places.PlaceResult | null;
}

const MapPickerMarkerClusterer = ({
  places,
  onSelect,
  selectedPlace,
}: MapPickerMarkerClustererProps) => {
  const [markers, setMarkers] = useState<{ [key: number]: Marker }>({});
  const map = useMap();

  const clusterer = useMemo(() => {
    if (!map) return null;

    return new MarkerClusterer({
      map,
      algorithmOptions: {
        maxZoom: 18,
      },
      renderer: new ClustererRenderer(),
    });
  }, [map]);

  useEffect(() => {
    if (!clusterer) return;

    clusterer.clearMarkers();
    clusterer.addMarkers(Object.values(markers));
  }, [clusterer, markers]);

  // this callback will effectively get passsed as ref to the markers to keep
  // tracks of markers currently on the map
  const setMarkerRef = useCallback((marker: Marker | null, key: number) => {
    setMarkers((markers) => {
      if ((marker && markers[key]) || (!marker && !markers[key]))
        return markers;

      if (marker) {
        return { ...markers, [key]: marker };
      } else {
        const { [key]: _, ...newMarkers } = markers;

        return newMarkers;
      }
    });
  }, []);

  return (
    <>
      {places.map((place, index) => (
        <MapPickerMarker
          key={place.place_id}
          clustererKey={index}
          place={place}
          setMarkerRef={setMarkerRef}
          markerProps={{
            position: {
              lat: place.geometry?.location?.lat() || 0,
              lng: place.geometry?.location?.lng() || 0,
            },
            title: place.name,
            clickable: true,
          }}
          onSelect={onSelect}
          selectedPlace={selectedPlace}
        />
      ))}
    </>
  );
};

export const MapPickerComponent = ({
  showSearchBox = false,
  onChangePlace,
}: MapPickerComponentProps) => {
  const { t } = useTranslation("ui");
  const [query, setQuery] = useState<string>("");

  const { location, error } = useGeolocation();
  const { setSearchQuery, queryPlaces, selectedPlace, selectPlace } =
    useMapPlacesContext();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleMapClick = (e: MapMouseEvent) => {
    const coords = `${e.detail.latLng?.lat},${e.detail.latLng?.lng}`;
    setSearchQuery(coords);
    setQuery(coords);
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: { md: "400px" },
        flexDirection: {
          xs: "column",
          md: "row",
        },
      }}
    >
      <Box
        sx={{
          flex: 1,
          position: "relative",
        }}
      >
        <GoogleMapComponent
          key={location.latitude || error || "map"}
          style={{ width: "100%", height: isMobile ? "350px" : "100%" }}
          defaultCenter={{
            lat: location.latitude || 0,
            lng: location.longitude || 0,
          }}
          defaultZoom={error ? 3 : 12}
          gestureHandling="greedy"
          mapId="c4247d4e7ad57509"
          controlSize={25}
          onClick={handleMapClick}
        />
      </Box>
      <Box
        sx={{
          width: { md: "350px" },
          display: "flex",
          flexDirection: "column",
          gap: 1,
          pl: { xs: 0, md: 2 },
          pt: { xs: 2, md: 0 },
          overflowY: "auto",
          borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <Typography variant="h6">{t("MapComponent.search")}</Typography>
        {showSearchBox && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              flexDirection: "column",
              mb: 2,
            }}
          >
            <TextField
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              size="small"
              placeholder={t("MapComponent.nameOrAddress")}
              fullWidth
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => setSearchQuery(query)}>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
              onKeyDown={(event: React.KeyboardEvent) => {
                if (event.key === "Enter") {
                  setSearchQuery(query);
                }
              }}
            />
          </Box>
        )}
        {queryPlaces && (
          <MapPickerMarkerClusterer
            places={queryPlaces}
            onSelect={selectPlace}
            selectedPlace={selectedPlace}
          />
        )}
        {selectedPlace && (
          <>
            {onChangePlace(selectedPlace)}
            <ListItemButton
              selected
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                flexGrow: "unset",
                mb: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography variant="body1">{selectedPlace.name}</Typography>
                <IconButton size="small" onClick={() => selectPlace(null)}>
                  <Close sx={{ mr: "0 !important" }} />
                </IconButton>
              </Box>
              <Typography variant="body2">
                {selectedPlace.formatted_address}
              </Typography>
            </ListItemButton>
          </>
        )}
        <Box sx={{ flex: 1, overflowY: "auto" }}>
          {queryPlaces
            ?.filter((place) => place.place_id !== selectedPlace?.place_id)
            ?.map((place) => (
              <ListItem
                key={place.place_id}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  mb: 2,
                }}
              >
                <Box sx={{ mb: 1, flex: 1 }}>
                  <Typography variant="body1">{place.name}</Typography>
                  <Typography variant="body2">
                    {place.formatted_address}
                  </Typography>
                </Box>
                <Button
                  onClick={() => selectPlace(place)}
                  variant="contained"
                  color="primary"
                  size="small"
                  sx={{ alignSelf: "flex-end" }}
                >
                  {t("MapComponent.select")}
                </Button>
              </ListItem>
            ))}
        </Box>
      </Box>
    </Box>
  );
};
