import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import { useTranslation } from "@repo/i18n-config";
import { TransparentButton } from "@repo/ui";
import { useRegisterSW } from "virtual:pwa-register/react";

function ReloadPrompt() {
  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW();

  const close = () => {
    setNeedRefresh(false);
  };

  const { t } = useTranslation("cms");

  return (
    <Dialog open={needRefresh}>
      {needRefresh && (
        <>
          <DialogContent>
            <Typography>{t("ReloadPrompt.description")}</Typography>
          </DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              p: 1,
              pb: 2.5,
              gap: 1,
            }}
          >
            <Button
              onClick={() => {
                updateServiceWorker(true);
              }}
            >
              {t("ReloadPrompt.yes")}
            </Button>
            <TransparentButton
              onClick={() => {
                close();
              }}
            >
              {t("ReloadPrompt.no")}
            </TransparentButton>
          </Box>
        </>
      )}
    </Dialog>
  );
}

export default ReloadPrompt;
