import { Box, Typography } from "@mui/material";
import { useTranslation } from "@repo/i18n-config";
import { Card, BillingForm, StripeCardForm } from "@repo/ui";

const PaymentsInfo = () => {
  
  const { t } = useTranslation("cms");

  return (
    <Card
      title={t("Rewards.paymentsInfo.cardTitle")}
      sx={{
        flexDirection: {
          xs: "column",
          lg: "row",
        },
        "& > h6": {
          width: { xs: "100%", lg: "40%" },
        },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", flex: 1, gap: 2 }}>
        <Typography variant="h6">
          {t("Rewards.paymentsInfo.billingData")}
        </Typography>
        <BillingForm />
        <div id="card">
          <Typography variant="h6">
            {t("Rewards.paymentsInfo.yourCard")}
          </Typography>
        </div>
        <StripeCardForm />
      </Box>
    </Card>
  );
};

export default PaymentsInfo;
