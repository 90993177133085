import { Box, CircularProgress } from "@mui/material";
import { getTenantId } from "@repo/api-config";
import {
  getCompanyDetails,
  updateCompany,
} from "@repo/api-config/services/company";
import { useTranslation } from "@repo/i18n-config";
import {
  CompanyDto,
  HttpValidationProblemDetails,
  UpdateCompanyCommand,
} from "@repo/types/companyApi.types";
import { Card, ErrorMessage, LoadingButton, TextInput } from "@repo/ui";
import {
  companyNameValidator,
  phoneValidator,
  useNotificationsContext,
  useServerErrorFormatter,
} from "@repo/utils";
import { useForm } from "@tanstack/react-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { valibotValidator } from "@tanstack/valibot-form-adapter";
import { AxiosError } from "axios";
import { useEffect } from "react";

const CompanyInfoForm = ({ data }: { data: CompanyDto }) => {
  const form = useForm({
    defaultValues: {
      companyName: data.name,
      phoneNumber: data.phoneNumber,
    },
    onSubmit: async ({ value }) => {
      mutate(value);
    },
    validatorAdapter: valibotValidator(),
  });

  useEffect(() => {
    if (data) form.setFieldValue("companyName", data.name);
    if (data) form.setFieldValue("phoneNumber", data.phoneNumber);
  }, [data]);

  const { t } = useTranslation("cms");
  const { showNotification } = useNotificationsContext();
  const tenantId = getTenantId();

  const { mutate, isPending, error } = useMutation<
    void,
    AxiosError,
    Omit<UpdateCompanyCommand, "multiplier">
  >({
    mutationFn: (value) => updateCompany(tenantId!, value),
    onSuccess: () => {
      showNotification(t("Settings.companyInfo.form.saved"));
    },
  });

  return (
    <Box
      component="form"
      sx={{ display: "flex", flex: 1 }}
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          gap: 2,
        }}
      >
        <TextInput
          form={form}
          name="companyName"
          label={t("Settings.companyInfo.form.companyName")}
          required
          validators={{
            onChange: companyNameValidator,
          }}
        />
        <TextInput
          form={form}
          name="phoneNumber"
          type="tel"
          label={t("Settings.companyInfo.form.phoneNumber")}
          validators={{
            onChange: phoneValidator,
          }}
        />
        {error && (
          <ErrorMessage errorMessage={t("Settings.companyInfo.form.error")} />
        )}
        <LoadingButton
          sx={{
            mt: 4,
            alignSelf: "flex-end",
          }}
          fullWidth={false}
          text={t("Settings.companyInfo.form.save")}
          isLoading={isPending}
        />
      </Box>
    </Box>
  );
};

const CompanyInfo = () => {
  const { t } = useTranslation("cms");

  const tenantId = getTenantId();

  const { formatErrorMessage } = useServerErrorFormatter();

  const { data, isLoading, error } = useQuery({
    queryKey: ["CompanyInfo"],
    queryFn: () => getCompanyDetails(tenantId!),
  });

  return (
    <Card
      title={t("Settings.companyInfo.title")}
      description={t("Settings.companyInfo.description")}
      sx={{
        flexDirection: {
          xs: "column",
          md: "row",
        },
        "& h6": {
          width: { xs: "100%", md: "40%" },
        },
      }}
    >
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {error && !data && (
        <ErrorMessage
          errorMessage={formatErrorMessage(
            error as AxiosError<HttpValidationProblemDetails>
          )}
        />
      )}
      {data && <CompanyInfoForm data={data} />}
    </Card>
  );
};

export default CompanyInfo;
