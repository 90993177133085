import {
  CheckCircleOutline,
  Close,
  ErrorOutline,
  WarningAmberOutlined,
} from "@mui/icons-material";
import { Alert, AlertTitle, IconButton } from "@mui/material";
import { useTranslation } from "@repo/i18n-config";
import { useMemo } from "react";

export interface UpperAlertProps {
  variant: "rewardScan" | "pointScan" | "error" | "warning";
  onClose: () => void;
  description?: string;
  points?: number;
  smallScreen?: boolean;
  textPage?: boolean;
  scanStreak?: number;
}

interface BottomAlertProps {
  onClose: () => void;
  points: number;
  smallScreen?: boolean;
  textPage?: boolean;
}

export const UpperAlert = ({
  variant,
  description,
  points,
  onClose,
  smallScreen,
  textPage,
  scanStreak,
}: UpperAlertProps) => {
  
  const { t } = useTranslation("cms");

  const alertSeverity = useMemo(() => {
    if (variant === "rewardScan") return "successDark";
    if (variant === "pointScan") return "success";
    if (variant === "warning") return "warning";
    return "error";
  }, [variant]);

  const alertTitle = useMemo(() => {
    if (variant === "rewardScan" || variant === "pointScan")
      return t("employeeScan.alert.success");
    if (variant === "warning") return t("employeeScan.alert.warning");
    return t("employeeScan.alert.error");
  }, [variant, description, points]);

  const alertDescription = useMemo(() => {
    if (variant === "rewardScan")
      return t("employeeScan.alertDescription.reward", { points });
    if (variant === "pointScan")
      return t("employeeScan.alertDescription.points");
    if (variant === "warning")
      return t("employeeScan.alertDescription.warning", { streak: scanStreak });
    return description;
  }, [variant, description, points]);

  const alertIcon = useMemo(() => {
    if (variant === "rewardScan" || variant === "pointScan")
      return <CheckCircleOutline />;
    if (variant === "warning") return <WarningAmberOutlined />;
    return <ErrorOutline />;
  }, [variant]);

  return (
    <Alert
      severity={alertSeverity}
      sx={{
        position: "absolute",
        top: smallScreen ? 16 : textPage ? 0 : -120,
        width: (theme) => `calc(100% - ${theme.spacing(6)})`,
      }}
      action={
        <IconButton color="inherit" size="small" onClick={onClose}>
          <Close fontSize="inherit" />
        </IconButton>
      }
      icon={alertIcon}
    >
      <AlertTitle>{alertTitle}</AlertTitle>
      {alertDescription}
    </Alert>
  );
};

export const BottomAlert = ({
  onClose,
  points,
  smallScreen,
  textPage,
}: BottomAlertProps) => {
  
  const { t } = useTranslation("cms");

  return (
    <Alert
      severity="info"
      variant="filled"
      sx={{
        position: "absolute",
        bottom: smallScreen ? 16 : textPage ? -64 : -76,
        width: (theme) => `calc(100% - ${theme.spacing(6)})`,
      }}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={onClose}
        >
          <Close fontSize="inherit" />
        </IconButton>
      }
    >
      {t("employeeScan.alertDescription.totalAmount", { points })}
    </Alert>
  );
};
