import { createFileRoute } from "@tanstack/react-router";
import { RegisterCompany } from "../../../pages/RegisterCompany";

export interface RegisterCompanyRouteParams {
  ref?: string;
}

export const Route = createFileRoute("/_public/register/company")({
  component: () => <RegisterCompany />,
  validateSearch: (
    search: Record<string, string>
  ): RegisterCompanyRouteParams => {
    return {
      ref: search.ref,
    };
  },
});
