/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AccessTokenResponse {
  tokenType: string;
  accessToken: string;
  /** @format int64 */
  expiresIn: number;
  refreshToken: string;
}

export interface AllowNotificationsCommand {
  allowNotifications: boolean;
}

export interface CompanyRewardsResult {
  identifier: string;
  name: string;
  /** @format int32 */
  pointsOwned: number;
  rewards: RewardDto[];
  pointsGainDescription?: string | null;
  pointsGainDescriptionPl?: string | null;
}

export interface CreateCustomerCommand {
  /**
   * @minLength 1
   * @maxLength 100
   */
  email: string;
  /**
   * @minLength 8
   * @maxLength 200
   * @pattern ^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()._+=-])[A-Za-z\d!@#$%^&*()._+=-]{8,200}$
   */
  password: string;
  languageCode: LanguageCode;
  allowNotifications?: boolean | null;
}

export interface CustomerSubscriptionsDto {
  companyName: string;
  companyIdentifier: string;
  isCompanySubscribed: boolean;
}

export interface ExtendedLocationDto {
  externalId: string;
  tenantIdentifier: string;
  companyName: string;
  companyCategory: string;
}

export interface ExtendedScanDto {
  /** @format int32 */
  points: number;
  /** @format date-time */
  date: string;
  type: ScanType;
  /** @format int32 */
  rewardId?: number | null;
  rewardName?: string | null;
  companyIdentifier?: string | null;
  companyName?: string | null;
}

export interface ExtendedScanDtoPaginatedList {
  items: ExtendedScanDto[];
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  totalCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface ForgotPasswordRequest {
  email: string;
}

export interface GooglePassResult {
  saveLoyaltyPassUri: string;
}

export type HttpValidationProblemDetails = ProblemDetails & {
  errors?: Record<string, string[]>;
  [key: string]: any;
};

export type IResult = object;

export interface InfoRequest {
  newEmail?: string | null;
  newPassword?: string | null;
  oldPassword?: string | null;
  languageCode?: LanguageCode | null;
}

export enum LanguageCode {
  En = "en",
  Pl = "pl",
}

export interface LatestPurchase {
  scan: ScanDto;
}

export interface LatestReward {
  scan: ScanDto;
  rewardName?: string | null;
  rewardNamePl?: string | null;
}

export interface LoginRequest {
  email: string;
  password: string;
}

export interface NoContent {
  /** @format int32 */
  statusCode: number;
}

export interface OAuth2AuthorizationResult {
  authorizationUrl: string;
}

export enum OAuth2Provider {
  Google = "Google",
  Apple = "Apple",
}

export interface PointsInCompanyDto {
  companyIdentifier: string;
  companyName: string;
  /** @format int32 */
  points: number;
  /** @format int32 */
  purchaseScanCount: number;
  /** @format int32 */
  claimedRewards: number;
  canAffordSomeReward: boolean;
}

export interface PointsInCompanyDtoPaginatedList {
  items: PointsInCompanyDto[];
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  totalCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export interface RefreshRequest {
  refreshToken: string;
}

export interface ResendConfirmationEmailRequest {
  email: string;
}

export interface ResetPasswordRequest {
  email: string;
  resetCode: string;
  newPassword: string;
}

export interface RewardDto {
  /** @format int32 */
  id: number;
  name?: string | null;
  namePl?: string | null;
  description?: string | null;
  descriptionPl?: string | null;
  /** @format int32 */
  pointCost: number;
}

export interface ScanDto {
  /** @format int32 */
  points: number;
  /** @format date-time */
  date: string;
  type: ScanType;
  companyIdentifier: string;
  companyName: string;
}

export interface ScanSummary {
  /** @format int32 */
  purchaseScanCount: number;
  latestPurchase?: LatestPurchase | null;
  latestReward?: LatestReward | null;
}

export enum ScanType {
  Purchase = "Purchase",
  ClaimReward = "ClaimReward",
}

export interface SetNotificationsTokenCommand {
  token: string;
}

export interface SubscribeTopicCommand {
  companyIdentifier: string;
}

export interface UnsubscribeTopicCommand {
  companyIdentifier: string;
}

export interface UserInfoResponse {
  email: string;
  isEmailConfirmed: boolean;
  userType: string;
  userRole?: string | null;
  tenantIdentifier?: string | null;
  languageCode: LanguageCode;
}
