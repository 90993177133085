import { createFileRoute, redirect } from "@tanstack/react-router";
import { cmsRoutes, getCookie } from "@repo/utils";
import { clearUserAuth, COOKIE_CONFIG } from "@repo/api-config";
import { DashboardTemplate } from "@repo/ui";
import { getUserInfo } from "@repo/api-config/services/company";

export const Route = createFileRoute("/_private/_withDashboard")({
  component: DashboardTemplate,
  beforeLoad: async ({ context }) => {
    const { isAuthenticated, getUserRole } = context.auth;
    if (getUserRole() === "Operator") {
      throw redirect({
        to: cmsRoutes.scanner.base,
      });
    }

    const logout = () => {
      clearUserAuth();
      throw redirect({
        to: cmsRoutes.login.base,
      });
    };

    if (!isAuthenticated()) {
      logout();
    } else {
      const data = await getUserInfo(
        getCookie(COOKIE_CONFIG.LOYMEE_TENANT_ID.name)!
      );

      if (data.userRole !== getUserRole()) {
        logout();
      }
    }
  },
});
