import { FormGroup } from "@mui/material";
import { TextInput, LoadingButton, ErrorMessage } from "@repo/ui";
import { emailValidator, loginPasswordValidator } from "@repo/utils";
import { useLoginForm } from "./useLoginForm";
import { useTranslation } from "@repo/i18n-config";

export const LoginForm = () => {
  const { form, isPending, errorMessage } = useLoginForm();
  const { t } = useTranslation("cms");

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
    >
      <FormGroup sx={{ gap: 2, mt: 1, mb: 2 }}>
        <TextInput
          form={form}
          name="email"
          validators={{
            onChange: emailValidator,
          }}
          type="email"
          label={t("Login.form.email")}
        />
        <TextInput
          form={form}
          name="password"
          validators={{
            onChange: loginPasswordValidator,
          }}
          type="password"
          label={t("Login.form.password")}
        />
      </FormGroup>
      <LoadingButton isLoading={isPending} text={t("Login.form.login")} />
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
    </form>
  );
};
