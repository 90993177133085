import { Close, InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { COOKIE_CONFIG, getTenantId } from "@repo/api-config";
import {
  buySubscription,
  cancelCancellation,
  cancelSubscription,
  createBillingData,
  getBillingData,
  getCurrentSubscription,
  getPaymentMethod,
} from "@repo/api-config/services/company";
import { useTranslation } from "@repo/i18n-config";
import {
  BillingAccountType,
  CreateSubscriptionCommand,
  HttpValidationProblemDetails,
  PlanPublicDto,
  SubscriptionFrequency,
  SubscriptionState,
  SubscriptionVm,
} from "@repo/types/companyApi.types";
import { Card, ErrorButton } from "@repo/ui";
import {
  getCookie,
  getSubscriptionDescription,
  getSubscriptionName,
  useNotificationsContext,
  useServerErrorFormatter,
} from "@repo/utils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useMemo, useState } from "react";
import { PlanVerification } from "./PlanVerification";

interface StatusBadgeProps {
  data: SubscriptionVm;
}
const StatusBadge = ({ data }: StatusBadgeProps) => {
  const { t, i18n } = useTranslation("cms");

  const chipColor = useMemo(() => {
    if (data.state === SubscriptionState.Cancelled || data.cancelAtPeriodEnd)
      return "warning";
    if (
      data.state === SubscriptionState.AwaitingPayment ||
      data.state === SubscriptionState.Failed ||
      data.state === SubscriptionState.Expired
    )
      return "error";
    if (
      data.state === SubscriptionState.Trial ||
      data.state === SubscriptionState.Paid
    )
      return "primary";
  }, [data]);

  const dateText = useMemo(() => {
    if (data.state === SubscriptionState.Cancelled || data.cancelAtPeriodEnd)
      return t("subscriptionOptions.ends");
    return t("subscriptionOptions.nextPayment");
  }, [data]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "end",
        gap: 0.5,
      }}
    >
      <Chip
        label={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {data.cancelAtPeriodEnd &&
            data.state !== SubscriptionState.Cancelled
              ? t("subscriptionOptions.forCancel")
              : t(`subscriptionOptions.subscriptionState.${data.state}`)}
            <Tooltip
              title={
                <Typography sx={{ lineHeight: 1.25 }}>
                  {data.cancelAtPeriodEnd &&
                  data.state !== SubscriptionState.Cancelled
                    ? t("subscriptionOptions.subscriptionStateDesc.forCancel")
                    : t(
                        `subscriptionOptions.subscriptionStateDesc.${data.state}`
                      )}
                </Typography>
              }
              placement="top"
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -10],
                      },
                    },
                  ],
                },
                tooltip: {
                  sx: {
                    backgroundColor: (theme) => theme.palette.primary.contrast,
                    padding: 1,
                  },
                },
              }}
            >
              <InfoOutlined sx={{ height: 24, ml: 0.5 }} />
            </Tooltip>
          </Box>
        }
        color={chipColor}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
        }}
      >
        <Typography variant="caption">
          {t(
            `subscriptionOptions.currentSubscriptionFrequency.${data.frequency}`
          )}
        </Typography>

        {data.state === SubscriptionState.Trial && data.trialEnd && (
          <Typography variant="caption">
            {t("subscriptionOptions.trialTo")}{" "}
            {new Date(data.trialEnd).toLocaleString(i18n.language, {
              dateStyle: "short",
            })}
          </Typography>
        )}

        {data.currentPeriodEnd &&
          data.state !== SubscriptionState.Cancelled && (
            <Typography variant="caption">
              {dateText}{" "}
              {new Date(data.currentPeriodEnd).toLocaleString(i18n.language, {
                dateStyle: "short",
              })}
            </Typography>
          )}

        {data.canceledAt && data.state === SubscriptionState.Cancelled && (
          <Typography variant="caption">
            {dateText}{" "}
            {new Date(data.canceledAt).toLocaleString(i18n.language, {
              dateStyle: "short",
            })}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

interface SubscriptionCardProps {
  item: PlanPublicDto;
  selectedFrequency: SubscriptionFrequency;
}

interface SubModalData {
  plan: PlanPublicDto;
  frequency: SubscriptionFrequency;
  useTrial: boolean;
}

export const SubscriptionCard = ({
  item,
  selectedFrequency,
}: SubscriptionCardProps) => {
  const { t, i18n } = useTranslation("cms");
  const { t: commonT } = useTranslation("common");
  const tenantId = getTenantId();
  const { showNotification } = useNotificationsContext();
  const { formatErrorMessage } = useServerErrorFormatter();
  const isPendingOwner =
    getCookie(COOKIE_CONFIG.LOYMEE_USER_ROLE.name) === "PendingOwner";
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { data: currentSubscription, refetch: refetchCurrentSubscription } =
    useQuery({
      queryKey: ["currentSubscription"],
      queryFn: () => getCurrentSubscription(tenantId!),
    });

  const { data: billingData } = useQuery({
    queryFn: () => getBillingData(tenantId!),
    queryKey: ["BillingData"],
  });

  const { data: paymentMethod } = useQuery({
    queryFn: () => getPaymentMethod(tenantId!),
    queryKey: ["PaymentMethod"],
  });

  const isCurrentSubscription = useMemo(
    () =>
      currentSubscription?.ratePlan?.id === item.id &&
      currentSubscription.frequency === selectedFrequency,
    [currentSubscription, item, selectedFrequency]
  );

  const { mutate: subscribe, isPending: buyPending } = useMutation<
    void,
    AxiosError,
    CreateSubscriptionCommand
  >({
    mutationFn: (values) => {
      if (!values.useTrial && (!billingData || !paymentMethod)) {
        showNotification(
          commonT("errorCodeMessages.billing-profile-not-configured"),
          {
            type: "error",
          }
        );
        return Promise.reject();
      }
      if (!billingData && values.useTrial) {
        return createBillingData(tenantId!, {
          firstName: "",
          lastName: "",
          billingEmail: "",
          accountType: BillingAccountType.Business,
          address: "",
          city: "",
          country: "",
          zipCode: "",
        }).then(() => buySubscription(values, tenantId!));
      } else return buySubscription(values, tenantId!);
    },
    onSuccess: () => {
      showNotification(t("subscriptionOptions.subscriptionActivated"));
      refetchCurrentSubscription();
    },
    onError: (error) =>
      showNotification(
        formatErrorMessage(error as AxiosError<HttpValidationProblemDetails>),
        {
          type: "error",
        }
      ),
  });

  const { mutate: cancelSubscriptionMutation, isPending: cancelPending } =
    useMutation<void, AxiosError, void>({
      mutationFn: () => cancelSubscription(currentSubscription!.id, tenantId!),
      onSuccess: () => {
        showNotification(t("subscriptionOptions.subscriptionCanceled"));
        setTimeout(() => {
          refetchCurrentSubscription();
        }, 1000);
      },
      onError: (error) =>
        showNotification(
          formatErrorMessage(error as AxiosError<HttpValidationProblemDetails>),
          {
            type: "error",
          }
        ),
    });

  const {
    mutate: cancelCancelationMutation,
    isPending: cancelCancelationPending,
  } = useMutation<void, AxiosError, void>({
    mutationFn: () => cancelCancellation(currentSubscription!.id, tenantId!),
    onSuccess: () => {
      showNotification(t("subscriptionOptions.subscriptionReactivated"));
      setTimeout(() => {
        refetchCurrentSubscription();
      }, 1000);
    },
    onError: (error) =>
      showNotification(
        formatErrorMessage(error as AxiosError<HttpValidationProblemDetails>),
        {
          type: "error",
        }
      ),
  });

  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [cancelModalType, setCancelModalType] = useState<
    "cancelSub" | "cancelCancellation" | null
  >(null);
  const closeActionModal = () => {
    setCancelModalOpen(false);
    setTimeout(() => {
      setCancelModalType(null);
    }, 300);
  };

  const [subModalOpen, setSubModalOpen] = useState(false);
  const [subModalData, setSubModalData] = useState<SubModalData | null>(null);
  const openSubModal = (data: SubModalData) => {
    if (!data.useTrial && (!billingData || !paymentMethod)) {
      showNotification(
        commonT("errorCodeMessages.billing-profile-not-configured"),
        {
          type: "error",
        }
      );
    } else {
      setSubModalOpen(true);
      setSubModalData(data);
    }
  };
  const closeSubModal = () => {
    setSubModalOpen(false);
    setTimeout(() => {
      setSubModalData(null);
    }, 300);
  };

  return (
    <Box
      sx={[
        {
          position: "relative",
          zIndex: 1,
        },
        item.namePl === "Profesjonalny" && {
          mt: {
            xs: 4,
            sm: 0,
          },
        },
      ]}
    >
      {item.namePl === "Profesjonalny" && (
        <Box
          sx={(theme) => ({
            position: "absolute",
            top: -32,
            left: 0,
            backgroundColor: theme.palette.primary.main,
            width: "100%",
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            pt: 0.5,
            pb: 1,
            zIndex: -1,
          })}
        >
          <Typography
            fontWeight={700}
            textAlign="center"
            sx={(theme) => ({
              color: theme.palette.common.black,
            })}
          >
            {t("subscriptionOptions.frequentlyChosen")}
          </Typography>
        </Box>
      )}
      <Card
        variant="outlined"
        small
        sx={[
          {
            p: 2,
            height: "100%",
          },
          isCurrentSubscription &&
            ((theme) => ({
              borderColor: theme.palette.primary.main,
              borderWidth: 1,
              borderStyle: "solid",
            })),
        ]}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Chip
            label={getSubscriptionName(item, i18n.language)}
            sx={{
              textTransform: "uppercase",
              alignSelf: "center",
              fontWeight: 700,
              letterSpacing: 1.5,
              flexShrink: 0,
            }}
          />
          <Typography
            variant={isMobile ? "h5" : "h4"}
            fontWeight={700}
            component="p"
            textAlign="center"
            sx={{
              mt: 1,
            }}
          >
            {selectedFrequency === SubscriptionFrequency.Annually
              ? item.pricePerYear.toLocaleString(i18n.language, {
                  style: "currency",
                  currency: "PLN",
                })
              : item.pricePerMonth.toLocaleString(i18n.language, {
                  style: "currency",
                  currency: "PLN",
                })}
          </Typography>
          <Typography sx={{ mb: 2 }} textAlign="center">
            {t(`subscriptionOptions.priceFrequency.${selectedFrequency}`)}
          </Typography>
          {item.descriptionPl && (
            <Typography sx={{ mb: 2 }}>
              {getSubscriptionDescription(item, i18n.language)}
            </Typography>
          )}
          <Typography>
            {t("subscriptionOptions.operatorsLimit")}: {item.operatorsLimit}
          </Typography>
          <Typography>
            {t("subscriptionOptions.rewardsLimit")}: {item.rewardsLimit}
          </Typography>
          <Typography>
            {t("subscriptionOptions.locationsLimit")}: {item.locationsLimit}
          </Typography>
          <Typography>
            {t("subscriptionOptions.pushLimit")}:{" "}
            {item.pushNotificationToSubscribersLimit}
          </Typography>
          <Typography>
            {t("subscriptionOptions.mailLimit")}: {item.emailToSubscribersLimit}
          </Typography>
          <Box
            sx={[
              {
                display: "flex",
                flexDirection: "column",
                gap: 1,
                justifyContent: "space-between",
                width: "100%",
                mt: "auto",
                pt: 2,
              },
              isCurrentSubscription
                ? {
                    height: "100%",
                  }
                : {
                    height: "auto",
                  },
            ]}
          >
            {isCurrentSubscription && (
              <StatusBadge data={currentSubscription!} />
            )}
            {isCurrentSubscription &&
            currentSubscription?.state !== SubscriptionState.Cancelled ? (
              <>
                {(currentSubscription?.state === SubscriptionState.Paid ||
                  currentSubscription?.state === SubscriptionState.Trial) &&
                  !currentSubscription?.cancelAtPeriodEnd && (
                    <ErrorButton
                      onClick={() => {
                        setCancelModalOpen(true);
                        setCancelModalType("cancelSub");
                      }}
                      disabled={cancelPending}
                    >
                      {t("subscriptionOptions.cancelSubscription")}
                    </ErrorButton>
                  )}
                {currentSubscription?.cancelAtPeriodEnd && (
                  <ErrorButton
                    onClick={() => {
                      setCancelModalOpen(true);
                      setCancelModalType("cancelCancellation");
                    }}
                    disabled={cancelCancelationPending}
                  >
                    {t("subscriptionOptions.cancelCancelation")}
                  </ErrorButton>
                )}
              </>
            ) : (
              <>
                {isPendingOwner && !currentSubscription ? (
                  <Button
                    color="secondary"
                    disabled={buyPending}
                    onClick={() => {
                      openSubModal({
                        frequency: selectedFrequency,
                        plan: item,
                        useTrial: true,
                      });
                    }}
                  >
                    {t("subscriptionOptions.startTrial")}
                  </Button>
                ) : (
                  <Button
                    color="secondary"
                    disabled={
                      buyPending ||
                      (currentSubscription &&
                        currentSubscription?.state !==
                          SubscriptionState.Cancelled)
                    }
                    onClick={() => {
                      openSubModal({
                        frequency: selectedFrequency,
                        plan: item,
                        useTrial: false,
                      });
                    }}
                  >
                    {t("subscriptionOptions.buy")}
                  </Button>
                )}
              </>
            )}
          </Box>
        </Box>
        <Dialog
          open={cancelModalOpen}
          onClose={closeActionModal}
          fullWidth
          maxWidth="sm"
        >
          {cancelModalType && (
            <>
              <DialogTitle
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {t(`subscriptionOptions.cancelModal.${cancelModalType}.header`)}
                <IconButton onClick={closeActionModal} sx={{ mr: -1 }}>
                  <Close />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                {t(
                  `subscriptionOptions.cancelModal.${cancelModalType}.description`,
                  {
                    endDate: new Date(
                      currentSubscription!.currentPeriodEnd
                    ).toLocaleString(i18n.language, {
                      dateStyle: "short",
                    }),
                  }
                )}
                <Box
                  sx={{ display: "flex", justifyContent: "end", gap: 2, mt: 5 }}
                >
                  <Button onClick={closeActionModal} variant="text">
                    {t(`subscriptionOptions.cancelModal.${cancelModalType}.no`)}
                  </Button>
                  <ErrorButton
                    onClick={() => {
                      if (cancelModalType === "cancelSub")
                        cancelSubscriptionMutation();
                      if (cancelModalType === "cancelCancellation")
                        cancelCancelationMutation();
                      closeActionModal();
                    }}
                  >
                    {t(
                      `subscriptionOptions.cancelModal.${cancelModalType}.yes`
                    )}
                  </ErrorButton>
                </Box>
              </DialogContent>
            </>
          )}
        </Dialog>
        <Dialog
          open={subModalOpen}
          onClose={closeSubModal}
          fullWidth
          maxWidth="sm"
        >
          {subModalData && (
            <>
              <DialogTitle
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {subModalData.useTrial
                  ? t("subscriptionOptions.subModal.headerTrial")
                  : t("subscriptionOptions.subModal.header")}
                <IconButton onClick={closeSubModal} sx={{ mr: -1 }}>
                  <Close />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <PlanVerification
                  prependContent={(eligible) =>
                    eligible ? (
                      <>
                        {subModalData.useTrial ? (
                          t("subscriptionOptions.subModal.confirmTrialDesc", {
                            name: getSubscriptionName(
                              subModalData.plan,
                              i18n.language
                            ),
                            period: t(
                              `subscriptionOptions.subModal.frequencyTrial.${subModalData.frequency}`
                            ),
                          })
                        ) : (
                          <>
                            {t("subscriptionOptions.subModal.confirmDesc")}{" "}
                            {getSubscriptionName(
                              subModalData.plan,
                              i18n.language
                            )}{" "}
                            -{" "}
                            {t(
                              `subscriptionOptions.subModal.frequency.${subModalData.frequency}`
                            )}
                            ?
                          </>
                        )}
                      </>
                    ) : (
                      <>{t("subscriptionOptions.subModal.limitExceededDesc")}</>
                    )
                  }
                  planData={subModalData.plan}
                  type="new"
                  appendContent={(eligible) => (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        gap: 2,
                        mt: 5,
                      }}
                    >
                      {eligible ? (
                        <>
                          <Button onClick={closeSubModal} variant="text">
                            {t("subscriptionOptions.subModal.no")}
                          </Button>
                          <Button
                            onClick={() => {
                              subscribe({
                                frequency: subModalData.frequency,
                                planId: subModalData.plan.id,
                                useTrial: subModalData.useTrial,
                              });
                              closeSubModal();
                            }}
                          >
                            {t("subscriptionOptions.subModal.yes")}
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button onClick={closeSubModal} variant="text">
                            {t("subscriptionOptions.subModal.close")}
                          </Button>
                        </>
                      )}
                    </Box>
                  )}
                />
              </DialogContent>
            </>
          )}
        </Dialog>
      </Card>
    </Box>
  );
};
