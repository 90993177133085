"use client";
import { useQuery } from "@tanstack/react-query";
import { UserInfoResponse as CompanyUserInfoResponse } from "@repo/types/companyApi.types";
import { UserInfoResponse as CustomerUserInfoResponse } from "@repo/types/customerApi.types";
import { UserInfoResponse as RootUserInfoResponse } from "@repo/types/rootApi.types";
import { getUserInfo as getCustomerUserInfo } from "../services/customer";
import { getUserInfo as getCompanyUserInfo } from "../services/company";
import { getUserInfo as getRootUserInfo } from "../services/root";
import { getTenantId } from "./authUtils";
import { getCookie } from "@repo/utils";
import { COOKIE_CONFIG } from "../cookieConfig";

type UserInfo =
  | CompanyUserInfoResponse
  | CustomerUserInfoResponse
  | RootUserInfoResponse;

function getUserInfo(type: "company" | "customer" | "admin") {
  switch (type) {
    case "customer":
      return getCustomerUserInfo;
    case "company": {
      const tenantId = getTenantId(); // must be here, not in useMe, otherwise Next.js will trigger a "document is not defined" error
      return () => getCompanyUserInfo(tenantId!);
    }
    case "admin":
      return getRootUserInfo;
    default:
      throw new Error("Invalid type");
  }
}

export const useMe = (
  type: "company" | "customer" | "admin",
  isAuthenticated?: boolean
): UserInfo => {
  const { data } = useQuery({
    queryKey: ["userInfo"],
    queryFn: getUserInfo(type),
    enabled: isAuthenticated,
  });
  return (data as UserInfo) || {};
};

export const useCmsRole = () => {
  const roleFromCookie = getCookie(COOKIE_CONFIG.LOYMEE_USER_ROLE.name);

  return roleFromCookie === "SuperAdmin" || roleFromCookie === "Agent"
    ? "admin"
    : "company";
};
