import { LanguageCode } from "@repo/types/customerApi.types";

export const fallbackLng = LanguageCode.Pl;
export const languages = Object.values(LanguageCode);
export const cookieName = "loymeeI18nLang";
export const defaultNS = "translation";

export function parseLanguageCode(code: string): LanguageCode {
  switch (code) {
    case LanguageCode.Pl:
      return LanguageCode.Pl;
    case LanguageCode.En:
      return LanguageCode.En;
    default:
      return LanguageCode.Pl;
  }
}

export function getOptions(
  lng?: string | undefined,
  ns: string | string[] = defaultNS
) {
  return {
    supportedLngs: languages,
    fallbackLng,
    lng,
    fallbackNS: defaultNS,
    defaultNS,
    ns,
  };
}

export function getMuiLocale(lng: string) {
  switch (parseLanguageCode(lng)) {
    case LanguageCode.Pl:
      return "plPL";
    case LanguageCode.En:
      return "enUS";
    default:
      return "plPL";
  }
}
