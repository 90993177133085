"use client";

import { Box, Divider, FormGroup, Typography } from "@mui/material";
import {
  Button,
  Card,
  ErrorMessage,
  FunctionNotAvailable,
  TextInput,
} from "@repo/ui";
import { useForm } from "@tanstack/react-form";
import { valibotValidator } from "@tanstack/valibot-form-adapter";
import {
  cmsRoutes,
  notificationContentValidator,
  notificationTitleValidator,
  requiredNotificationContentValidator,
  requiredNotificationTitleValidator,
  useNotificationsContext,
  useServerErrorFormatter,
} from "@repo/utils";
import { useMe } from "@repo/api-config";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getFlags,
  sendTopicNotification,
} from "@repo/api-config/services/company";
import { AxiosError } from "axios";
import {
  LanguageCode,
  SendPushNotificationToCompanySubscribersCommand,
  UserInfoResponse,
} from "@repo/types/companyApi.types";
import { useTranslation } from "@repo/i18n-config";
import { useNavigate } from "@tanstack/react-router";
import { HttpValidationProblemDetails } from "@repo/types/customerApi.types";
import ReactCountryFlag from "react-country-flag";
import { useEffect } from "react";

const NewNotification = () => {
  const { t, i18n } = useTranslation("cms");

  const { tenantIdentifier } = useMe("company");
  const { showNotification } = useNotificationsContext();
  const { formatErrorMessage } = useServerErrorFormatter();
  const navigate = useNavigate();

  const { data: flags, isLoading: flagsLoading } = useQuery({
    queryKey: ["flags"],
    queryFn: () => getFlags(tenantIdentifier!),
    refetchOnMount: true,
  });

  const {
    mutate: sendTopicPush,
    error,
    reset,
  } = useMutation<
    UserInfoResponse,
    AxiosError,
    SendPushNotificationToCompanySubscribersCommand
  >({
    mutationFn: (values) => {
      reset();
      return sendTopicNotification(tenantIdentifier || "", values);
    },
    onSuccess: () => {
      showNotification(t("Notifications.pushSent"));
      navigate({ to: cmsRoutes.notifications.base });
    },
  });

  const pushNotificationForm = useForm({
    defaultValues: {
      title: "",
      titlePl: "",
      content: "",
      contentPl: "",
    },
    onSubmit: async ({ value }) => {
      sendTopicPush({
        body: value.content,
        bodyPl: value.contentPl,
        title: value.title,
        titlePl: value.titlePl,
        customData: {},
      });
    },
    validatorAdapter: valibotValidator(),
  });

  useEffect(() => {
    if (!pushNotificationForm.store.state.isValid) {
      pushNotificationForm.validateField("title", "change");
      pushNotificationForm.validateField("titlePl", "change");
      pushNotificationForm.validateField("content", "change");
      pushNotificationForm.validateField("contentPl", "change");
    }
  }, [i18n.language]);

  if (flagsLoading) return null;
  if (!flags?.pushNotificationToSubscribersLimit)
    return <FunctionNotAvailable />;

  return (
    <>
      <Typography variant="h6">
        {t("Notifications.yourNotifications")}:{" "}
        {flags.pushNotificationToSubscribersUsed}/
        {flags.pushNotificationToSubscribersLimit}
      </Typography>
      <Card
        title={t("Notifications.newNotification")}
        sx={{
          flexDirection: {
            xs: "column",
            md: "row",
          },
          "& h6": {
            width: { xs: "100%", md: "40%" },
          },
          mt: 2,
        }}
      >
        <Box
          component="form"
          sx={{ display: "flex", flex: 1 }}
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            pushNotificationForm.handleSubmit();
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <FormGroup sx={{ gap: 2 }}>
              {(() => {
                const plInputs = (
                  <FormGroup
                    sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                  >
                    <Typography variant="h6" component="p">
                      {t("Notifications.form.plVersion")}{" "}
                      <ReactCountryFlag
                        countryCode="pl"
                        svg
                        style={{ marginLeft: 4, marginBottom: 2 }}
                      />
                    </Typography>

                    <TextInput
                      form={pushNotificationForm}
                      name="titlePl"
                      validators={{
                        onChange:
                          i18n.language === LanguageCode.Pl
                            ? requiredNotificationTitleValidator
                            : notificationTitleValidator,
                      }}
                      label={t("Notifications.form.subject")}
                      required={i18n.language === LanguageCode.Pl}
                      charLimit={40}
                    />
                    <TextInput
                      form={pushNotificationForm}
                      name="contentPl"
                      validators={{
                        onChange:
                          i18n.language === LanguageCode.Pl
                            ? requiredNotificationContentValidator
                            : notificationContentValidator,
                      }}
                      label={t("Notifications.form.content")}
                      required={i18n.language === LanguageCode.Pl}
                      multiline
                      charLimit={120}
                    />
                  </FormGroup>
                );

                const enInputs = (
                  <FormGroup
                    sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                  >
                    <Typography variant="h6" component="p">
                      {t("Notifications.form.enVersion")}{" "}
                      <ReactCountryFlag
                        countryCode="us"
                        svg
                        style={{ marginLeft: 4, marginBottom: 2 }}
                      />
                    </Typography>
                    <TextInput
                      form={pushNotificationForm}
                      name="title"
                      validators={{
                        onChange:
                          i18n.language === LanguageCode.En
                            ? requiredNotificationTitleValidator
                            : notificationTitleValidator,
                      }}
                      label={t("Notifications.form.subject")}
                      required={i18n.language === LanguageCode.En}
                      charLimit={40}
                    />
                    <TextInput
                      form={pushNotificationForm}
                      name="content"
                      validators={{
                        onChange:
                          i18n.language === LanguageCode.En
                            ? requiredNotificationContentValidator
                            : notificationContentValidator,
                      }}
                      label={t("Notifications.form.content")}
                      required={i18n.language === LanguageCode.En}
                      multiline
                      charLimit={120}
                    />
                  </FormGroup>
                );

                return i18n.language === LanguageCode.En
                  ? [enInputs, <Divider sx={{ mb: 1 }} />, plInputs]
                  : [plInputs, <Divider sx={{ mb: 1 }} />, enInputs];
              })()}
            </FormGroup>
            {error && (
              <ErrorMessage
                errorMessage={formatErrorMessage(
                  error as AxiosError<HttpValidationProblemDetails>
                )}
              />
            )}
            <pushNotificationForm.Subscribe
              selector={(state) => state.isValid && !state.isPristine}
              children={(isValid) => (
                <Button
                  type="submit"
                  sx={{ mt: 9, alignSelf: "flex-end" }}
                  variant="contained"
                  disabled={
                    !isValid ||
                    flags.pushNotificationToSubscribersUsed >=
                      flags.pushNotificationToSubscribersLimit
                  }
                >
                  {t("Notifications.form.submit")}
                </Button>
              )}
            />
          </Box>
        </Box>
      </Card>
    </>
  );
};
export default NewNotification;
