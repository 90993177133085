import { Box, FormGroup } from "@mui/material";
import { COOKIE_CONFIG, getTenantId } from "@repo/api-config";
import {
  addEmployee,
  editEmployee,
  getEmployeesList,
} from "@repo/api-config/services/company";
import { useTranslation } from "@repo/i18n-config";
import {
  HttpValidationProblemDetails,
  RegisterEmployeeRequest,
  UpdateEmployeeCommand,
} from "@repo/types/companyApi.types";
import { Button, Card, ErrorMessage, TextInput } from "@repo/ui";
import {
  cmsRoutes,
  confirmPasswordValidator,
  getCookie,
  passwordValidator,
  requiredStringValidator,
  useNotificationsContext,
  useServerErrorFormatter,
} from "@repo/utils";
import { FormApi, ReactFormApi, useForm } from "@tanstack/react-form";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useLoaderData, useNavigate } from "@tanstack/react-router";
import { valibotValidator } from "@tanstack/valibot-form-adapter";
import { AxiosError } from "axios";

type EmployeeFormType = RegisterEmployeeRequest & {
  repeatPassword: string;
};

export const NewEmployee = () => {
  const { showNotification } = useNotificationsContext();
  const navigate = useNavigate();
  const { t } = useTranslation("cms");
  const tenantId = getTenantId();
  const queryClient = useQueryClient();
  const isPendingOwner =
    getCookie(COOKIE_CONFIG.LOYMEE_USER_ROLE.name) === "PendingOwner";

  const { data: employeesList } = useQuery({
    queryFn: () => getEmployeesList(tenantId!, "PENDINGOPERATOR"),
    queryKey: ["employees"],
    enabled: !!tenantId && isPendingOwner,
  });

  const { mutate: addEmployeeMutation, error } = useMutation<
    void,
    AxiosError,
    RegisterEmployeeRequest
  >({
    mutationFn: (values) => addEmployee(tenantId!, values),
    onSuccess: () => {
      const firstEmployee = employeesList?.length === 0;
      showNotification(t("Employees.employeeAdded"));
      queryClient.invalidateQueries({
        queryKey: ["employees"],
      });
      navigate({
        to:
          isPendingOwner && firstEmployee
            ? `${cmsRoutes.employees.base}?pending=true`
            : cmsRoutes.employees.base,
      });
    },
  });

  const form = useForm<EmployeeFormType, any>({
    defaultValues: {
      userName: "",
      password: "",
      repeatPassword: "",
    },
    onSubmit: ({ value }) => {
      addEmployeeMutation({
        userName: value.userName,
        password: value.password,
      });
    },
    validatorAdapter: valibotValidator(),
  });

  return (
    <Card
      title={t("Employees.addEmployee")}
      description={t("Employees.addEmployeeDescription")}
      sx={{
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        "& h6": {
          width: { xs: "100%", sm: "40%" },
        },
      }}
    >
      <EmployeeForm
        form={form}
        error={
          error
            ? (error as AxiosError<HttpValidationProblemDetails>)
            : undefined
        }
      />
    </Card>
  );
};

export const EditEmployee = () => {
  const loaderData = useLoaderData({
    from: "/_private/_withDashboard/employees/edit/$employeeId",
  });
  const navigate = useNavigate();
  const { showNotification } = useNotificationsContext();

  const { t } = useTranslation("cms");
  const tenantId = getTenantId();
  const queryClient = useQueryClient();

  const { mutate: editEmployeeMutation, error } = useMutation<
    void,
    AxiosError,
    UpdateEmployeeCommand
  >({
    mutationFn: (values) => editEmployee(tenantId!, values),
    onSuccess: () => {
      showNotification(t("Employees.employeeEdited"));
      queryClient.invalidateQueries({
        queryKey: ["employees"],
      });
      navigate({ to: cmsRoutes.employees.base });
    },
  });

  const form = useForm<EmployeeFormType, any>({
    defaultValues: {
      userName: loaderData.email.split("@")[0] || "",
      password: "",
      repeatPassword: "",
    },
    onSubmit: ({ value }) => {
      editEmployeeMutation({
        id: loaderData.id,
        ...(value.userName !== loaderData.email.split("@")[0]
          ? { email: `${value.userName}@${loaderData.email.split("@")[1]}` }
          : {}),
        newPassword: value.password,
      });
    },
    validatorAdapter: valibotValidator(),
  });

  return (
    <Card
      title={t("Employees.editCardTitle")}
      sx={{
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        "& h6": {
          width: { xs: "100%", sm: "40%" },
        },
      }}
    >
      <EmployeeForm
        form={form}
        error={
          error
            ? (error as AxiosError<HttpValidationProblemDetails>)
            : undefined
        }
      />
    </Card>
  );
};

interface EmployeeFormProps {
  form: FormApi<EmployeeFormType, any> & ReactFormApi<EmployeeFormType, any>;
  error?: AxiosError<HttpValidationProblemDetails>;
}

const EmployeeForm = ({ form, error }: EmployeeFormProps) => {
  const { t } = useTranslation("cms");
  const { formatErrorMessage } = useServerErrorFormatter();

  return (
    <Box
      component="form"
      sx={{ display: "flex", flex: 1 }}
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <FormGroup sx={{ gap: 2 }}>
          <TextInput
            form={form}
            name="userName"
            validators={{
              onChange: requiredStringValidator,
            }}
            label={t("Employees.form.email")}
            required
            InputProps={{
              endAdornment: (
                <>
                  @
                  {getCookie(
                    COOKIE_CONFIG.LOYMEE_TENANT_ID.name
                  )?.toLowerCase()}
                </>
              ),
            }}
          />
          <TextInput
            form={form}
            type="password"
            name="password"
            validators={{
              onChange: passwordValidator,
            }}
            label={t("Employees.form.password")}
            autoComplete="new-password"
            required
          />
          <TextInput
            form={form}
            type="password"
            name="repeatPassword"
            validators={{
              onChange: confirmPasswordValidator,
            }}
            label={t("Employees.form.repeatPassword")}
            autoComplete="new-password"
            required
          />
        </FormGroup>
        {error && <ErrorMessage errorMessage={formatErrorMessage(error)} />}
        <form.Subscribe
          selector={(state) => state.isValid && !state.isPristine}
          children={(isValid) => (
            <Button
              type="submit"
              sx={{ mt: 4, alignSelf: "flex-end" }}
              variant="contained"
              disabled={!isValid}
            >
              {t("Employees.form.save")}
            </Button>
          )}
        />
      </Box>
    </Box>
  );
};
