import { Add } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { getTenantId } from "@repo/api-config";
import {
  deleteMessage,
  getFlags,
  getMessagesHistory,
} from "@repo/api-config/services/company";
import { useTranslation } from "@repo/i18n-config";
import {
  ConfirmModal,
  DataTable,
  FunctionNotAvailable,
  RowsPerPageOption,
} from "@repo/ui";
import { cmsRoutes, useNotificationsContext } from "@repo/utils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Link } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { useMemo, useState } from "react";
import { DeletedItem } from "../../@types";
import { MessageType } from "@repo/types/companyApi.types";

export const MailNotificationsPage = () => {
  const { t } = useTranslation("cms");
  const tenantId = getTenantId() || "";

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState<RowsPerPageOption>(10);

  const { showNotification } = useNotificationsContext();
  const { data, isLoading, refetch } = useQuery({
    queryFn: () =>
      getMessagesHistory(tenantId, { pageNumber, pageSize }, MessageType.Email),
    queryKey: ["MailNotifications", pageNumber, pageSize],
    refetchOnMount: true,
  });

  const notificationsList = useMemo(() => {
    if (data)
      return {
        ...data,
        items: data.items.map((obj) => {
          const { messageType, subjectPl, bodyContentPl, ...rest } = obj;
          return {
            ...rest,
          };
        }),
      };
  }, [data]);

  const { data: flags, isFetching: flagsFetching } = useQuery({
    queryKey: ["flags"],
    queryFn: () => getFlags(tenantId!),
    refetchOnMount: true,
  });

  const [deletedItem, setDeletedItem] = useState<DeletedItem>(null);
  const {
    mutate: deleteNotificationMutation,
    isPending: isDeleteNotificationMutationPending,
  } = useMutation<any, AxiosError, any>({
    mutationFn: (id) => deleteMessage(tenantId!, id),
    onSuccess: () => {
      showNotification(t("MailNotifications.notificationDeleted"));
      refetch();
    },
    onError: () => {
      showNotification(t("MailNotifications.notificationDeleteError"), {
        type: "error",
      });
    },
  });

  if (flagsFetching) return null;
  if (!flags?.emailToSubscribersLimit) return <FunctionNotAvailable />;

  return (
    <Box>
      {data && (
        <Typography variant="h6">
          {t("MailNotifications.yourEmails")}: {flags.emailToSubscribersUsed}/
          {flags.emailToSubscribersLimit}
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          mt: -8.5,
          mb: 6,
        }}
      >
        <Button
          component={Link}
          to={cmsRoutes.mailNotifications.new}
          size="large"
          sx={{ pl: 2 }}
          disabled={
            flags.emailToSubscribersUsed >= flags.emailToSubscribersLimit
          }
        >
          <Add sx={{ mr: 1 }} />
          {t("MailNotifications.newNotification")}
        </Button>
      </Box>
      <DataTable
        handlePageChange={setPageNumber}
        handlePageSizeChange={setPageSize}
        pageSize={pageSize}
        isFetching={isLoading}
        data={notificationsList}
        showActions={{
          handleDelete: (id) => {
            setDeletedItem({
              id,
              name: data?.items.find((i) => i.id === id)?.subject!,
            });
          },
          isDeleteMutationPending: isDeleteNotificationMutationPending,
        }}
      />
      <ConfirmModal
        open={!!deletedItem?.id}
        handleNo={() => setDeletedItem({ ...deletedItem!, id: "" })}
        handleYes={() => {
          setDeletedItem({ ...deletedItem!, id: "" });
          deleteNotificationMutation(deletedItem!.id);
        }}
        text={t("MailNotifications.notificationDeleteConfirm", {
          name: deletedItem?.name,
        })}
      />
    </Box>
  );
};
