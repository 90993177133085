import { Box, FormGroup } from "@mui/material";
import { COOKIE_CONFIG, handleAuthResponse, useMe } from "@repo/api-config";
import { updatePassword } from "@repo/api-config/services/company";
import { useTranslation } from "@repo/i18n-config";
import {
  AccessTokenResponse,
  HttpValidationProblemDetails,
  InfoRequest,
} from "@repo/types/companyApi.types";
import { Button, Card, ErrorMessage, TextInput } from "@repo/ui";
import {
  confirmPasswordValidator,
  getCookie,
  passwordValidator,
  requiredStringValidator,
  useNotificationsContext,
  useServerErrorFormatter,
} from "@repo/utils";
import { useForm } from "@tanstack/react-form";
import { useMutation } from "@tanstack/react-query";
import { valibotValidator } from "@tanstack/valibot-form-adapter";
import { AxiosError } from "axios";
import { useEffect } from "react";

export const ChangePassword = () => {
  const { t } = useTranslation("cms");
  const { tenantIdentifier } = useMe("company");

  const { showNotification } = useNotificationsContext();
  const { formatErrorMessage } = useServerErrorFormatter();

  const {
    mutate: updateUserInfoMutation,
    error,
    reset,
  } = useMutation<AccessTokenResponse, AxiosError, InfoRequest>({
    mutationFn: (values) => updatePassword(values, tenantIdentifier || ""),
    onSuccess: (values) => {
      showNotification(t("Settings.changePasswordForm.passwordChanged"));
      handleAuthResponse(
        values,
        undefined,
        !!getCookie(COOKIE_CONFIG.LOYMEE_REFRESH_TOKEN.name)
      );
      changePasswordForm.reset();
    },
  });

  const changePasswordForm = useForm({
    defaultValues: {
      oldPassword: "",
      password: "",
      confirmPassword: "",
    },
    onSubmit: async ({ value }) => {
      updateUserInfoMutation({
        newPassword: value.password,
        oldPassword: value.oldPassword,
      });
    },
    validatorAdapter: valibotValidator(),
  });

  useEffect(() => {
    return changePasswordForm.store.subscribe(() => {
      if (error) reset();
    });
  }, [changePasswordForm.store, error]);

  return (
    <Card
      title={t("Settings.changePassword")}
      sx={{
        flexDirection: {
          xs: "column",
          md: "row",
        },
        "& h6": {
          width: { xs: "100%", md: "40%" },
        },
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flex: 1 }}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          changePasswordForm.handleSubmit();
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <FormGroup sx={{ gap: 2 }}>
            <TextInput
              form={changePasswordForm}
              name="oldPassword"
              validators={{
                onChange: requiredStringValidator,
              }}
              type="password"
              label={t("Settings.changePasswordForm.oldPassword")}
              required
            />
            <TextInput
              form={changePasswordForm}
              name="password"
              validators={{
                onChange: passwordValidator,
              }}
              type="password"
              label={t("Settings.changePasswordForm.password")}
              required
            />
            <TextInput
              form={changePasswordForm}
              name="confirmPassword"
              validators={{
                onChangeListenTo: ["password"],
                onChange: confirmPasswordValidator,
              }}
              type="password"
              label={t("Settings.changePasswordForm.confirmPassword")}
              required
            />
          </FormGroup>
          {error && (
            <ErrorMessage
              errorMessage={formatErrorMessage(
                error as AxiosError<HttpValidationProblemDetails>
              )}
            />
          )}
          <changePasswordForm.Subscribe
            selector={(state) => state.isValid && !state.isPristine}
            children={() => (
              <Button
                type="submit"
                sx={{ mt: 4, alignSelf: "flex-end" }}
                variant="contained"
              >
                {t("Settings.changePasswordForm.save")}
              </Button>
            )}
          />
        </Box>
      </Box>
    </Card>
  );
};
