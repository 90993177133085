"use client";

import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSignalRConnection } from "../hooks/useSignalRConnection";
import { customerApiSocketPaths } from "../../api-config/services/customer/paths";
import { useTranslation } from "@repo/i18n-config";
import { useNotificationsContext } from "../providers/NotificationsContext";
import { useQueryClient } from "@tanstack/react-query";

interface UserCodeContextType {
  publicId: string | null;
  qrCodeErrorMessage?: string;
}

const UserCodeContext = createContext<UserCodeContextType | undefined>(
  undefined
);

export const UserCodeContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  
  const { t } = useTranslation("webapp");
  const { showNotification } = useNotificationsContext();
  const queryClient = useQueryClient();

  const [publicId, setPublicId] = useState<string | null>(null);
  const { connection: qrCodeWebSocket, errorMessage: qrCodeErrorMessage } =
    useSignalRConnection(
      `${process.env.NEXT_PUBLIC_API_BASE_URL}${customerApiSocketPaths.gainPoints}`
    );

  useEffect(() => {
    if (qrCodeWebSocket) {
      qrCodeWebSocket
        .invoke("GetCustomerPublicId")
        .then((data) => setPublicId(data.customerPublicId));
      qrCodeWebSocket.on("NotifyUserAboutGainedPoints", () => {
        showNotification(t("home.pointsScanned"));
        queryClient.invalidateQueries({
          queryKey: ["userHomeScans"],
        });
      });
    }
  }, [qrCodeWebSocket]);

  return (
    <UserCodeContext.Provider value={{ publicId, qrCodeErrorMessage }}>
      {children}
    </UserCodeContext.Provider>
  );
};

export const useUserCodeContext = (): UserCodeContextType => {
  const context = useContext(UserCodeContext);
  if (!context) {
    throw new Error(
      "UserCodeContext must be used within a UserCodeContextProvider"
    );
  }
  return context;
};
