/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AccessTokenResponse {
  tokenType: string;
  accessToken: string;
  /** @format int64 */
  expiresIn: number;
  refreshToken: string;
}

export interface AddPlanCommand {
  /**
   * @minLength 1
   * @maxLength 200
   */
  name: string;
  /** @maxLength 1000 */
  description?: string | null;
  /**
   * @minLength 1
   * @maxLength 200
   */
  namePl: string;
  /** @maxLength 1000 */
  descriptionPl?: string | null;
  /**
   * @format double
   * @min 0
   */
  pricePerMonth: number;
  /** @format double */
  pricePerYear: number;
  /**
   * @format int32
   * @min 1
   */
  operatorsLimit: number;
  /**
   * @format int32
   * @min 1
   */
  rewardsLimit: number;
  /**
   * @format int32
   * @min 1
   */
  locationLimit: number;
  /** @format int32 */
  emailToSubscribersLimit: number;
  /** @format int32 */
  pushNotificationToSubscribersLimit: number;
  accessibility: PlanAccessibility;
}

export interface AddPlanResult {
  /** @format int32 */
  planId: number;
}

export interface BaseInfoDto {
  name: string;
  /** @format date-time */
  created: string;
  ownerEmail: string;
  phoneNumber?: string | null;
}

export enum CompanyCategory {
  Other = "Other",
  Restaurant = "Restaurant",
  Cafe = "Cafe",
  RetailStore = "RetailStore",
  BeautySalon = "BeautySalon",
  FitnessNutrition = "FitnessNutrition",
  HairSalon = "HairSalon",
  BarberShop = "BarberShop",
  MassagePhysiotherapy = "MassagePhysiotherapy",
  TattooPiercing = "TattooPiercing",
  HealthWellness = "HealthWellness",
  AestheticMedicine = "AestheticMedicine",
  ForPets = "ForPets",
  CarWash = "CarWash",
  Bakery = "Bakery",
  StreetFood = "StreetFood",
}

export interface CompanyDto {
  identifier: string;
  name: string;
  verificationStatus: CompanyVerificationStatus;
  phoneNumber: string;
  pointsType: string;
  /** @format int32 */
  multiplier: number;
  pointsGainDescription?: string | null;
  pointsGainDescriptionPl?: string | null;
}

export interface CompanyStatsForRootDto {
  /** @format int32 */
  scanPointsCountInPeriod: number;
  /** @format int32 */
  claimedRewardCountInPeriod: number;
  /** @format int32 */
  customerCountInPeriod: number;
  /** @format int32 */
  returningCustomerCountInPeriod: number;
  /** @format int32 */
  initialScanCustomerCountInPeriod: number;
}

export interface CompanySubscriptionDto {
  /** @format int32 */
  planId?: number | null;
  planName?: string | null;
  /** @format double */
  price?: number | null;
  currency?: string | null;
  subscriptionFrequency?: SubscriptionFrequency | null;
  subscriptionState: SubscriptionState;
  /** @format date-time */
  lastPayment?: string | null;
  /** @format date-time */
  nextPayment?: string | null;
}

export interface CompanySummaryDto {
  identifier: string;
  name: string;
  ownerEmail: string;
  phoneNumber?: string | null;
  verificationStatus: CompanyVerificationStatus;
  isActive: boolean;
  /** @format date-time */
  lastScanActivity?: string | null;
  subscription: CompanySubscriptionDto;
}

export interface CompanySummaryDtoPaginatedList {
  items: CompanySummaryDto[];
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  totalCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export enum CompanyVerificationStatus {
  Pending = "Pending",
  Confirmed = "Confirmed",
  Rejected = "Rejected",
}

export interface EmailStatisticsDto {
  /** @format int32 */
  processed: number;
  /** @format int32 */
  delivered: number;
  /** @format int32 */
  uniqueOpens: number;
}

export interface FlagsDto {
  billingProfileIncomplete: boolean;
  lackOfPaymentMethod: boolean;
}

export interface ForgotPasswordRequest {
  email: string;
}

export interface GetCommissionSummaryResult {
  /** @format double */
  totalCommissionPln: number;
  /** @format double */
  currentMonthRealCommissionPln: number;
  /** @format double */
  currentMonthPotentialCommissionPln: number;
}

export interface GetConnectAccountUrlResult {
  accountUrl: string;
}

export interface GetReferralUrlResult {
  referralUrl: string;
}

export type HttpValidationProblemDetails = ProblemDetails & {
  errors?: Record<string, string[]>;
  [key: string]: any;
};

export interface InfoRequest {
  newEmail?: string | null;
  newPassword?: string | null;
  oldPassword?: string | null;
  languageCode?: LanguageCode | null;
}

export enum LanguageCode {
  En = "en",
  Pl = "pl",
}

export interface LoginRequest {
  email: string;
  password: string;
}

export interface NoContent {
  /** @format int32 */
  statusCode: number;
}

export enum PlanAccessibility {
  Standalone = "Standalone",
  Custom = "Custom",
}

export interface PlanDto {
  /** @format int32 */
  id: number;
  name: string;
  description?: string | null;
  namePl: string;
  descriptionPl?: string | null;
  /** @format double */
  pricePerMonth: number;
  /** @format double */
  pricePerYear: number;
  /** @format int32 */
  operatorsLimit: number;
  /** @format int32 */
  locationsLimit: number;
  /** @format int32 */
  rewardsLimit: number;
  /** @format int32 */
  emailToSubscribersLimit: number;
  /** @format int32 */
  pushNotificationToSubscribersLimit: number;
  /** @format date-time */
  created: string;
  accessibility: PlanAccessibility;
  isAbandoned: boolean;
}

export interface PlanDtoPaginatedList {
  items: PlanDto[];
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  totalCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export interface RefreshRequest {
  refreshToken: string;
}

export interface RegisterCompanyAsAgentCommand {
  /**
   * @format email
   * @minLength 1
   * @maxLength 200
   */
  ownerEmailAddress: string;
  /**
   * @minLength 1
   * @maxLength 200
   */
  companyName: string;
  category: CompanyCategory;
  languageCode: LanguageCode;
}

export interface RegisterRootUserRequest {
  email: string;
  password: string;
  languageCode: LanguageCode;
}

export interface ResendConfirmationEmailRequest {
  email: string;
}

export interface ResetPasswordRequest {
  email: string;
  resetCode: string;
  newPassword: string;
}

export interface RootChartStatisticsDto {
  purchaseScanCountByDay: Record<string, number>;
  claimedRewardsCountByDay: Record<string, number>;
  newCustomersCountByDay: Record<string, number>;
  newCompaniesCountByDay: Record<string, number>;
  currentPlansUsage: StatisticsPlanUsageDto[];
}

export interface RootGeneralNumericStatisticsDto {
  /** @format int32 */
  existingCustomerCount: number;
  /** @format int32 */
  existingCompaniesCount: number;
  /** @format int32 */
  activeCompaniesCount: number;
  /** @format int32 */
  purchaseScanCount: number;
  /** @format int32 */
  claimedRewardCount: number;
}

export interface RootNumericStatisticsInPeriodDto {
  marketingEmailStatistics: EmailStatisticsDto;
}

export interface RootReferredCompanyDto {
  baseInfo: BaseInfoDto;
  subscription?: SubscriptionDto | null;
  transfers: TransfersDto;
  statistics: StatisticsDto;
  flags: FlagsDto;
}

export interface RootReferredCompanyDtoPaginatedList {
  items: RootReferredCompanyDto[];
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  totalCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface RootUserDto {
  /** @format uuid */
  id: string;
  email: string;
}

export interface SetVerificationStatusCommand {
  identifier: string;
  isConfirmed: boolean;
}

export enum SortQueryBy {
  CompanyName = "CompanyName",
  CreatedAt = "CreatedAt",
  SubscriptionStatus = "SubscriptionStatus",
  TransferTotalAmount = "TransferTotalAmount",
  SubscriptionEndAt = "SubscriptionEndAt",
}

export interface StatisticsDto {
  /** @format int32 */
  scanCount: number;
  /** @format int32 */
  customerCount: number;
}

export enum StatisticsPeriod {
  Year = "Year",
  Month = "Month",
  Week = "Week",
}

export interface StatisticsPlanUsageDto {
  /** @format int32 */
  planId: number;
  name: string;
  /** @format int32 */
  companyCount: number;
}

export interface SubscriptionDto {
  planName: string;
  planNamePl: string;
  /** @format date-time */
  currentPeriodStart: string;
  /** @format date-time */
  currentPeriodEnd: string;
  state: SubscriptionState;
  frequency: SubscriptionFrequency;
}

export enum SubscriptionFrequency {
  Monthly = "Monthly",
  Annually = "Annually",
}

export enum SubscriptionState {
  Unknown = "Unknown",
  Provisioned = "Provisioned",
  Trial = "Trial",
  Paid = "Paid",
  AwaitingPayment = "AwaitingPayment",
  Cancelled = "Cancelled",
  Failed = "Failed",
  Expired = "Expired",
}

export interface TransfersDto {
  /** @format double */
  totalAmountPln: number;
}

export interface UpdateCompanyCommand {
  /** @maxLength 200 */
  companyName?: string | null;
  /** @maxLength 20 */
  phoneNumber?: string | null;
  /**
   * @minLength 3
   * @maxLength 32
   */
  pointsType?: string | null;
  /**
   * @format int32
   * @min 1
   */
  multiplier?: number | null;
  /** @maxLength 300 */
  pointsGainDescription?: string | null;
  pointsGainDescriptionPl?: string | null;
}

export interface UserInfoResponse {
  email: string;
  isEmailConfirmed: boolean;
  userType: string;
  userRole?: string | null;
  tenantIdentifier?: string | null;
  languageCode: LanguageCode;
}
