"use client";

import { Box, CardOwnProps, Card as MuiCard, Typography } from "@mui/material";

interface CardProps extends CardOwnProps {
  fullscreen?: boolean;
  title?: string;
  description?: string;
  small?: boolean;
}

export const Card = ({
  fullscreen = false,
  title,
  small = false,
  description,
  ...props
}: CardProps) => {
  if (fullscreen)
    return (
      <MuiCard
        {...props}
        sx={[
          {
            display: "flex",
            flexDirection: "column",
            padding: 3,
            gap: 2,
            textAlign: "left",
            position: "fixed",
            maxHeight: "100vh",
            maxWidth: 552,
            width: "100%",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            overflowY: "auto",
          },
          ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
        ]}
      >
        {props.children}
      </MuiCard>
    );
  return (
    <MuiCard
      {...props}
      sx={[
        {
          display: "flex",
          padding: small ? 0 : 3,
          px: 3,
          gap: 2,
          width: "100%",
          borderRadius: "6px",
          border: "1px solid rgba(255,255,255,0.12)",
          ...(!title || !description ? { flexDirection: "column" } : null),
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    >
      {title || description ? (
        <Box sx={{ maxWidth: { md: 420 }, width: { md: "40%" } }}>
          {title && (
            <Typography variant="h6" sx={{ width: "100% !important", mb: 2 }}>
              {title}
            </Typography>
          )}
          {description && (
            <Typography sx={{ width: "100% !important", whiteSpace: "pre-line" }}>
              {description}
            </Typography>
          )}
        </Box>
      ) : null}
      {props.children}
    </MuiCard>
  );
};
