import { Box, Divider, FormGroup, Typography } from "@mui/material";
import { COOKIE_CONFIG, getTenantId } from "@repo/api-config";
import { addReward, editReward } from "@repo/api-config/services/company";
import { useTranslation } from "@repo/i18n-config";
import {
  AddRewardCommand,
  EditRewardCommand,
  HttpValidationProblemDetails,
  LanguageCode,
} from "@repo/types/companyApi.types";
import { Button, Card, ErrorMessage, TextInput } from "@repo/ui";
import {
  cmsRoutes,
  getCookie,
  requiredNumberValidator,
  requiredRewardNameValidator,
  rewardDescriptionValidator,
  rewardNameValidator,
  useNotificationsContext,
  useServerErrorFormatter,
} from "@repo/utils";
import { FormApi, ReactFormApi, useForm } from "@tanstack/react-form";
import { useMutation } from "@tanstack/react-query";
import { useLoaderData, useNavigate } from "@tanstack/react-router";
import { valibotValidator } from "@tanstack/valibot-form-adapter";
import { AxiosError } from "axios";
import { useEffect } from "react";
import ReactCountryFlag from "react-country-flag";

type NewRewardFormData = Omit<AddRewardCommand, "pointCost"> & {
  pointCost: number | "";
};

export const NewReward = () => {
  const { t, i18n } = useTranslation("cms");

  const { showNotification } = useNotificationsContext();
  const navigate = useNavigate();
  const tenantId = getTenantId();
  const isPendingOwner =
    getCookie(COOKIE_CONFIG.LOYMEE_USER_ROLE.name) === "PendingOwner";

  const { mutate: addRewardMutation, error } = useMutation<
    void,
    AxiosError,
    AddRewardCommand
  >({
    mutationFn: (values) => addReward(tenantId!, values),
    onSuccess: () => {
      showNotification(t("Rewards.rewardAdded"));
      navigate({
        to: isPendingOwner
          ? `${cmsRoutes.rewards.base}?pending=true`
          : cmsRoutes.rewards.base,
      });
    },
  });

  const form = useForm<NewRewardFormData, any>({
    defaultValues: {
      namePl: "",
      name: "",
      descriptionPl: "",
      description: "",
      pointCost: "",
    },
    onSubmit: ({ value }) => {
      addRewardMutation(value as AddRewardCommand);
    },
    validatorAdapter: valibotValidator(),
  });

  useEffect(() => {
    if (!form.store.state.isValid) {
      form.validateField("name", "change");
      form.validateField("namePl", "change");
    }
  }, [i18n.language]);

  return (
    <Card
      title={t("Rewards.addReward")}
      sx={{
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        "& h6": {
          width: { xs: "100%", sm: "40%" },
        },
      }}
    >
      <RewardForm
        form={form}
        error={
          error
            ? (error as AxiosError<HttpValidationProblemDetails>)
            : undefined
        }
      />
    </Card>
  );
};

export const EditReward = () => {
  const { t } = useTranslation("cms");

  const loaderData = useLoaderData({
    from: "/_private/_withDashboard/rewards/edit/$rewardId",
  });
  const navigate = useNavigate();
  const { showNotification } = useNotificationsContext();
  const tenantId = getTenantId();

  const { mutate: editRewardMutation, error } = useMutation<
    void,
    AxiosError,
    EditRewardCommand
  >({
    mutationFn: (values) => editReward(tenantId!, values),
    onSuccess: () => {
      showNotification(t("Rewards.rewardEdited"));
      navigate({ to: cmsRoutes.rewards.base });
    },
  });

  const form = useForm<EditRewardCommand, any>({
    defaultValues: {
      id: loaderData.id,
      name: loaderData.name || "",
      namePl: loaderData.namePl || "",
      description: loaderData.description || "",
      descriptionPl: loaderData.descriptionPl || "",
      pointCost: loaderData.pointCost,
    },
    onSubmit: ({ value }) => {
      editRewardMutation({
        id: loaderData.id,
        description: value.description,
        descriptionPl: value.descriptionPl,
        pointCost: value.pointCost,
        name: value.name,
        namePl: value.namePl,
      });
    },
    validatorAdapter: valibotValidator(),
  });

  return (
    <Card
      title={t("Rewards.editReward")}
      sx={{
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        "& h6": {
          width: { xs: "100%", sm: "40%" },
        },
      }}
    >
      <RewardForm
        form={form}
        error={
          error
            ? (error as AxiosError<HttpValidationProblemDetails>)
            : undefined
        }
      />
    </Card>
  );
};

interface RewardFormProps {
  form: FormApi<any, any> & ReactFormApi<any, any>;
  error?: AxiosError<HttpValidationProblemDetails>;
}

const RewardForm = ({ form, error }: RewardFormProps) => {
  const { t, i18n } = useTranslation("cms");
  const { formatErrorMessage } = useServerErrorFormatter();

  return (
    <Box
      component="form"
      sx={{ display: "flex", flex: 1 }}
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {(() => {
            const plInputs = (
              <FormGroup
                sx={{ display: "flex", flexDirection: "column", gap: 2 }}
              >
                <Typography variant="h6" component="p">
                  {t("Rewards.form.plVersion")}{" "}
                  <ReactCountryFlag
                    countryCode="pl"
                    svg
                    style={{ marginLeft: 4, marginBottom: 2 }}
                  />
                </Typography>
                <TextInput
                  form={form}
                  name="namePl"
                  validators={{
                    onChange:
                      i18n.language === LanguageCode.Pl
                        ? requiredRewardNameValidator
                        : rewardNameValidator,
                  }}
                  label={t("Rewards.form.name")}
                  required={i18n.language === LanguageCode.Pl}
                  charLimit={24}
                />
                <TextInput
                  form={form}
                  name="descriptionPl"
                  multiline
                  validators={{
                    onChange: rewardDescriptionValidator,
                  }}
                  label={t("Rewards.form.description")}
                  charLimit={100}
                />
              </FormGroup>
            );

            const enInputs = (
              <FormGroup
                sx={{ display: "flex", flexDirection: "column", gap: 2 }}
              >
                <Typography variant="h6" component="p">
                  {t("Rewards.form.enVersion")}{" "}
                  <ReactCountryFlag
                    countryCode="us"
                    svg
                    style={{ marginLeft: 4, marginBottom: 2 }}
                  />
                </Typography>
                <TextInput
                  form={form}
                  name="name"
                  validators={{
                    onChange:
                      i18n.language === LanguageCode.En
                        ? requiredRewardNameValidator
                        : rewardNameValidator,
                  }}
                  label={t("Rewards.form.name")}
                  required={i18n.language === LanguageCode.En}
                  charLimit={24}
                />
                <TextInput
                  form={form}
                  name="description"
                  multiline
                  validators={{
                    onChange: rewardDescriptionValidator,
                  }}
                  label={t("Rewards.form.description")}
                  charLimit={100}
                />
              </FormGroup>
            );

            return i18n.language === LanguageCode.En
              ? [enInputs, <Divider sx={{ mb: 1 }} />, plInputs]
              : [plInputs, <Divider sx={{ mb: 1 }} />, enInputs];
          })()}
          <Divider sx={{ mb: 1 }} />
          <FormGroup sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography variant="h6" component="p">
              {t("Rewards.form.pointCostHeader")}
            </Typography>
            <TextInput
              form={form}
              type="number"
              name="pointCost"
              validators={{
                onChange: requiredNumberValidator,
              }}
              label={t("Rewards.form.pointCost")}
              required
            />
          </FormGroup>
        </Box>
        {error && <ErrorMessage errorMessage={formatErrorMessage(error)} />}
        <form.Subscribe
          selector={(state) => state.isValid && !state.isPristine}
          children={(isValid) => (
            <Button
              type="submit"
              sx={{ mt: 4, alignSelf: "flex-end" }}
              variant="contained"
              disabled={!isValid}
            >
              {t("Rewards.form.save")}
            </Button>
          )}
        />
      </Box>
    </Box>
  );
};
