"use client";

import { useCallback, useState } from "react";
import {
  AdvancedMarker,
  AdvancedMarkerProps,
  InfoWindow,
} from "@vis.gl/react-google-maps";
import { Typography } from "@mui/material";
import { Button } from "../Button";
import { getImageUrl } from "@repo/utils";
import loymeeMapPin from "../assets/loymee-map-pin.svg";
import { useTranslation } from "@repo/i18n-config";
import { useMapPlacesContext } from "./MapPlacesContext";
import { Marker } from "@googlemaps/markerclusterer";

interface MarkerProps {
  markerProps: AdvancedMarkerProps;
  place: google.maps.places.PlaceResult;
  onSelect: (place: google.maps.places.PlaceResult) => void;
  selectedPlace: google.maps.places.PlaceResult | null;
  setMarkerRef: (marker: Marker | null, key: number) => void;
  clustererKey: number;
}

export const MapPickerMarker = ({
  markerProps,
  place,
  onSelect,
  selectedPlace,
  setMarkerRef,
  clustererKey,
}: MarkerProps) => {
  
  const { t } = useTranslation("ui");

  const { openInfoWindowPlaceId, setOpenInfoWindowPlaceId } =
    useMapPlacesContext();

  const handleMarkerClick = useCallback(() => {
    setOpenInfoWindowPlaceId(place.place_id!);
  }, []);

  const handleSelectClick = useCallback(() => {
    onSelect(place);
    setOpenInfoWindowPlaceId(null);
  }, [onSelect, place]);

  const handleClose = useCallback(() => {
    setOpenInfoWindowPlaceId(null);
  }, []);

  const isPlaceSelected = selectedPlace?.place_id === place.place_id;

  const [marker, setMarker] =
    useState<google.maps.marker.AdvancedMarkerElement | null>(null);

  const ref = useCallback(
    (marker: google.maps.marker.AdvancedMarkerElement) => {
      setMarker(marker);
      return setMarkerRef(marker, clustererKey);
    },
    [setMarkerRef, clustererKey]
  );

  return (
    <>
      <AdvancedMarker ref={ref} onClick={handleMarkerClick} {...markerProps}>
        <img
          src={getImageUrl(loymeeMapPin)}
          alt="Loymee"
          style={{
            height: "48px",
            filter: "drop-shadow(0px 4px 8px #22222288)",
          }}
        />
      </AdvancedMarker>
      {openInfoWindowPlaceId === place.place_id && (
        <InfoWindow
          anchor={marker}
          maxWidth={300}
          onCloseClick={handleClose}
          headerContent={
            <Typography
              variant="body2"
              sx={(theme) => ({ color: theme.palette.background.default })}
            >
              {place.name}
            </Typography>
          }
        >
          <Typography
            variant="caption"
            sx={(theme) => ({ color: theme.palette.background.default })}
          >
            {place.formatted_address}
          </Typography>
          <Button
            sx={{
              mt: 1,
              display: "block",
            }}
            size="small"
            onClick={handleSelectClick}
          >
            {t(`MapComponent.isPlaceSelected.${isPlaceSelected}`)}
          </Button>
        </InfoWindow>
      )}
    </>
  );
};
