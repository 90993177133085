import { Box, Divider, Link, Typography } from "@mui/material";
import {
  phoneValidator,
  requiredStringValidator,
  useNotificationsContext,
} from "@repo/utils";
import { useForm } from "@tanstack/react-form";
import { valibotValidator } from "@tanstack/valibot-form-adapter";
import { TextInput } from "./form-fields/TextInput";
import { LoadingButton } from "./LoadingButton";
import { useTranslation } from "@repo/i18n-config";
import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { ErrorMessage } from "./ErrorMessage";
import { useMe } from "@repo/api-config";

interface ContactDataProps {
  type: "company" | "customer" | "admin";
}

interface ContactFormData {
  subject: string;
  message: string;
  phone?: string;
}

export const ContactData = ({ type }: ContactDataProps) => {
  const { t } = useTranslation("ui");
  const me = useMe(type);
  const { showNotification } = useNotificationsContext();

  const { mutate, isPending, error } = useMutation<
    void,
    AxiosError,
    ContactFormData
  >({
    mutationFn: (value) =>
      axios.post("https://api.web3forms.com/submit", {
        access_key: "4f270cad-a160-494f-988c-c40abbe26633",
        from_name: "Loymee formularz kontaktowy",
        subject: "Zgłoszenie z formularza kontaktowego",
        replyto: me.email,
        name: value.subject,
        message: value.message,
        email: me.email,
        phone: value.phone,
        type,
      }),
    onSuccess: () => {
      showNotification(t("ContactData.sent"));
      form.reset();
    },
  });
  const form = useForm({
    defaultValues: {
      subject: "",
      message: "",
      phone: "",
    },
    onSubmit: async ({ value }) => {
      mutate(value);
    },
    validatorAdapter: valibotValidator(),
  });

  return (
    <>
      {type !== "customer" && (
        <>
          <Typography variant="h6">Mobitouch sp. z o.o.</Typography>
          <Typography>ul. Lwowska 114/2, 35-301 Rzeszów</Typography>
          <Typography>NIP: 5170361521</Typography>
          <Divider sx={{ mt: 2, mb: 1.5 }} />
        </>
      )}
      <Typography>
        e-mail: <Link href="mailto:support@loymee.com">support@loymee.com</Link>
      </Typography>
      <Divider sx={{ mt: 2, mb: 1.5 }} />
      <Typography variant="h6" sx={{ mb: 2 }}>
        {t("ContactData.contactForm")}
      </Typography>
      <Box
        component="form"
        sx={{ display: "flex", flex: 1 }}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <TextInput
              form={form}
              name="subject"
              label={t("ContactData.form.subject")}
              required
              validators={{
                onChange: requiredStringValidator,
              }}
            />

            <TextInput
              form={form}
              name="message"
              label={t("ContactData.form.message")}
              required
              multiline
              rows={8}
              validators={{
                onChange: requiredStringValidator,
              }}
            />

            {type !== "customer" && (
              <TextInput
                form={form}
                name="phone"
                label={t("ContactData.form.phone")}
                validators={{
                  onChange: phoneValidator,
                }}
              />
            )}
          </Box>
          {error && <ErrorMessage errorMessage={t("ContactData.form.error")} />}
          <form.Subscribe
            selector={(state) => state.isValid && !state.isPristine}
            children={(isValid) => (
              <LoadingButton
                sx={(theme) => ({
                  mt: 4,
                  alignSelf: "flex-end",
                  backgroundColor: theme.palette.secondary.main,
                })}
                fullWidth={false}
                text={t("ContactData.form.send")}
                isLoading={isPending}
                disabled={!isValid}
              />
            )}
          />
        </Box>
      </Box>
    </>
  );
};
