"use client";

export const usePWAInstall = () => {
  const install = () => {
    if (window._LOYMEE_PWA_PROMPT_) window._LOYMEE_PWA_PROMPT_.prompt();
  };

  const canPrompt = () => {
    return !!window._LOYMEE_PWA_PROMPT_;
  };

  return {
    install,
    canPrompt,
  };
};
