"use client";

import { Select, SelectProps as MuiSelectProps } from "@mui/material";
import {
  DeepKeys,
  FieldApiOptions,
  ReactFormApi,
} from "@tanstack/react-form";
import { FieldInfo } from "../FieldInfo";

type SelectProps<T> = {
  form: ReactFormApi<T, any>;
  name: DeepKeys<T>;
  required?: boolean;
  validators?: FieldApiOptions<any, any, any, any, any>["validators"];
} & MuiSelectProps;

const SelectComponent = <T,>(props: SelectProps<T>) => {
  return (
    <props.form.Field
      name={props.name}
      children={(field) => (
        <>
          <Select
            value={field.state.value}
            onChange={(e) => field.handleChange(e.target.value)}
            {...props}
          >
            {props.children}
          </Select>

          <FieldInfo field={field} />
        </>
      )}
      validators={props.validators}
    />
  );
};

export { SelectComponent as Select };
