import { Box, CircularProgress } from "@mui/material";
import { getTenantId } from "@repo/api-config";
import {
  getCompanyDetails,
  updateCompany,
} from "@repo/api-config/services/company";
import { useTranslation } from "@repo/i18n-config";
import {
  HttpValidationProblemDetails,
  LanguageCode,
  UpdateCompanyCommand,
} from "@repo/types/companyApi.types";
import { Card, ErrorMessage, LoadingButton, TextInput } from "@repo/ui";
import {
  rewardRulesValidator,
  useNotificationsContext,
  useServerErrorFormatter,
} from "@repo/utils";
import { useForm } from "@tanstack/react-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { valibotValidator } from "@tanstack/valibot-form-adapter";
import { AxiosError } from "axios";
import { useEffect } from "react";

const RewardRules = () => {
  const { t, i18n } = useTranslation("cms");

  const tenantId = getTenantId();

  const { formatErrorMessage } = useServerErrorFormatter();

  const { showNotification } = useNotificationsContext();

  const { data, isLoading, error } = useQuery({
    queryKey: ["RewardRules"],
    queryFn: () => getCompanyDetails(tenantId!),
  });

  const form = useForm({
    defaultValues: {
      pointsGainDescription: "",
      pointsGainDescriptionPl: "",
    },
    onSubmit: async ({ value }) => {
      mutate(value);
    },
    validatorAdapter: valibotValidator(),
  });

  useEffect(() => {
    if (data?.pointsGainDescription)
      form.setFieldValue("pointsGainDescription", data.pointsGainDescription);
    if (data?.pointsGainDescriptionPl)
      form.setFieldValue(
        "pointsGainDescriptionPl",
        data.pointsGainDescriptionPl
      );
  }, [data]);

  const {
    mutate,
    isPending,
    error: mutationError,
  } = useMutation<
    void,
    AxiosError,
    Pick<
      UpdateCompanyCommand,
      "pointsGainDescription" | "pointsGainDescriptionPl"
    >
  >({
    mutationFn: (value) => updateCompany(tenantId!, value),
    onSuccess: () => {
      showNotification(t("Rewards.multiplierSaved"));
    },
  });

  return (
    <Card
      title={t("Rewards.rules.title")}
      description={t("Rewards.rules.description")}
      sx={{
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        "& h6": {
          width: { xs: "100%", sm: "40%" },
        },
      }}
    >
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {error && !data && (
        <ErrorMessage
          errorMessage={formatErrorMessage(
            error as AxiosError<HttpValidationProblemDetails>
          )}
        />
      )}
      {data && (
        <Box
          component="form"
          sx={{ display: "flex", flex: 1 }}
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            form.handleSubmit();
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            {(() => {
              const plInput = (
                <TextInput
                  form={form}
                  name="pointsGainDescriptionPl"
                  label={t("Rewards.rules.labelPl")}
                  multiline
                  charLimit={300}
                  validators={{
                    onChange: rewardRulesValidator,
                  }}
                />
              );

              const enInput = (
                <TextInput
                  form={form}
                  name="pointsGainDescription"
                  label={t("Rewards.rules.labelEn")}
                  multiline
                  charLimit={300}
                  validators={{
                    onChange: rewardRulesValidator,
                  }}
                />
              );

              return i18n.language === LanguageCode.En
                ? [enInput, plInput]
                : [plInput, enInput];
            })()}
            {mutationError && (
              <ErrorMessage
                errorMessage={formatErrorMessage(
                  mutationError as AxiosError<HttpValidationProblemDetails>
                )}
              />
            )}
            <form.Subscribe
              selector={(state) => state.isValid && !state.isPristine}
              children={(isValid) => (
                <LoadingButton
                  sx={(theme) => ({
                    mt: 4,
                    alignSelf: "flex-end",
                    backgroundColor: theme.palette.secondary.main,
                  })}
                  fullWidth={false}
                  text={t("Rewards.rules.save")}
                  isLoading={isPending}
                  disabled={!isValid}
                />
              )}
            />
          </Box>
        </Box>
      )}
    </Card>
  );
};

export default RewardRules;
