import {
  AccessTokenResponse,
  CompanyDto,
  CompanyRegistrationStatusDto,
  RegisterCompanyCommand,
  UpdateCompanyCommand,
} from "@repo/types/companyApi.types";
import { httpClient } from "../../http-client";
import { companyApiPaths } from "./paths";
import { updateCompanyAuthAfterConfirmation } from "../../hooks";

export type RegisterCompanyCommandWithCaptcha = RegisterCompanyCommand & {
  turnstileResponse: string;
  idempotencyKey: string;
};

export const registerCompany = async (
  payload: RegisterCompanyCommandWithCaptcha
) => {
  const { turnstileResponse, idempotencyKey, ...rest } = payload;
  const response = await httpClient.post(companyApiPaths.companies.base, rest, {
    headers: {
      "X-Turnstile-Response": turnstileResponse,
      "X-Idempotency-Key": idempotencyKey,
    },
  });

  return response.data;
};

export const getCompanyDetails = async (tenantId: string) => {
  const response = await httpClient.get<CompanyDto>(
    companyApiPaths.companies.getByTenant(tenantId)
  );

  return response.data;
};

export const getCompanyRegistrationStatus = async (tenantId: string) => {
  const response = await httpClient.get<CompanyRegistrationStatusDto>(
    companyApiPaths.companies.registrationStatus(tenantId)
  );

  return response.data;
};

export const confirmCompanyRegistration = async (
  tenantId: string
): Promise<void> => {
  const response = await httpClient.post<AccessTokenResponse>(
    companyApiPaths.companies.endRegistration(tenantId),
    {},
    {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    }
  );
  return updateCompanyAuthAfterConfirmation(response);
};

export const updateCompany = async (
  tenantId: string,
  payload: UpdateCompanyCommand
) => {
  const response = await httpClient.patch(
    companyApiPaths.companies.getByTenant(tenantId),
    payload
  );

  return response.data;
};
