import { Box, FormGroup, IconButton, Typography } from "@mui/material";
import { Card, LoadingButton, Logo, TextInput } from "@repo/ui";
import {
  cmsRoutes,
  emailValidator,
  useNotificationsContext,
} from "@repo/utils";
import { useForm } from "@tanstack/react-form";
import { valibotValidator } from "@tanstack/valibot-form-adapter";
import { AxiosError } from "axios";
import { useMutation } from "@tanstack/react-query";
import { forgotPassword } from "@repo/api-config/services/company";
import { useTranslation } from "@repo/i18n-config";
import { ForgotPasswordRequest } from "@repo/types/companyApi.types";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "@tanstack/react-router";

export const ForgotPasswordPage = () => {
  const { showNotification } = useNotificationsContext();
  const { mutate: sendForm, isPending } = useMutation<
    void,
    AxiosError,
    ForgotPasswordRequest
  >({
    mutationFn: forgotPassword,
    onSuccess: () => showNotification(t("ForgotPassword.emailSent")),
    onError: () => showNotification(t("ForgotPassword.emailSent")),
  });
  const { t } = useTranslation("cms");
  const navigate = useNavigate();

  const form = useForm<any, any>({
    defaultValues: {
      email: "",
    },
    onSubmit: async ({ value }) => {
      sendForm(value);
    },
    validatorAdapter: valibotValidator(),
  });

  return (
    <Card fullscreen>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mb: 3,
        }}
      >
        <Logo height={50} theme="dark" />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          ml: -1,
          gap: 1,
        }}
      >
        <IconButton
          onClick={() =>
            navigate({
              to: cmsRoutes.login.base,
            })
          }
        >
          <ArrowBack />
        </IconButton>
        <Typography variant="h5">{t("ForgotPassword.header")}</Typography>
      </Box>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
      >
        <FormGroup sx={{ gap: 2, mt: 1 }}>
          <TextInput
            form={form}
            name="email"
            validators={{
              onChange: emailValidator,
            }}
            type="email"
            label={t("ForgotPassword.form.email")}
            required
          />
          <LoadingButton
            isLoading={isPending}
            text={t("ForgotPassword.form.send")}
            size="large"
          />
        </FormGroup>
      </form>
    </Card>
  );
};
