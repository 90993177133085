import { Typography, Link as MuiLink, Box } from "@mui/material";
import { Link } from "@tanstack/react-router";
import { LoginForm } from "./LoginForm";
import { Card, LanguageSelector, Logo } from "@repo/ui";
import { cmsRoutes, useIsPWA } from "@repo/utils";
import { useTranslation } from "@repo/i18n-config";

export const LoginPage = () => {
  const { t } = useTranslation("cms");
  const isPWA = useIsPWA();

  return (
    <>
      <Card fullscreen>
        <Box
          component="a"
          href="https://loymee.com/dla-biznesu/"
          sx={{
            display: "flex",
            justifyContent: "center",
            py: 3,
            mt: -3,
          }}
          target={isPWA ? "_blank" : "_self"}
          rel={isPWA ? "noopener noreferrer" : ""}
        >
          <Logo height={50} theme="dark" />
        </Box>
        <Typography variant="h5">{t("Login.header")}</Typography>
        <LoginForm />
        <Box display="flex" gap={1} justifyContent="space-between">
          <MuiLink component={Link} to={cmsRoutes.register.company}>
            {t("Login.createAccount")}
          </MuiLink>
          <MuiLink component={Link} to={cmsRoutes.forgotPassword.base}>
            {t("Login.forgotPassword")}
          </MuiLink>
        </Box>
        <Box>
          <LanguageSelector />
        </Box>
      </Card>
    </>
  );
};
