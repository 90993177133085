import { Box, Button, ButtonGroup } from "@mui/material";
import { useTranslation } from "@repo/i18n-config";

interface MultiplierSelectorProps {
  multiplier: number;
  value: number;
  setValue: (newValue: number) => void;
}

export const MultiplierSelector = ({
  multiplier,
  value,
  setValue,
}: MultiplierSelectorProps) => {
  
  const { t } = useTranslation("cms");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Button
        fullWidth
        onClick={() => setValue(1)}
        variant={value === 1 ? "contained" : "outlined"}
      >
        {t("employeeScan.confirmModal.noMultiplier")}
      </Button>
      <ButtonGroup fullWidth>
        {[2, 3, 4].map((n) => (
          <Button
            key={n}
            onClick={() => setValue(n)}
            variant={value === n ? "contained" : "outlined"}
          >
            {multiplier * n}
          </Button>
        ))}
      </ButtonGroup>
      <ButtonGroup fullWidth>
        {[5, 6, 10].map((n) => (
          <Button
            key={n}
            onClick={() => setValue(n)}
            variant={value === n ? "contained" : "outlined"}
          >
            {multiplier * n}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
};
