"use client";

import "@repo/mui-config/theme";
import { alpha, ButtonProps, Button as MuiButton } from "@mui/material";
import { useState } from "react";

export const ListItemButton = ({ ...props }: ButtonProps) => {
  const [hovering, setHovering] = useState(false);
  const backgroundColor =
    props.sx && "backgroundColor" in props.sx
      ? (props.sx.backgroundColor as string)
      : "common.white";

  const backgroundColorDark =
    props.sx && "backgroundColor" in props.sx
      ? (props.sx.backgroundColor as string)
      : "#313535";

  const backgroundColorDarkHover =
    alpha(backgroundColorDark, 0.6)

  return (
    <MuiButton
      {...props}
      sx={[
        (theme) => ({
          borderRadius: 2,
          backgroundColor,
          "&:hover": {
            backgroundColor,
            boxShadow: hovering ? 25 : "none",
          },
          "&:focus": {
            backgroundColor,
            boxShadow: hovering ? 25 : "none",
          },
          "&:active": {
            backgroundColor,
            boxShadow: hovering ? 25 : "none",
          },
          boxShadow: "none",
          width: "100%",
          justifyContent: "start",
          textTransform: "none",
          ...theme.applyStyles("dark", {
            backgroundColor: backgroundColorDark,
            "&:hover": {
              backgroundColor: backgroundColorDarkHover,
            },
            "&:focus": {
              backgroundColor: backgroundColorDarkHover,
            },
            "&:active": {
              backgroundColor: backgroundColorDarkHover,
            },
          }),
        }),
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
      onClick={(e) => {
        setHovering(false);
        props.onClick && props.onClick(e);
      }}
      onTouchStart={() => {
        setHovering(true);
      }}
      onTouchEnd={() => {
        setHovering(false);
      }}
      onMouseEnter={() => {
        setHovering(true);
      }}
      onMouseLeave={() => {
        setHovering(false);
      }}
    >
      {props.children}
    </MuiButton>
  );
};
