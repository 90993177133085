"use client";

import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { PackageLevel } from "./PackageLevel";
import { useTranslation } from "@repo/i18n-config";
import { LinkWrapper } from "../LinkWrapper";
import {
  cmsRoutes,
  getSubscriptionName,
  useServerErrorFormatter,
} from "@repo/utils";
import { useQuery } from "@tanstack/react-query";
import { getCurrentSubscription } from "@repo/api-config/services/company";
import { getTenantId } from "@repo/api-config";
import { AxiosError } from "axios";
import { ErrorMessage } from "../ErrorMessage";
import {
  HttpValidationProblemDetails,
  SubscriptionState,
} from "@repo/types/companyApi.types";

export const DashboardPlanInfo = () => {
  const { t, i18n } = useTranslation("ui");
  const tenantId = getTenantId();
  const { formatErrorMessage } = useServerErrorFormatter();

  const { data, isLoading, error } = useQuery({
    queryKey: ["currentSubscription"],
    queryFn: () => getCurrentSubscription(tenantId!),
  });

  if (isLoading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );

  if (error && !data)
    return (
      <ErrorMessage
        errorMessage={formatErrorMessage(
          error as AxiosError<HttpValidationProblemDetails>
        )}
      />
    );

  if (data?.state !== SubscriptionState.Cancelled)
    return (
      <Box>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            alignItems: "center",
          }}
        >
          <PackageLevel level={3} />
          {data?.ratePlan && (
            <Typography variant="h6" fontWeight={700}>
              {getSubscriptionName(data.ratePlan, i18n.language)}
            </Typography>
          )}
        </Box>
        <LinkWrapper
          to={cmsRoutes.settings.base}
          query={{
            tab: "paymentSubscription",
          }}
        >
          <Button color="secondary" fullWidth sx={{ mt: 2 }}>
            {t("DashboardPlanInfo.changePlan")}
          </Button>
        </LinkWrapper>
      </Box>
    );
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
        }}
      >
        <PackageLevel level={0} />
        <Typography fontWeight={700}>
          {t("DashboardPlanInfo.noPlan")}
        </Typography>
      </Box>
      <LinkWrapper
        to={cmsRoutes.settings.base}
        query={{
          tab: "paymentSubscription",
        }}
      >
        <Button color="secondary" fullWidth sx={{ mt: 2 }}>
          {t("DashboardPlanInfo.choosePlan")}
        </Button>
      </LinkWrapper>
    </Box>
  );
};
