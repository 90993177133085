import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "@repo/i18n-config";

interface ConfirmModalProps {
  open: boolean;
  text: string;
  handleYes: () => void;
  handleNo: () => void;
}

export const ConfirmModal = ({
  open,
  handleNo,
  handleYes,
  text,
}: ConfirmModalProps) => {
  
  const { t } = useTranslation("ui");

  return (
    <Dialog open={open} onClose={handleNo}>
      <DialogTitle>{t("ConfirmModal.title")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleYes}>{t("ConfirmModal.yes")}</Button>
        <Button onClick={handleNo} autoFocus>
          {t("ConfirmModal.no")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
