import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material";
import { useTranslation } from "@repo/i18n-config";
import { useMemo } from "react";
import { getTenantId } from "@repo/api-config";
import { Cancel, CheckCircle } from "@mui/icons-material";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  confirmCompanyRegistration,
  getCompanyRegistrationStatus,
  getCurrentSubscription,
} from "@repo/api-config/services/company";
import { AxiosError } from "axios";
import { DashboardStatusBanner, LoadingButton } from "@repo/ui";
import { useRouter } from "@tanstack/react-router";
import { cmsRoutes } from "@repo/utils";
import SubscriptionOptions from "../Settings/SubscriptionOptions";

const StatusIcon = ({ status }: { status: boolean }) => {
  if (!status) return <Cancel color="error" />;
  return <CheckCircle color="success" />;
};

export const DashboardPendingOwnerLayout = () => {
  
  const { t } = useTranslation("ui");
  const { navigate } = useRouter();

  const tenantId = getTenantId();

  const { data } = useQuery({
    queryFn: () => getCompanyRegistrationStatus(tenantId!),
    queryKey: ["RegistrationStatus"],
    enabled: !!tenantId,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });

  const configComplete = useMemo(() => {
    return data && Object.values(data).every((v) => v);
  }, [data]);

  const { mutate: updateCompany, isPending } = useMutation<
    void,
    AxiosError,
    void
  >({
    mutationFn: () => confirmCompanyRegistration(tenantId!),
    onSuccess: () => {
      window.location.reload();
    },
  });

  const {
    data: currentSubscription,
    isFetched,
  } = useQuery({
    queryKey: ["currentSubscription"],
    queryFn: () => getCurrentSubscription(tenantId!),
    refetchOnMount: true,
  });

  if (!isFetched)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  if (!currentSubscription)
    return (
      <>
        <Typography variant="h6" my={2}>
          {t("DashboardPendingOwnerLayout.welcome")}
        </Typography>
        <Typography variant="h6" mb={2}>
          {t("DashboardPendingOwnerLayout.weCanHelp")}
        </Typography>
        <Typography variant="h6" mb={2}>
          {t("DashboardPendingOwnerLayout.chooseSub")}
        </Typography>

        <SubscriptionOptions />
      </>
    );

  if (currentSubscription && data)
    return (
      <Box sx={{ mt: 3 }}>
        <DashboardStatusBanner />
        <Typography variant="h6" mt={2}>
          {t("DashboardPendingOwnerLayout.welcome")}
        </Typography>
        <Typography variant="h6" mb={4}>
          {t("DashboardPendingOwnerLayout.accountConfig")}
        </Typography>
        <Box sx={{ mb: 4 }}>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <StatusIcon status={data.hasReward} />
            <Typography>{t("DashboardPendingOwnerLayout.rewards")}</Typography>
          </Box>
          <Button
            disabled={data.hasReward}
            sx={{ mt: 1 }}
            onClick={() => navigate({ to: cmsRoutes.rewards.base })}
          >
            {t("DashboardPendingOwnerLayout.go")}
          </Button>
        </Box>
        <Box sx={{ mb: 4 }}>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <StatusIcon status={data.hasEmployee} />
            <Typography>
              {t("DashboardPendingOwnerLayout.employees")}
            </Typography>
          </Box>
          <Button
            disabled={data.hasEmployee}
            sx={{ mt: 1 }}
            onClick={() => navigate({ to: cmsRoutes.employees.base })}
          >
            {t("DashboardPendingOwnerLayout.go")}
          </Button>
        </Box>
        <Box sx={{ mb: 4 }}>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <StatusIcon status={data.hasLocation} />
            <Typography>{t("DashboardPendingOwnerLayout.location")}</Typography>
          </Box>
          <Button
            disabled={data.hasLocation}
            sx={{ mt: 1 }}
            onClick={() => navigate({ to: cmsRoutes.locations.base })}
          >
            {t("DashboardPendingOwnerLayout.go")}
          </Button>
        </Box>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <StatusIcon status={data.isEmailConfirmed} />
          <Typography>{t("DashboardPendingOwnerLayout.email")}</Typography>
        </Box>
        {configComplete && (
          <Dialog open>
            <DialogContent>
              {t("DashboardPendingOwnerLayout.readyToConfirm")}

              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <LoadingButton
                  isLoading={isPending}
                  disabled={!configComplete}
                  sx={{ mt: 2 }}
                  text={t("DashboardPendingOwnerLayout.confirm")}
                  fullWidth={false}
                  onClick={() => updateCompany()}
                />
              </Box>
            </DialogContent>
          </Dialog>
        )}
      </Box>
    );
  else return null;
};
