"use client";

import { useTranslation } from "@repo/i18n-config";
import { TopBar } from "@repo/ui";
import { cmsRoutes } from "@repo/utils";

export default function InstallPwaPage() {
  const { i18n } = useTranslation("cms");
  return (
    <>
      <TopBar linkHref={cmsRoutes.scanner.base} />
      <video
        controls
        autoPlay
        loop
        src={`/pwa-ios/${i18n.language}.mp4`}
        style={{
          display: "block",
          width: "100%",
          maxWidth: "100%",
          maxHeight: "85svh",
          margin: "auto",
          marginTop: "5px",
        }}
      />
    </>
  );
}
